import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CardContainer from '../../components/card/card-container.jsx';
import SelectCiclo from '../../components/select/select-ciclo.jsx';
import { useCoopConfig } from '../../components/content/coop-config.jsx';
import AnticiposXProductorList from './anticipos-x-productor-list.js';
import AnticipoList from './anticipos-list.js';
import useAnticipos from '../../hooks/useAnticipos.js';
import { useAuth } from '../../components/content/auth.jsx';
import env from '../../environment.js';
import useAnticiposXproductor from '../../hooks/useAnticiposXproductor.js';

const AnticiposTables = () => {
  const [btnPorProductor, setBtnPorProductor] = useState(0);
  const auth = useAuth();
  const coopConfig = useCoopConfig();
  const [ciclo, setCiclo] = useState({});
  const [etiquetaAnticipos, setEtiquetaAnticipos] = useState('-');
  const { data, originalData, blocking, setData, exportarAnticipos } = useAnticipos({
    ciclo,
    update: btnPorProductor,
    etiquetaAnticipos,
  });
  const {
    data: dataXproductor,
    originalData: originalDataXproductor,
    blocking: blockingXproductor,
    setData: setDataXproductor,
    exportarAnticipos: exportarAnticiposXproductor,
  } = useAnticiposXproductor({
    ciclo,
    update: btnPorProductor,
    etiquetaAnticipos,
  });

  useEffect(() => {
    getEtiquetaAnticipo();
  }, [coopConfig.config]);

  const getEtiquetaAnticipo = async () => {
    let etiqueta = await coopConfig.etiquetaAnticipos();
    setEtiquetaAnticipos(etiqueta);
  };

  const setFilter = value => {
    setCiclo(value.target);
  };

  const isPorProductor = () => btnPorProductor % 2;
  const isPorAnticipo = () => !isPorProductor();

  const onClickPorAnticipo = () => {
    if (isPorAnticipo()) return;
    setBtnPorProductor(prev => prev + 1);
  };

  const onClickPorProductor = () => {
    if (isPorProductor()) return;
    setBtnPorProductor(prev => prev + 1);
  };

  const exportarExcel = () => {
    if (isPorAnticipo()) {
      exportarAnticipos();
    } else {
      exportarAnticiposXproductor();
    }
  };

  return (
    <div>
      <div className="row justify-content-between mb-3  ml-1">
        <div className="col-6">
          <SelectCiclo
            setFilter={evt => {
              setFilter(evt);
            }}
            acopio
          />
          <div className="row mt-1" style={{ marginLeft: 0 }}>
            <div className="btn-group mr-4" role="group" style={{ borderRadius: 1 }}>
              <button
                type="button"
                onClick={onClickPorAnticipo}
                className={'btn btn-light'}
                style={{
                  borderTopLeftRadius: 10,
                  borderBottomLeftRadius: 10,
                  backgroundColor: isPorAnticipo() ? '#FFEED9' : 'transparent',
                  borderWidth: 1,
                  borderColor: '#d4d4d4',
                }}
                disabled={false}>
                Por {etiquetaAnticipos}
              </button>

              <button
                type="button"
                onClick={onClickPorProductor}
                className={'btn btn-light'}
                style={{
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  backgroundColor: isPorProductor() ? '#FFEED9' : 'transparent',
                  borderWidth: 1,
                  borderColor: '#d4d4d4',
                }}
                disabled={false}>
                Por productor
              </button>
            </div>
          </div>
        </div>
        <div className="col-6 d-flex justify-content-end align-items-center">
          {ciclo?.uuid && auth.hasPermission('ACOPIOS_ANTICIPOS_X_PRODUCTOR_EXPORTAR') && (
            <div className="d-flex justify-content-end">
              <Link to="#" onClick={exportarExcel} className="m-r-2" style={{ color: '#789D16' }}>
                <img src={env.images.icon.xls} className="pr-1" /> <span>Descargar Excel</span>
              </Link>
            </div>
          )}
        </div>
      </div>

      <CardContainer height={'100%'}>
        {isPorAnticipo() ? (
          <AnticipoList
            ciclo={ciclo}
            update={btnPorProductor}
            etiquetaAnticipos={etiquetaAnticipos}
            data={data}
            originalData={originalData}
            blocking={blocking}
            setData={setData}
          />
        ) : (
          <AnticiposXProductorList
            ciclo={ciclo}
            update={btnPorProductor}
            etiquetaAnticipos={etiquetaAnticipos}
            data={dataXproductor}
            originalData={originalDataXproductor}
            blocking={blockingXproductor}
            setData={setDataXproductor}
          />
        )}
      </CardContainer>
    </div>
  );
};

export default AnticiposTables;
