import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';

const AcopioKgsCategorias = ({ kgsCatCert }) => {
  const CategoriaTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-4 border border-light">
          <h6 className="font-lato-normal-400">{`${label}`}</h6>
          <h6>{`${payload[0].value.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })}`}</h6>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <Accordion>
        <AccordionSummary aria-controls="panel1-content" id="panel1-header" expandIcon={<i className="fa fa-plus" aria-hidden="true"></i>}>
          <h6 className="font-lato-normal-400 text-center">Desglose por categorías</h6>
        </AccordionSummary>
        <AccordionDetails>
          <ResponsiveContainer width="100%" height={100}>
            <BarChart
              data={kgsCatCert}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="label" />
              <YAxis hide />
              <Tooltip content={<CategoriaTooltip />} />
              <Bar dataKey="value" fill="#de7804" maxBarSize={40} />
            </BarChart>
          </ResponsiveContainer>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default AcopioKgsCategorias;
