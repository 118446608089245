import React from 'react';
import env from '../../environment';

const IconCheck = () => (
  <i
    className="fa fa-check"
    aria-hidden="true"
    style={{
      color: env.colors.primary_500,
      marginRight: 10,
    }}
  />
);

export default IconCheck;
