import React, { useCallback, useMemo } from 'react';
import moment from 'moment/moment';
import xlsx from 'json-as-xlsx';
import LabelNumber from '../components/label/label-number.jsx';
import LabelDate from '../components/label/label-date.jsx';
import LabelColor from '../components/label/label-color.jsx';
import changeCropLabelByCoop from '../utils/changeCropLabelByCoop.js';
import Swal from 'sweetalert2';

const useEfectivo = ({ ciclo, auth }) => {
  // Para buscar el nombre adentro de centro de acopio
  const addCentro_acopio_nombre = useCallback((data) => {
    return data.map(item => ({
      ...item,
      centro_acopio_nombre: item.centro_acopio?.nombre || '',
    }));
  }, []);

  const searchColumns = useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'id', label: 'id' },
      { value: 2, target: 'monto', label: 'DESEMBOLSO EFECTIVO' },
      { value: 3, target: 'motivo', label: 'MOTIVO' },
      { value: 4, target: 'centro_acopio_nombre', label: 'CENTRO DE ACOPIO' },
    ],
    []
  );

  const generateDynamicColumns = (data, baseColumns, auth, coopConfig) => {
    let maxColumns = data.map(item => item.desembolsos.length)
      .reduce((current, sum) => (current > sum ? current : sum), 0);
  
    const desembolsoColumns = Array.from({ length: maxColumns }, (_, i) => ({
      Header: 'DESEMBOLSO ' + (i + 1),
      columns: [
        {
          Header: 'FECHA',
          accessor: `desembolsos[${i}].fecha_envio`,
          sortable: true,
          minWidth: 80,
          Cell: ({ value }) => <LabelDate value={value} />,
        },
        {
          Header: 'MONTO',
          accessor: `desembolsos[${i}].monto`,
          sortable: true,
          minWidth: 50,
          Cell: ({ value }) => <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2} />,
        },
        {
          Header: 'MOTIVO DESEMBOLSO DE EFECTIVO',
          accessor: `desembolsos[${i}]`,
          id: i + '_3',
          sortable: true,
          minWidth: 100,
          Cell: ({ value }) => {
            let v = value;
            if (v != null)
              return (
                <LabelColor label={changeCropLabelByCoop(v.motivo, auth.user.id_cooperativa)} color={v.motivo_color}></LabelColor>
              );
            else return '';
          },
        },
        {
          Header: 'NOMBRE DE ACOPIADOR',
          accessor: `desembolsos[${i}].usuario_acopiador.nombre`,
          sortable: true,
          minWidth: 200,
        },
        {
          Header: 'REGISTRO',
          accessor: `desembolsos[${i}]`,
          id: i + '_5',
          sortable: true,
          minWidth: 350,
          Cell: cell => {
            let v = cell.value;
            if (v != null) return `Registrado por ${v.usuario_registro.nombre} el ${v.created_at.substr(0, 10)}`;
            else return '';
          },
        },
      ],
    }));
  
    return [...baseColumns, ...desembolsoColumns];
  };

  const handleMovimientosCheck = useCallback((event, row, setIdSeleccionado, setDataSeleccionado) => {
    if (Number(event.target.value) === Number(row.id) && !event.target.checked) {
      setIdSeleccionado(null);
      setDataSeleccionado(null);
    } else {
      setIdSeleccionado(Number(event.target.value));
      setDataSeleccionado(row);
    }
  }, []);

  const exportarXlsx = (data) => {    
    if (!ciclo?.id) return;

    data.forEach((item) => {
      item.created_at = moment(item.created_at).format('YYYY-MM-DD')
    });
    
    const xlsData = [
      {
        sheet: 'Movimientos',
        columns: [
          { label: 'ID', value: 'id' },
          { label: 'CENTRO DE ACOPIO', value: 'centro_acopio.nombre' },
          { label: 'FECHA', value: 'fecha_envio' },
          { label: 'DESEMBOLSO EFECTIVO', value: 'monto', format: '$#,##0.00' },
          { label: 'MOTIVO', value: 'motivo' },
          { label: 'NOMBRE DE ACOPIADOR', value: 'usuario_acopiador.nombre' },
          { label: 'NOMBRE DE REGISTRADOR', value: 'usuario_registro.nombre' },
          { label: 'FECHA DE REGISTRO', value: 'created_at', format: '####-##-##' },
        ],
        content: data,
      },
    ];

    const settings = {
      fileName: `AcopiosPorProductor_${ciclo.id}_${moment().format('YYYYMMDD_HHmmss')}`,
    };
    xlsx(xlsData, settings);
  };

  const getBackgroundColor = useCallback((i, j) => {
    if (i === 1) return 'rgb(247, 239, 231)';
    return j % 2 ? '#91918A' : '#666666';
  }, []);

  const getColor = useCallback((i) => {
    return i === 1 ? 'rgb(95, 95, 89)' : 'white';
  }, []);

  const eliminarEfectivo = useCallback(async (idSeleccionado, Api, setIdSeleccionado, setDataSeleccionado, updateData) => {
    if (!idSeleccionado || !ciclo.id) return;

    try {
      const result = await Swal.fire({
        title: '¿Estás seguro?',
        text: "Esta acción no se puede revertir",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      });

      if (result.isConfirmed) {
        await Api.Acopio.delete('registro_efectivo', idSeleccionado);
        await updateData(ciclo.id);
        setIdSeleccionado(null);
        setDataSeleccionado(null);
        
        Swal.fire(
          'Eliminado!',
          'El registro ha sido eliminado.',
          'success'
        );
      }
    } catch (error) {
      console.error('Error deleting efectivo:', error);
      Swal.fire(
        'Error!',
        'No se pudo eliminar el registro.',
        'error'
      );
    }
  }, [ciclo]);

  return {
    searchColumns,
    handleMovimientosCheck,
    exportarXlsx,
    getBackgroundColor,
    getColor,
    generateDynamicColumns,
    eliminarEfectivo,
    addCentro_acopio_nombre,
  };
};

export default useEfectivo;
