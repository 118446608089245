import React from 'react';
import { Redirect } from 'react-router';

import InicioResume from '../pages/admin/inicio-resume.js';

import LoginV3 from '../pages/login-v3.js';
import LoginV3Mobile from '../pages/login-v3-mobile.js';

import CooperativasList from '../pages/admin/cooperativas-list.js';
import CooperativasCRUD from '../pages/admin/cooperativas-crud.js';

import UsuariosList from '../pages/admin/usuarios-list.js';
import UsuariosCRUD from '../pages/admin/usuarios-crud.js';

import CuentasResume from '../pages/admin/cuentas-resume.js';
import CuentasList from '../pages/admin/cuentas-list.js';
import CuentasProfile from '../pages/admin/cuentas-profile.js';
import CuentasComite from '../pages/admin/cuentas-comite.js';

import ComitesDetalle from '../pages/admin/comites-detalle.js';
import ComitesRevisar from '../pages/admin/comites-revisar.js';
import ComitesEditar from '../pages/admin/comites-editar.js';

import ParcelasResume from '../pages/admin/parcelas-resume.js';
import ParcelasList from '../pages/admin/parcelas-list.js';
import ParcelasProfileWrapper from '../pages/admin/parcelas-profile-wrapper.js';
import ParcelasProfilePrint from '../pages/admin/parcelas-profile-print.js';
import CrearParcelas from '../pages/admin/crear-parcelas.js';

import PadronConcentradoList from '../pages/admin/padron-concentrado-list.js';
import PadronCompletoList from '../pages/admin/padron-completo-list.js';
import PadronAltasList from '../pages/admin/padron-altas-list.js';
import PadronBajasList from '../pages/admin/padron-bajas-list.js';
import PadronMapasList from '../pages/admin/padron-mapas-list.js';

import CsvList from '../pages/admin/csv-list.js';

import LocalidadesList from '../pages/admin/localidades-list.js';

import VersionesList from '../pages/admin/versiones-list.js';

import AcopioResume from '../pages/acopios/acopio-resume.js';
import AcopioXProductorList from '../pages/acopios/acopio-x-productor-list.js';
import EntregasXProductorList from '../pages/acopios/entregas-x-productor-list.js';
import AnticiposTables from '../pages/acopios/anticipos-tables.js';
import EntregasEfectivoList from '../pages/acopios/entregas-efectivo-list.js';
import PrecioXCooperativaList from '../pages/acopios/precio-x-cooperativa-list.js';
import VincularSicList from '../pages/admin/vincular-sic-list-p1.js';
import VincularSicListP2 from '../pages/admin/vincular-sic-list-p2.js';
import VincularSicListP3 from '../pages/admin/vincular-sic-list-p3.js';
import VincularSicParcelas from '../pages/admin/vincular-sic-parcelas-p1.js';
import VincularSicParcelasP2 from '../pages/admin/vincular-sic-parcelas-p2.js';
import VincularSicParcelasP3 from '../pages/admin/vincular-sic-parcelas-p3.js';
import VincularSicParcelasP4 from '../pages/admin/vincular-sic-parcelas-p4.js';
// import Construction from '../components/construction/construction.js';
import VincularSicParcelasNoParcela from '../pages/admin/vincular-sic-parcelas-no-parcela.js';
import VincularSicParcelasNoParcelaConfirm from '../pages/admin/vincular-sic-no-parcela-confirm.js';
import EntregasXProductor from '../pages/acopios/entregas-x-productor/entregas-x-productor.js';
import EntregasXProductorSeleccion from '../pages/acopios/entregas-x-productor/entregas-x-productor-seleccion.js';
import EntregasXProductorPagoPendiente from '../pages/acopios/entregas-x-productor/entregas-x-productor-pago-pendiente.js';
import CuentasForm from '../pages/admin/cuentas-form.js';
import EntregasXLotesList from '../pages/acopios/lotes/entregas-x-lote-list.js';
import EntregasRemanenteList from '../pages/acopios/entregas-remanente-list.js';

import CalidadList from '../pages/calidad/calidad-list.js';
import CalidadSeleccion from '../pages/calidad/calidad-seleccion.js';
import CalidadAnalisisFisico from '../pages/calidad/calidad-analisis-fisico.js';
import CalidadAnalisisFisicoInfo from '../pages/calidad/calidad-analisis-fisico-info.js';
import CalidadAnalisisSensorial from '../pages/calidad/calidad-analisis-sensorial.js';
import CalidadAnalisisSensorialInfo from '../pages/calidad/calidad-analisis-sensorial-info.js';
import NoPage404 from '../components/construction/404.js';
import env from '../environment.js';
import ParcelaEditCroquis from '../pages/admin/parcelas-edit-croquis.js';

const media = window.matchMedia('(min-width: 700px)');

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/user/login-v3" />,
  },
  {
    exact: true,
    path: '/user/login-v3',
    title: 'Login v3',
    component: () => {
      return media.matches ? <LoginV3 /> : <LoginV3Mobile />;
    },
  },
  {
    exact: true,
    path: '/dashboard',
    title: 'Dashboard',
    coopconfig: { variable: 'mostrar_dashboard_inicio', cond: '=', value: 1 },
    component: () => <InicioResume />,
    //component: () => <Construction />,
  },

  //COOPERATIVAS
  {
    exact: true,
    path: '/admin/cooperativas/list',
    title: 'Cooperativas',
    component: () => <CooperativasList />,
  },
  {
    exact: true,
    path: '/admin/cooperativas/create',
    title: 'Cooperativas',
    component: () => <CooperativasCRUD />,
  },
  {
    exact: true,
    path: '/admin/cooperativas/edit/:uuid',
    title: 'Cooperativas',
    component: () => <CooperativasCRUD />,
  },

  //USUARIOS
  {
    exact: true,
    path: '/admin/usuarios/list',
    title: 'Usuarios',
    component: () => <UsuariosList />,
  },
  {
    exact: true,
    path: '/admin/usuarios/create',
    title: 'Permisos',
    component: () => <UsuariosCRUD />,
  },
  {
    exact: true,
    path: '/admin/usuarios/edit/:uuid',
    title: 'Permisos',
    component: () => <UsuariosCRUD />,
  },
  //CUENTAS
  {
    exact: true,
    path: '/admin/cuentas',
    title: 'Cuentas',
    coopconfig: {
      variable: 'mostrar_dashboard_productores',
      cond: '=',
      value: 1,
    },
    component: () => <CuentasResume />,
    //component: () => <Construction />,
  },
  {
    exact: true,
    path: '/admin/cuentas/list',
    title: 'Cuentas',
    component: () => <CuentasList />,
  },
  {
    exact: true,
    path: '/admin/cuentas/perfil/:uuid',
    title: 'Cuentas',
    component: () => <CuentasProfile />,
  },
  {
    exact: true,
    path: '/admin/cuentas/comite/:uuid',
    title: 'Cuentas',
    component: () => <CuentasComite />,
  },

  //COMITE DE APROBACION
  {
    exact: true,
    path: '/admin/comite/:uuid',
    title: 'Comité de aprobación',
    component: () => <ComitesDetalle />,
  },
  //REVISAR EVALUACION DEL COMITE
  {
    exact: true,
    path: '/admin/cuentas/comite/revisar/:uuid',
    title: 'Revisar comité de aprobación',
    component: () => <ComitesRevisar />,
  },

  {
    exact: true,
    path: '/admin/comites-editar/:uuid',
    title: 'Editar comité de aprobación',
    component: () => <ComitesEditar />,
  },

  //PARCELAS
  {
    exact: true,
    path: '/admin/parcelas',
    title: 'Parcelas',
    component: () => <ParcelasResume />,
    //component: () => <Construction />,
  },
  {
    exact: true,
    path: '/admin/parcelas/list',
    title: 'Parcelas',
    component: () => <ParcelasList />,
  },
  {
    exact: true,
    path: '/admin/parcelas/perfil/:uuid',
    title: 'Parcelas',
    component: () => <ParcelasProfileWrapper />,
  },
  {
    exact: true,
    path: '/admin/parcelas/perfil/print/:uuid',
    title: 'Parcelas',
    component: () => <ParcelasProfilePrint />,
  },
  {
    exact: true,
    path: '/admin/parcelas/perfil/editcroquis/:uuid',
    title: 'Parcela - Editar croquis',
    component: () => <ParcelaEditCroquis />,
  },
  {
    exact: true,
    path: '/admin/parcelas/create/:uuid',
    title: 'CrearParcelas',
    component: () => <CrearParcelas />,
  },
  //PADRON
  {
    exact: true,
    path: '/admin/padron/completo/list',
    title: 'Padrón Completo',
    coopconfig: { variable: 'mostrar_padron_completo', cond: '=', value: 1 },
    component: () => <PadronCompletoList />,
  },
  {
    exact: true,
    path: '/admin/padron/concentrado/list',
    title: 'Padrón Concentrado',
    coopconfig: { variable: 'mostrar_padron_concentrado', cond: '=', value: 1 },
    component: () => <PadronConcentradoList />,
  },
  {
    exact: true,
    path: '/admin/padron/altas/list',
    title: 'Padrón Altas',
    component: () => <PadronAltasList />,
    //component: () => <Construction />,
  },
  {
    exact: true,
    path: '/admin/padron/bajas/list',
    title: 'Padrón Bajas',
    component: () => <PadronBajasList />,
    //component: () => <Construction />,
  },
  {
    exact: true,
    path: '/admin/padron/mapas/list',
    title: 'Padrón Mapas',
    component: () => <PadronMapasList />,
    //component: () => <Construction />,
  },

  //CSV
  {
    exact: true,
    path: '/admin/csv/list',
    title: 'CSV',
    component: () => <CsvList />,
  },

  //LOCALIDADES
  {
    exact: true,
    path: '/admin/localidades/list',
    title: 'Localidades',
    component: () => <LocalidadesList />,
  },

  //VERSIONES
  {
    exact: true,
    path: '/admin/versiones/list',
    title: 'Versiones',
    component: () => <VersionesList />,
  },

  //VINCULAR SIC
  {
    exact: true,
    path: '/admin/vincular-sic/list',
    title: 'Vincular Sic Paso 1',
    component: () => <VincularSicList />,
  },
  {
    exact: true,
    path: '/admin/vincular-sic/list-p2',
    title: 'Vincular Sic Paso 2',
    component: () => <VincularSicListP2 />,
  },
  {
    exact: true,
    path: '/admin/vincular-sic/list-p3',
    title: 'Vincular Sic Paso 3',
    component: () => <VincularSicListP3 />,
  },
  {
    exact: true,
    path: '/admin/vincular-sic-parcelas/list',
    title: 'Vincular Parcelas Paso 1',
    component: () => <VincularSicParcelas />,
  },
  {
    exact: true,
    path: '/admin/vincular-sic-parcelas/list-p2',
    title: 'Vincular Parcelas Paso 2',
    component: () => <VincularSicParcelasP2 />,
  },
  {
    exact: true,
    path: '/admin/vincular-sic-parcelas/list-p3',
    title: 'Vincular Parcelas Paso 3',
    component: () => <VincularSicParcelasP3 />,
  },
  {
    exact: true,
    path: '/admin/vincular-sic-parcelas/list-p4',
    title: 'Vincular Parcelas Paso 4',
    component: () => <VincularSicParcelasP4 />,
  },
  {
    exact: true,
    path: '/admin/vincular-sic-parcelas/list-No-parcela',
    title: 'Vincular Sic Paso 3(no-parcela)',
    component: () => <VincularSicParcelasNoParcela />,
  },
  {
    exact: true,
    path: '/admin/vincular-sic-parcelas/list-No-parcela-confirm',
    title: 'Vincular Sic Paso 4(no-parcela)',
    component: () => <VincularSicParcelasNoParcelaConfirm />,
  },

  //ACOPIOS
  {
    exact: true,
    path: '/admin/acopio',
    title: 'Acopio',
    component: () => <AcopioResume />,
  },
  {
    exact: true,
    path: '/admin/acopio/acopio_por_productor/list',
    title: 'Por productor',
    component: () => <AcopioXProductorList />,
  },
  {
    exact: true,
    path: '/admin/acopio/entregas_por_productor/list',
    title: 'Entregas',
    component: () => <EntregasXProductorList />,
  },
  {
    exact: true,
    path: '/admin/acopio/entregas_por_productor/seleccion',
    title: 'Entregas',
    component: () => <EntregasXProductorSeleccion />,
  },
  {
    exact: true,
    path: '/admin/acopio/entregas_por_productor/pago_pendiente/:id_cuenta/:uuid_cuenta/:id_acopio',
    title: 'Entregas',
    component: () => <EntregasXProductorPagoPendiente />,
  },
  {
    exact: true,
    path: '/admin/acopio/entregas_por_productor/form/:id_cuenta/:uuid_cuenta/:uuid_acopio?',
    title: 'Entregas',
    component: () => <EntregasXProductor />,
  },
  {
    exact: true,
    path: '/admin/acopio/anticipos_por_productor/list',
    title: 'Créditos',
    coopconfig: {
      variable: 'id_cat_credito_precosecha',
      cond: '>=',
      value: '2',
    },
    component: () => <AnticiposTables />,
  },
  {
    exact: true,
    path: '/admin/acopio/entregas_efectivo/list',
    title: 'Efectivo',
    component: () => <EntregasEfectivoList />,
  },
  {
    exact: true,
    path: '/admin/acopio/precio_por_cooperativa/list',
    title: 'Precio',
    component: () => <PrecioXCooperativaList />,
  },
  {
    exact: true,
    path: '/admin/acopio/lotes',
    title: 'Lotes',
    component: () => <EntregasXLotesList />,
  },
  {
    exact: true,
    path: '/admin/acopio/remanente/list',
    title: 'Remanente',
    component: () => <EntregasRemanenteList />,
  },
  ...(env.appEnv === 'local' || env.appEnv === 'dev'
    ? [
        {
          exact: true,
          path: '/admin/calidad',
          title: 'Calidad',
          coopconfig: { variable: 'usa_sic', cond: '=', value: '1' },
          component: () => <CalidadList />,
        },
        {
          exact: true,
          path: '/admin/calidad/seleccion',
          title: 'Calidad',
          component: () => <CalidadSeleccion />,
        },
        {
          exact: true,
          path: '/admin/calidad/analisis/fisico/registro',
          title: 'Análisis Físico',
          component: () => <CalidadAnalisisFisico />,
        },
        {
          exact: true,
          path: '/admin/calidad/analisis/fisico/info',
          title: 'Análisis Físico',
          component: () => <CalidadAnalisisFisicoInfo />,
        },
        {
          exact: true,
          path: '/admin/calidad/analisis/sensorial/registro',
          title: 'Análisis Sensorial',
          component: () => <CalidadAnalisisSensorial />,
        },
        {
          exact: true,
          path: '/admin/calidad/analisis/sensorial/info',
          title: 'Análisis Sensorial',
          component: () => <CalidadAnalisisSensorialInfo />,
        },
      ]
    : []),
  {
    exact: true,
    path: '/admin/cuentas/agregar',
    title: 'Productores',
    component: () => <CuentasForm />,
  },
  //TODO: cambiar el renderizado de este componente por un redirect propiamente dicho, porque el título no se modifica
  {
    exact: true,
    path: '**',
    title: 'Esta página no existe',
    component: () => <NoPage404 />,
  },
];

export default routes;
