import React from 'react';
import env from '../../environment';

const IconWrong = () => (
  <i
    className="fa fa-times"
    aria-hidden="true"
    style={{
      color: env.colors.error,
      marginRight: 10,
    }}
  />
);

export default IconWrong;
