import React, {useEffect, useCallback, useMemo, createRef, useState} from 'react';
import LabelNumber from '../../../components/label/label-number.jsx';
import LabelDate from '../../../components/label/label-date.jsx';
import LabelColor from '../../../components/label/label-color.jsx';
import { useAuth } from '../../../components/content/auth.jsx';
import NoPagination from '../../../components/pagination/noPagination.jsx';
import SearchColumn from '../../../components/search/search-column.jsx';
import useEfectivo from '../../../hooks/useEfectivo.jsx';
import CheckboxList from '../../../components/forms/checkbox-list.jsx';
import ModalEfectivo from '../../../components/modal/modal-efectivo.jsx';
import Swal from 'sweetalert2';
import Api from '../../../api/global.service.js';
import { subscribe, unsubscribe } from '../../../components/events.js';


const EntregaEfectivoPorMovimiento = ({ 
  ciclo, update
}) => {
  const auth = useAuth();
  const [idSeleccionado, setIdSeleccionado] = useState(0);
  const [dataSeleccionado, setDataSeleccionado] = useState({});
  const [blocking, setBlocking] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [data, setData] = useState([]);
  const [dynamicColumnsData, setDynamicColumnsData] = useState([]);
  const [edit, setEdit] = useState(null);
  const dialog = createRef();
  const onShowModal = () => dialog.current.add();
  const { 
    searchColumns,
    handleMovimientosCheck,
    getBackgroundColor,
    getColor,
    eliminarEfectivo,
    addCentro_acopio_nombre,
  } = useEfectivo({ ciclo, auth });

  const updateData = useCallback(async (id_ciclo) => {
    setBlocking(true);
    try {
      const movementsResponse = await Api.Acopio.get(`efectivo_por_movimiento/${id_ciclo}`);
      setData(movementsResponse.data);
      setOriginalData(movementsResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setBlocking(false);
    }
  }, [auth.user.id_cooperativa]);

  const baseColumns = useMemo(() => [
    {
      Header: 'INFORMACIÓN GENERAL',
      columns: [
        {
          id: 'check',
          accessor: 'id',
          sortable: false,
          width: 50,
          Cell: ({ row }) => (
            <div className="form-check">
              <CheckboxList
                name="movimiento"
                value={row.original.id}
                handleCheck={(event) => handleMovimientosCheck(event, row.original, setIdSeleccionado, setDataSeleccionado)}
                checked={idSeleccionado === Number(row.original.id)}
              />
            </div>
          ),
        },
        {
          Header: 'ID',
          accessor: 'id',
          sortable: true,
          minWidth: 50,
        },
        {
          Header: 'CENTRO DE ACOPIO',
          accessor: 'centro_acopio.nombre',
          sortable: true,
          minWidth: 150,
        },
        {
          Header: 'FECHA',
          accessor: 'fecha_envio',
          sortable: true,
          minWidth: 80,
          Cell: ({ value }) => {
            return <LabelDate value={value}></LabelDate>;
          },
        },
        {
          Header: 'DESEMBOLSO EFECTIVO',
          accessor: 'monto',
          sortable: true,
          minWidth: 50,
          Cell: ({ value }) => {
            return <LabelNumber value={value} currency={true} digits={2}></LabelNumber>;
          },
        },
        {
          Header: 'MOTIVO DESEMBOLSO DE EFECTIVO',
          accessor: 'motivo',
          sortable: true,
          minWidth: 100,
          Cell: ({ value, row }) => {
            let v = value;
            if (v != null) return <LabelColor label={value} color={row.original.motivo_color} />;
            else return '';
          },
        },
        {
          Header: 'NOMBRE DE ACOPIADOR',
          accessor: 'usuario_acopiador.nombre',
          sortable: true,
          minWidth: 200,
        },
        {
          Header: 'REGISTRO',
          accessor: 'usuario_registro',
          sortable: true,
          minWidth: 350,
          Cell: ({ value, row }) => {
            if (value != null) return `Registrado por ${value.nombre} el ${row.original.created_at.substr(0, 10)}`;
            else return '';
          },
        },
      ],
    },
  ], [idSeleccionado, handleMovimientosCheck, dataSeleccionado, data, update]);

    // const dynamicColumns = useMemo(() => {
    //   if (!dynamicColumnsData.length) return baseColumns;
    //   return generateDynamicColumns(
    //     dynamicColumnsData,
    //     baseColumns,
    //     auth,
    //     coopConfig,
    //     onClickEdit
    //   );
    // }, [dynamicColumnsData, baseColumns]);

  const editButton = useCallback((e) => {
    e.preventDefault();
    if (!dataSeleccionado) return;
    
    const editData = {
      id: dataSeleccionado.id,
      fecha_envio: dataSeleccionado.fecha_envio,
      id_centros_acopios: dataSeleccionado.id_centros_acopios,
      monto: dataSeleccionado.monto,
      tipo_motivo: dataSeleccionado.tipo_motivo,
      acopiador: dataSeleccionado.acopiador
    };
    
    setEdit(editData);
  }, [dataSeleccionado]);
  
    useEffect(() => {
      if (ciclo?.id) {
        updateData(ciclo.id);
      }
    }, [ciclo]);

    useEffect(() => {
      setDynamicColumnsData(prevColumns => {
        // para actualizar el checkbox
        const updatedColumns = [...prevColumns];
        if (updatedColumns[0]?.columns?.[0]?.id === 'check') {
          updatedColumns[0].columns[0] = {
            ...updatedColumns[0].columns[0],
            Cell: ({ row }) => (
              <div className="form-check">
                <CheckboxList
                  name="movimiento"
                  value={row.original.id}
                  handleCheck={event => handleMovimientosCheck(event, row.original)}
                  checked={idSeleccionado === Number(row.original.id)}
                />
              </div>
            ),
          };
        }
        return updatedColumns;
      });
    }, [idSeleccionado]);

    const setDataFilter = data => {
      setData(data);
    };

    const onClickEdit = (event, data) => {
      event.preventDefault();
      setEdit(data);
    };
  
    const handleSave = useCallback(event => {
      Swal.fire({
        icon: 'success',
        title: '',
        text: `Se guardó con éxito el desembolso de efectivo ${event.detail.id}.`,
      });
  
        updateData(event.detail.id_ciclos);
    }, [ciclo]);
  
    useEffect(() => {
      return () => {
        setData([]);
        setOriginalData([]);
      };
    }, []);

    useEffect(() => {
      subscribe('Efectivo::onSave', e => handleSave(e));
      return () => {
        unsubscribe('Efectivo::onSave');
      };
    }, []);
  
    useEffect(() => {
      if (edit !== null) {      
        dialog.current.edit(edit);
      }
      return () => {
        setEdit(null);
        setIdSeleccionado(null);
        setDataSeleccionado(null);
      };
    }, [edit]);

  return (
    <>
      <ModalEfectivo ref={dialog} title="desembolso de efectivo"></ModalEfectivo>
      <div className="row mb-2 justify-content-between">
        <div className="col-6">
          <SearchColumn
            data={addCentro_acopio_nombre(originalData)}
            columns={searchColumns}
            setDataFilter={setDataFilter}
          />
        </div>
        <div className="col-2">
            {auth.hasPermission('ACOPIOS_ENTREGAS_EFECTIVO_NUEVO') && (
              <button onClick={onShowModal} type="button" className="btn btn-sirio pr-4 pl-4" style={{ borderRadius: '20px 20px 20px 20px' }}>
                <i className="fa fa-plus mr-2"></i> Nuevo
              </button>
            )}
        </div>
        <div className="col-2 d-flex justify-content-center">
          <button
              onClick={(e) => {editButton(e)}}
              type="button"
              className="btn btn-outline-secondary pr-3"
              disabled={!idSeleccionado || idSeleccionado === 0}
              style={{
                // color: Colors.primary_500,
                // borderColor: Colors.primary_500,
                borderRadius: '20px 20px 20px 20px',
                flexGrow: 1,
                flexBasis: 'auto',
                opacity: idSeleccionado > 0 ? 1 : 0.4,
              }}>
              <span>
                <i className="fa fa-edit mr-2"></i> {'Editar'}
              </span>
            </button>
          </div>
        <div className="col-2 d-flex justify-content-center">
            <button
              onClick={() => eliminarEfectivo(idSeleccionado, Api, setIdSeleccionado, setDataSeleccionado, updateData)}
              type="button"
              className="btn btn-outline-secondary btn-sirio pr-3"
              disabled={!idSeleccionado || idSeleccionado === 0}
              style={{
                borderColor: 'red',
                borderRadius: '20px 20px 20px 20px',
                flexGrow: 1,
                flexBasis: 'auto',
                opacity: idSeleccionado > 0 ? 1 : 0.4,
              }}>
              <span style={{ color: 'red' }}>
                <i className="fa fa-trash pr-2"></i> {' Eliminar'}
              </span>
            </button>
        </div>
      </div>

      <NoPagination 
        blocking={blocking}
        selected="id"
        columns={baseColumns}
        data={data}
        fnBackgroundColor={getBackgroundColor}
        fnColor={getColor}
      />
    </>
  );
};

export default EntregaEfectivoPorMovimiento;
