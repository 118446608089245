import React, { useState, useEffect, createRef, useCallback, useMemo } from 'react';
import CardContainer from '../../components/card/card-container.jsx';
import LinkDownload from '../../components/link/link-download';
import ModalEfectivo from '../../components/modal/modal-efectivo.jsx';
import Api from '../../api/global.service';
import { useAuth } from '../../components/content/auth.jsx';
import env from '../../environment.js';
import EntregaEfectivoPorMovimientoTable from '../../nuup/componentes/Tables/entrega-efectivo-por-movimiento.js';
import EntregaEfectivoPorCentroAcopioTable from '../../nuup/componentes/Tables/entrega-efectivo-por-centro-acopio.js';
import SelectCiclo from '../../components/select/select-ciclo.jsx';
import HalfDonut from '../../components/chart/half-donut.jsx';
import CardColor from '../../components/card/card-color.jsx';
import { useCoopConfig } from '../../components/content/coop-config.jsx';
import useEfectivo from '../../hooks/useEfectivo.jsx';

const EntregasEfectivoList = () => {
  const auth = useAuth();
  const coopConfig = useCoopConfig();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [blocking, setBlocking] = useState(true);
  const [porMovimiento, setPorMovimiento] = useState(true);
  const [totalPagado, setTotalPagado] = useState(0);
  const [totalDesembolsado, setTotalDesembolsado] = useState(100);
  const [percentagePayedSent, setPercentagePayedSent] = useState(0);
  const [dataMovimiento, setDataMovimiento] = useState([]);
  const [ciclo, setCiclo] = useState({ id: null });

  const { exportarXlsx } = useEfectivo({ ciclo, auth });

  const dialog = createRef();

  const setFilter = useCallback(value => {
    if (!value?.target?.id) return;
    setCiclo(value.target);
  }, []);

  const updateData = useCallback(
    async id_ciclo => {
      setBlocking(true);

      try {
        const response = await Api.Acopio.search(`efectivo_por_centro_acopio/${id_ciclo}`, {
          filter: [{ column: 'id_cooperativa', operator: '=', value: auth.user.id_cooperativa }],
        });

        const totalSent = response.data.reduce((current, sum) => current + +sum.desembolsos_sum_cantidad, 0);
        const totalPayed = response.data.reduce((current, sum) => current + +sum.acopio_pagos_sum_total, 0);
        const avance_anticipos = parseInt(+totalSent !== 0 ? (totalPayed * 100) / totalSent : 0);

        setTotalDesembolsado(totalSent);
        setTotalPagado(totalPayed);
        setPercentagePayedSent(avance_anticipos);
        setData(response.data);
        setOriginalData(response.data);
        const movementsResponse = await Api.Acopio.get(`efectivo_por_movimiento/${id_ciclo}`);
        setDataMovimiento(movementsResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setBlocking(false);
      }
    },
    [auth.user.id_cooperativa]
  );

  useEffect(() => {
    if (ciclo?.id) {
      updateData(ciclo.id);
    }
  }, [ciclo]);

  const handlePorMovimientoChange = useCallback(newValue => {
    setPorMovimiento(newValue);
  }, []);

  useEffect(() => {
    return () => {
      setCiclo({ id: null });
    };
  }, []);

  return (
    <div>
      <ModalEfectivo ref={dialog} title="desembolso de efectivo"></ModalEfectivo>
      <div className="row justify-content-between ml-2">
        <SelectCiclo setFilter={setFilter} acopio />
      </div>
      <div className="row justify-content-between mb-2 mt-1">
        <div className="btn-group col-5" role="group" style={{ borderRadius: 1 }}>
          <button
            type="button"
            onClick={() => handlePorMovimientoChange(true)}
            className={'btn btn-light'}
            style={{
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
              backgroundColor: porMovimiento ? '#FFEED9' : 'transparent',
              borderWidth: 1,
              borderColor: '#d4d4d4',
            }}
            disabled={false}>
            por Movimiento
          </button>
          <button
            type="button"
            onClick={() => handlePorMovimientoChange(false)}
            className={'btn btn-light'}
            style={{
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
              backgroundColor: !porMovimiento ? '#FFEED9' : 'transparent',
              borderWidth: 1,
              borderColor: '#d4d4d4',
              color: data.length < 2 ? 'rgba(255, 255, 255, 0.5)' : 'white',
            }}
            disabled={!data || data.length === 0 || data[0]?.nombre === 'Sin Asignarse'}>
            por Centro de Acopio
          </button>
        </div>
        {!porMovimiento ? (
          <div className="col-4 mr-3">
            {auth.hasPermission('ACOPIOS_ENTREGAS_EFECTIVO_EXPORTAR') && (
              <div className="d-flex justify-content-end mb-2 mt-2">
                <LinkDownload
                  url={`xls/efectivo_cooperativa/${ciclo.id}/${auth.user.id_cooperativa}`}
                  label="Descargar Excel"
                  icon={env.images.icon.xls}></LinkDownload>
              </div>
            )}
          </div>
        ) : (
          <div className="col-4 d-flex justify-content-end flex-direction-row">
            {auth.hasPermission('ACOPIOS_ENTREGAS_EFECTIVO_EXPORTAR') && (
              <button onClick={() => exportarXlsx(dataMovimiento)} className={`btn`} type="button">
                <img src={env.images.icon.xls} className="pr-2" />
                <span style={{ color: '#789D16', fontSize: '0.73rem' }}>Descargar Excel</span>
              </button>
            )}
          </div>
        )}
      </div>

      <CardContainer height={'100%'}>
        {porMovimiento ? (
          <EntregaEfectivoPorMovimientoTable ciclo={ciclo} update={porMovimiento} />
        ) : (
          <EntregaEfectivoPorCentroAcopioTable
            ciclo={ciclo}
            update={porMovimiento}
            data={data}
            originalData={originalData}
            setData={setData}
            setOriginalData={setOriginalData}
          />
        )}
        <div className="row">
          <div className="col-8"></div>

          <div className="col-4">
            <div className="col-xl-12 col-md-12">
              <h1 className="font-lato-normal-600 halfdonut-percent">{percentagePayedSent}%</h1>
              <HalfDonut value={percentagePayedSent > 100 ? 100 : percentagePayedSent} color={'#BC8268'} width={250}></HalfDonut>
            </div>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-6 col-md-6">
                  <CardColor
                    height={120}
                    label={'pagado a productores'}
                    value={
                      coopConfig.currency()?.symbol +
                      totalPagado.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }
                    mode={'onlyData'}
                    size={'16px'}
                    style={{ textAlign: 'center' }}></CardColor>
                </div>
                <div className="col-xl-6 col-md-6">
                  <CardColor
                    height={120}
                    label={'efectivo otorgado para pagos'}
                    value={
                      coopConfig.currency()?.symbol +
                      totalDesembolsado.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }
                    mode={'onlyData'}
                    size={'16px'}
                    style={{ textAlign: 'center' }}></CardColor>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardContainer>
    </div>
  );
};

export default EntregasEfectivoList;
