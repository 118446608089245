import React, { useState, useEffect, createRef } from 'react';
import { Link } from 'react-router-dom';
import CardContainer from '../../components/card/card-container.jsx';
import LabelCategory from '../../components/label/label-category.jsx';
import LabelNumber from '../../components/label/label-number.jsx';
import Api from '../../api/global.service';
import { useAuth } from '../../components/content/auth.jsx';
import env from '../../environment.js';
import Swal from 'sweetalert2';

import NoPagination from '../../components/pagination/noPagination.jsx';
import SelectCiclo from '../../components/select/select-ciclo.jsx';

import { subscribe, unsubscribe } from '../../components/events';
import LinkDownload from '../../components/link/link-download.jsx';
import { useHistory } from 'react-router-dom';
import FormSelectMaterial from '../../nuup/componentes/Forms/FormSelectMaterial.jsx';
import CheckboxList from '../../components/forms/checkbox-list.jsx';
import LabelEstatus from '../../components/label/label-estatus.jsx';
import { useCoopConfig } from '../../components/content/coop-config.jsx';
import SearchColumn from '../../components/search/search-column.jsx';
import CalidadListExport from './components/calidad-list-export.js';
import getBackgroundColor from '../../utils/getBackgroundColor.js';
import getColor from '../../utils/getColor.js';

const CalidadList = () => {
  const history = useHistory();
  const auth = useAuth();
  const coopConfig = useCoopConfig();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [ciclo, setCiclo] = useState({});
  const [blocking, setBlocking] = useState(false);
  const [btnLotes, setBtnLotes] = useState(true);
  const [idsSeleccionados, setIdsSeleccionados] = useState([]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'INFORMACIÓN GENERAL DE LA MUESTRA',
        columns: [
          {
            Header: 'FOLIO',
            accessor: 'folio',
            sortable: true,
            minWidth: 50,
            Cell: ({ value, row }) => {
              return (
                <>
                  <span onClick={() => nuevoAnalisis(row.original)} style={{ marginRight: 10, color: '#999' }}>
                    <i className="fa fa-pen"></i>
                  </span>
                  <span>
                    <LinkDownload
                      url={`calidad/analisis/recibo/descargar/${row.original.uuid_calidad_analisis}`}
                      label={value}></LinkDownload>
                  </span>
                </>
              );
            },
          },
          {
            Header: 'FECHA',
            accessor: 'fecha',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'TIPO',
            accessor: 'tipo_analisis',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'NOMBRE',
            accessor: 'nombre',
            sortable: true,
            minWidth: 75,
            Cell: ({ value, row }) => {
              return Number(row.original.id_tipo_analisis) === 1 ? (
                <Link
                  to={{ pathname: `/admin/cuentas/perfil/${row.original.uuid_productor}` }}
                  className="m-r-2"
                  style={{ color: '#789D16' }}>
                  <span>{value}</span>
                </Link>
              ) : (
                <span>{value}</span>
              );
            },
          },
          {
            Header: 'DETALLE',
            accessor: 'detalle',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'REGIÓN',
            accessor: 'region',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'ALTURA PROMEDIO',
            accessor: 'altura_promedio',
            sortable: true,
            minWidth: 50,
          },
        ],
      },
      {
        Header: 'RESUMEN DE ANÁLISIS',
        columns: [
          {
            Header: 'RENDIMIENTO',
            accessor: 'resumen_rendimiento',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2} suffix={'%'}></LabelNumber>;
            },
          },
          {
            Header: 'RENDIMIENTO EXPORTABLE',
            accessor: 'resumen_rendimiento_exportable',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'PUNTAJE EN TAZA',
            accessor: 'resumen_puntaje_taza',
            sortable: true,
            minWidth: 50,
          },
        ],
      },
      {
        Header: 'RESULTADOS FÍSICOS DE LA MUESTRA',
        columns: [
          {
            Header: 'PROCESO',
            accessor: 'nombre_tipo_proceso',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'RENDIMIENTO',
            accessor: 'porcentaje_rendimiento',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2} suffix={'%'}></LabelNumber>;
            },
          },
          {
            Header: 'CEREZO',
            accessor: 'porcentaje_cerezo',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2} suffix={'%'}></LabelNumber>;
            },
          },
          {
            Header: 'MANCHA',
            accessor: 'porcentaje_mancha',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2} suffix={'%'}></LabelNumber>;
            },
          },
          {
            Header: 'HUMEDAD (%)',
            accessor: 'pergamino_humedad',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'OLOR',
            accessor: 'olor_muestra',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'DENSIDAD (g./l.)',
            accessor: 'pergamino_densidad',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'ACTIVIDAD EN AGUA',
            accessor: 'pergamino_actividad_agua',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'COLOR',
            accessor: 'nombre_color_muestra',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'RENDIMIENTO EXPORTABLE',
            accessor: 'resumen_rendimiento_exportable_2',
            sortable: true,
            minWidth: 50,
          },

          {
            Header: 'TOTAL DEFECTOS',
            accessor: 'defectos_gramos',
            sortable: true,
            minWidth: 50,
            Cell: ({ value, row }) => {
              if (row.original.defectos_gramos != null) {
                return value + ' grs.';
              } else {
                return '-';
              }
            },
          },
          {
            Header: 'DEFECTOS',
            accessor: 'defectos',
            sortable: true,
            minWidth: 75,
          },
          {
            Header: 'OBSERVACIONES',
            accessor: 'observaciones',
            sortable: true,
            maxWidth: 100,
            Cell: ({ value, row }) => {
              return <p style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 150 }}>{value}</p>;
            },
          },
        ],
      },
      {
        Header: 'RESULTADOS SENSORIALES DE LA MUESTRA',
        columns: [
          {
            Header: 'PUNTAJE EN TAZA',
            accessor: 'puntaje_taza',
            sortable: true,
            minWidth: 75,
          },
          {
            Header: 'FRAGANCIA / AROMA',
            accessor: 'fragancia_aroma_desc',
            sortable: true,
            minWidth: 75,
            Cell: ({ value, row }) => {
              return <p style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 150 }}>{value}</p>;
            },
          },
          {
            Header: 'PERFIL EN TAZA',
            accessor: 'perfil_taza',
            sortable: true,
            maxWidth: 100,
            Cell: ({ value, row }) => {
              return <p style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 150 }}>{value}</p>;
            },
          },
          {
            Header: 'OBSERVACIONES',
            accessor: 'descripcion',
            sortable: true,
            maxWidth: 100,
            Cell: ({ value, row }) => {
              return <p style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 150 }}>{value}</p>;
            },
          },
        ],
      },
    ],
    [idsSeleccionados, btnLotes]
  );

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'folio', label: 'FOLIO' },
      { value: 2, target: 'fecha', label: 'FECHA' },
      { value: 3, target: 'tipo_analisis', label: 'TIPO' },
      { value: 4, target: 'nombre', label: 'NOMBRE' },
      { value: 5, target: 'detalle', label: 'DETALLE' },
      { value: 6, target: 'region', label: 'REGIÓN' },
      { value: 7, target: 'defectos', label: 'DEFECTOS' },
      { value: 8, target: 'resumen_puntaje_taza', label: 'PUNTAJE EN TAZA' },
      { value: 9, target: 'descripcion', label: 'OBSERVACIONES' },
    ],
    []
  );

  const setFilter = value => {
    setCiclo(value.target);
  };

  const setDataFilter = data => {
    setData(data);
  };

  const getData = () => {
    Api.getAll('calidad/analisis')
      .then(response => {
        setBlocking(false);
        if (typeof response.data !== 'undefined') {
          setData(response.data);
          setOriginalData(response.data);
        }
        setBlocking(false);
      })
      .catch(e => {
        console.log('e', e);
        setBlocking(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const nuevoAnalisis = (data = {}) => {
    let defaultConfig = {
      id_calidad_analisis: '',
      id_calidad_analisis_fisico: '',
      id_calidad_analisis_sensorial: '',
      id_cuenta: '',
      id_acopio: '',
      id_lote: '',
      tipo_analisis: 1,
      analisis_por_entrega: 2,
      nombre_proveedor: '',
      region_proveedor: '',
      notas_adicionales: '',
      id_municipio: '',
      id_localidad: '',
    };

    if (typeof data.uuid_calidad_analisis !== 'undefined' && data.uuid_calidad_analisis.length === 36) {
      defaultConfig.id_calidad_analisis = data.uuid_calidad_analisis;

      if (data.uuid_calidad_analisis_fisico !== null && data.uuid_calidad_analisis_fisico.length === 36) {
        defaultConfig.id_calidad_analisis_fisico = data.uuid_calidad_analisis_fisico;
      }

      if (data.uuid_calidad_analisis_sensorial !== null && data.uuid_calidad_analisis_sensorial.length === 36) {
        defaultConfig.id_calidad_analisis_sensorial = data.uuid_calidad_analisis_sensorial;
      }

      if (data.id_cuenta !== null && Number(data.id_cuenta) > 0) {
        defaultConfig.id_cuenta = Number(data.id_cuenta);
      }

      if (data.id_acopio !== null && data.id_acopio.length > 0) {
        defaultConfig.id_acopio = data.id_acopio;
      }

      if (data.id_recoleccion_acopio !== null && Number(data.id_recoleccion_acopio) > 0) {
        defaultConfig.id_lote = Number(data.id_recoleccion_acopio);
      }

      if (data.id_tipo_analisis !== null && Number(data.id_tipo_analisis) > 0) {
        defaultConfig.tipo_analisis = Number(data.id_tipo_analisis);
      }

      if (data.analisis_por_entrega !== null && Number(data.analisis_por_entrega) > 0) {
        defaultConfig.analisis_por_entrega = Number(data.analisis_por_entrega);
      }

      if (data.nombre_proveedor !== null) {
        defaultConfig.nombre_proveedor = data.nombre_proveedor;
      }

      if (data.region_proveedor !== null) {
        defaultConfig.region_proveedor = data.region_proveedor;
      }

      if (data.notas_adicionales !== null) {
        defaultConfig.notas_adicionales = data.notas_adicionales;
      }

      if (data.id_municipio !== null && Number(data.id_municipio) > 0) {
        defaultConfig.id_municipio = Number(data.id_municipio);
      }

      if (data.id_localidad !== null && data.id_localidad.length > 0) {
        defaultConfig.id_localidad = data.id_localidad;
      }
    }

    localStorage.setItem(`${env.appName}-calidad`, JSON.stringify(defaultConfig));
    history.push({
      pathname: '/admin/calidad/seleccion',
    });
  };

  return (
    <div>
      <CardContainer height={'100%'} style={{ marginTop: 10 }}>
        <div className="row">
          <div className="col-10">
            <SearchColumn
              data={originalData}
              columns={searchColumns}
              setDataFilter={evt => {
                setDataFilter(evt);
              }}></SearchColumn>
          </div>
          <div className="col-2">
            <div className="d-flex justify-content-end">
              {data.length > 0 && (
                <Link to="#" onClick={() => CalidadListExport(data)} className="m-r-2" style={{ color: '#789D16' }}>
                  <img src={env.images.icon.xls} className="pr-1" /> <span>Descargar Excel</span>
                </Link>
              )}
            </div>
          </div>
        </div>

        <div className="div-nombre-lote"> {'Útimos registros de muestras'}</div>
        <NoPagination
          blocking={blocking}
          selected="id"
          columns={columns}
          data={data}
          fnBackgroundColor={getBackgroundColor}
          fnColor={getColor}></NoPagination>

        <div className="row mt-3">
          <div className="col-md-12">
            <button
              style={{ borderRadius: '20px 20px 20px 20px', color: '#333', fontWeight: 'normal', fontSize: 16 }}
              className="btn btn-sirio"
              onClick={() => nuevoAnalisis()}>
              <i className="fa fa-plus"></i> Registrar nuevo análisis
            </button>
          </div>
        </div>
      </CardContainer>
    </div>
  );
};

export default CalidadList;
