import React, { useState, useEffect } from 'react';
import CardContainer from '../../components/card/card-container.jsx';
import Api from '../../api/global.service.js';

import SicLinkTable from '../../components/tables/sic-link-table.jsx';
import { useHistory } from 'react-router-dom';
import toggleIds from '../../utils/toggleIds.js';
import SearchColumn from '../../components/search/search-column.jsx';

const VincularSicList = () => {
  const [dataForms, setDataForms] = useState([]);
  const [blockingLists, setBlockingLists] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const history = useHistory();
  const keyForm = 'uuid_odk'; // Antes id

  useEffect(() => {
    setBlockingLists(true);

    Api.create('admin/sic/vinculos')
      .then(response => {
        setDataForms(response.data);
        setOriginalData(response.data);
        setBlockingLists(false);
      })
      .catch(e => {
        setBlockingLists(false);
        // eslint-disable-next-line no-console
        console.log('e', e);
      });
  }, []);

  const navigateToNextStep = () => {
    const selected = dataForms.filter(it => selectedIds.includes(it[keyForm]));
    history.push({
      pathname: '/admin/vincular-sic/list-p2',
      state: { forms: selected },
    });
  };

  const [originalData, setOriginalData] = useState([]);

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'coop_nombre', label: 'COOPERATIVA' },
      { value: 2, target: 'form_type', label: 'FORMULARIO' },
      { value: 3, target: 'parcela_nombre_otro', label: 'NOMBRE PARCELA' },
      { value: 5, target: 'id_socio_otro', label: 'NOMBRE PRODUCTOR' },
      { value: 6, target: 'nombre_inspector', label: 'NOMBRE INSPECTOR' },
      { value: 7, target: 'uuid_odk', label: 'UUID' },
      { value: 7, target: 'fecha', label: 'FECHA DE LEVANTAMIENTO' },
    ],
    []
  );

  return (
    <div>
      {/*
         // TODO: crear lógica de permiso
        {auth.hasPermission('VINCULAR_SIC') && (
      */}
      <>
        <CardContainer height={'100%'}>
          <div className="row"></div>
          <div className="row">
            <h2 className="col-xl-12 font-lato-normal-400">PASO 1: Elige Formularios por Vincular</h2>
          </div>
          <div>
            <div className="row">
              <div className="col-10">
                <SearchColumn
                  data={originalData}
                  columns={searchColumns}
                  setDataFilter={evt => {
                    setDataForms(evt);
                  }}
                />
              </div>
              <div className="col-xl-12">
                <SicLinkTable
                  title="Formularios"
                  blocking={blockingLists}
                  data={dataForms}
                  toggleIds={toggleIds}
                  setter={setSelectedIds}
                  linkable
                  keyForm={keyForm}
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-4">
                {/*
									// TODO: crear lógica de permiso
									 {auth.hasPermission('VINCULAR_SIC') && (
									 */}
                <button
                  onClick={navigateToNextStep}
                  type="button"
                  className="btn btn-outline-secondary"
                  style={{ borderRadius: '20px 20px 20px 20px' }}
                  disabled={selectedIds.length === 0}>
                  <i className="fa fa-arrow-right"></i> Siguiente paso
                </button>
              </div>
            </div>
          </div>
        </CardContainer>
      </>
    </div>
  );
};

export default VincularSicList;
