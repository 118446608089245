import React from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

const BackButton = ({ label, goBackDefault = '/dashboard', touched = false }) => {
  const history = useHistory();

  const goBackOrDefault = () => {
    const doesAnyHistoryEntryExist = history.location.key;

    if (touched) {
      Swal.fire({
        text: 'Los cambios actuales se podrían perder. ¿Quieres volver a la página anterior?',
        showDenyButton: true,
        confirmButtonText: 'Seguir editando',
        denyButtonText: 'Volver',
      }).then(result => {
        if (result.isConfirmed) {
          // do nothing - stay on page
        } else {
          if (doesAnyHistoryEntryExist) {
            history.goBack();
          } else {
            history.push(goBackDefault);
          }
        }
      });
    } else {
      if (doesAnyHistoryEntryExist) {
        history.goBack();
      } else {
        history.push(goBackDefault);
      }
    }
  };

  return (
    <button onClick={goBackOrDefault} type="button" className="btn btn-outline-secondary btn-sirio" style={{ borderRadius: '20px' }}>
      <i className="fa fa-arrow-left mr-2"></i> {label ? label : 'Volver'}
    </button>
  );
};

export default BackButton;
