import React, { useState, useEffect, useCallback } from 'react';
import Api from '../../../api/global.service';
import BlockUi from 'react-block-ui';

const commonInputStyles = {
  borderRadius: '0px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal', 
  fontWeight: 400,
  fontSize: '14px',
  padding: '8px',
  borderColor: '#DADADA'
};

const emptyFunction = () => { };

const FormSelectApi = ({
  url,
  id,
  label,
  name,
  error,
  keyArray,
  onChange,
  register,
  labelArray,
  filter = false,
  column = false,
  value = false,
  placeholder = false,
  onFinish = emptyFunction,
}) => {
  const [items, setItems] = useState([]);
  const [blocking, setBlocking] = useState(true);

  const fetchData = useCallback(async () => {
    try {
      const response = filter 
        ? await Api.search(url, {
            filter: [{ column, operator: '=', value }],
          })
        : await Api.getAll(url);
        
      setBlocking(false);
      setItems(response.data);

      if (response.data.length === 1) {
        const singleItemValue = response.data[0][keyArray];
        onChange({ target: { value: singleItemValue } });
        onFinish(response.data);
      } else {
        onFinish(response.data);
      }
    } catch (error) {
      setBlocking(false);
      setItems([]);
      onChange({ target: { value: null } });
      onFinish([]);
    }
  }, [url, value]);

  useEffect(() => {
    fetchData();
  }, [url, value]);

  const renderSelect = useCallback(() => (
    <select
      {...register(id)}
      id={id}
      name={name}
      className={`form-control ${error ? '_is-invalid' : ''}`}
      onChange={onChange}
      style={commonInputStyles}
    >
      <option value={0}>{placeholder || '--- SELECCIONE ---'}</option>
      {items.length > 1 && items.map((item, index) => (
        <option key={index} value={item[keyArray]}>
          {item[labelArray]}
        </option>
      ))}
    </select>
  ), [error, onChange, items, keyArray]);

  const renderInputGroup = (children) => {
    const isDisabled = items?.length === 1 || !items?.length;
    return (
      <BlockUi tag="div" blocking={blocking} hidden={items.length === 1}>
        <div className="input-group" style={{...(isDisabled && { backgroundColor: '#e9ecef' })}}>
          {items.length > 1 ? (
            <label style={{ marginLeft: '19px'}} htmlFor={id}>{label}</label>
          ) : (<span style={{ minWidth: '19px', backgroundColor: "white"}}/>)}
          {children}
        </div>
      </BlockUi>
    );
  }

  const renderError = () => error && (
    <div className="invalid-feedback">{error.message}</div>
  );

  if (items.length === 1) {
    return (
      <>
        {renderInputGroup(
          <>
            <input
              type="text"
              className="form-control"
              value={items[0][labelArray]}
              style={commonInputStyles}
              {...register(id)}
              onChange={onChange}
            />
          </>
        )}
        {renderError()}
      </>
    );
  }

  if (!items?.length) {
    return renderInputGroup(renderSelect());
  }

  return (
    <>
      {renderInputGroup(renderSelect())}
      {renderError()}
    </>
  );
};

export default FormSelectApi;
