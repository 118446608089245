import React, { useState } from 'react';
import BlockUi from 'react-block-ui';
import './eudr-risk.css';
import EudrForm from '../../nuup/componentes/Forms/EudrForm';
import LinkDownload from '../../components/link/link-download.jsx';
import env from '../../environment';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

const EudrRisk = ({ uuid, eudrRisk, loadingEudrData }) => {
  const [formActive, setFormActive] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  if (loadingEudrData)
    return (
      <BlockUi tag="div" blocking={true} className="eudr-form-container">
        <div style={{ width: '100%', height: '100%' }}></div>
      </BlockUi>
    );

  if (!formActive) {
    if (!eudrRisk || Object.keys(eudrRisk).length === 0) {
      return (
        <>
          <button onClick={() => setFormActive(true)} className="btn btn-outline-secondary no-print" type="button">
            <i className="fa fa-pen" aria-hidden="true"></i> Cambiar nivel de riesgo
          </button>
        </>
      );
    } else {
      const imagenes = eudrRisk.imagenes ? eudrRisk.imagenes.split(';') : [];
      let documentos = eudrRisk.documentos ? eudrRisk.documentos.split(';') : [];
      documentos = documentos.map(doc => doc.split('/').pop());
      return (
        <div className="risk-info-container">
          <div className="risk-info-container-header">
            <label className="risk-date">Editado el {eudrRisk?.created_at?.split('T')[0]}</label>
            <label>Riesgo</label>
            <label
              style={{
                backgroundColor: eudrRisk?.cat_riesgo_eudr?.color ?? '#E4E3DB',
                padding: '4px 24px',
                borderRadius: 4,
                WebkitPrintColorAdjust: 'exact',
                printColorAdjust: 'exact',
              }}>
              {eudrRisk?.cat_riesgo_eudr?.nombre}
            </label>
          </div>
          <div>
            <label style={{ fontWeight: '600' }}>Justificación</label>
            <p style={{ whiteSpace: 'pre-line' }}>{eudrRisk.justificacion}</p>
          </div>
          {imagenes.length > 0 && (
            <div style={{ marginTop: '20px', width: '100%' }}>
              <div className="scrollable-gallery">
                {imagenes.map((imagePath, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        position: 'relative',
                        height: '100%',
                        cursor: 'pointer',
                        maxHeight: 250,
                        maxWidth: 250,
                      }}
                      onClick={() => setSelectedImage(imagePath)}>
                      <img
                        src={`${env.assetURL}/storage/${imagePath}`}
                        alt={`Evidencia ${index + 1}`}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          borderRadius: '8px',
                          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {documentos.length > 0 && (
            <div className="no-print" style={{ marginTop: '20px', width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px 0' }}>
                {documentos.map((filename, index) => {
                  const extension = filename.split('.').pop();
                  let icon = env.images.icon.pdf;
                  if (extension === 'doc' || extension === 'docx') icon = env.images.icon.doc;
                  if (extension === 'xls' || extension === 'xlsx') icon = env.images.icon.xls;
                  return (
                    <div key={index}>
                      <LinkDownload url={`parcela_eudr_risk/descargar/${uuid}/documentos/${filename}`} label={filename} icon={icon} />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div></div>
          <button onClick={() => setFormActive(true)} className="btn btn-outline-secondary no-print" type="button">
            <i className="fa fa-pen" aria-hidden="true"></i> Cambiar información
          </button>

          <Modal isOpen={!!selectedImage} toggle={() => setSelectedImage(null)} centered={true} size={'lg'}>
            <ModalBody>
              <img src={`${env.assetURL}/storage/${selectedImage}`} style={{ width: '100%' }} alt="Evidencia" />
            </ModalBody>
            <ModalFooter>
              <button type="button" className="btn btn-white" onClick={() => setSelectedImage(null)}>
                Cerrar
              </button>
            </ModalFooter>
          </Modal>
        </div>
      );
    }
  }

  return <EudrForm uuid={uuid} eudrRisk={eudrRisk} setFormActive={setFormActive} />;
};

export default EudrRisk;
