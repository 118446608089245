import React from 'react';

const TotalItemChart = ({ title, subtitle, backgroundColor, valueKey = 'value', fullTitle = false }) => {
  const bulletStyles = {
    marginLeft: '20px',
    width: '18px',
    height: '18px',
    backgroundColor: backgroundColor,
    borderRadius: '3px',
    border: '1px solid #9a9a9a',
  };

  return (
    <>
      <div style={bulletStyles}></div>
      <div>
        <h2 className="font-lato-normal-600">
          {fullTitle ? title : title[valueKey].toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </h2>
        <h6 className="font-lato-normal-400">{subtitle}</h6>
      </div>
    </>
  );
};

export default TotalItemChart;
