import React from 'react';

const LabelRiskEudr = ({ eudrRisk }) => (
  <div
    style={{
      backgroundColor: eudrRisk?.cat_riesgo_eudr?.color ?? '#E4E3DB',
      padding: '10px 18px 10px 18px',
      borderRadius: 4,
      WebkitPrintColorAdjust: 'exact',
      printColorAdjust: 'exact',
      colorAdjust: 'exact',
    }}>
    <span>{eudrRisk?.cat_riesgo_eudr?.nombre ?? `--`}</span>
  </div>
);

export default LabelRiskEudr;
