import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import CardContainer from '../../components/card/card-container.jsx';
import Api from '../../api/global.service.js';
import './cuentas-form.css';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormInput from '../../nuup/componentes/Forms/FormInput.jsx';
import FormSelectApi from '../../nuup/componentes/Forms/FormSelectApi.jsx';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

const ProductoresForm = () => {
  const history = useHistory();
  const redirect = history.location.state?.redirect;
  const [addParcelas, setAddParcelas] = useState(true);
  const [blocking, setBlocking] = useState(true);
  const [idMunicipio, setIdMunicipio] = useState(0);
  const [perteneceCooperativa, setPerteneceCooperativa] = useState(1);
  const [idLocalidad, setIdLocalidad] = useState(0);
  const [idCentroAcopio, setIdCentroAcopio] = useState(0);
  const [idRegion, setIdRegion] = useState(0);

  // const customStyles = {
  //   control: base => ({
  //     ...base,
  //     flexDirection: 'row-reverse',
  //   }),
  //   clearIndicator: base => ({
  //     ...base,
  //     position: 'absolute',
  //     right: 0,
  //   }),
  //   placeholder: defaultStyles => {
  //     return {
  //       ...defaultStyles,
  //       color: '#666',
  //       fontSize: 14,
  //     };
  //   },
  // };

  const [formData] = useState({
    nombres: '',
    apellido_paterno: '',
    apellido_materno: '',
    ine: '',
    curp: '',
    rfc: '',
    no_celular: '',
    id_cuenta_region: 0,
    id_municipio: 0,
    id_localidad: 0,
    id_centro_acopio: 0,
    pertenece_cooperativa: 1,
  });

  const schema = yup.object().shape({
    nombres: yup.string().required('Información obligatoria'),
    apellido_paterno: yup.string().required('Información obligatoria'),
    apellido_materno: yup.string().required('Información obligatoria'),
    ine: yup.string().nullable(),
    curp: yup.string().nullable(),
    rfc: yup.string().nullable(),
    no_celular: yup.string().nullable(),
    id_cuenta_region: yup
      .number()
      .min(1, 'Información obligatoria')
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value
      )
      .nullable(),
    id_municipio: yup
      .number()
      .min(1, 'Información obligatoria')
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value
      )
      .nullable(),
    id_localidad: yup
      .number()
      .min(1, 'Información obligatoria')
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value
      )
      .nullable(),
    id_centro_acopio: yup
      .number()
      .min(1, 'Información obligatoria')
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value
      )
      .nullable(),
    pertenece_cooperativa: yup
      .number()
      .min(0)
      .required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: async (data, context, options) => {
      data.id_cuenta_region = idRegion;
      data.id_municipio = idMunicipio;
      data.id_localidad = idLocalidad;
      data.id_centro_acopio = idCentroAcopio;
      return yupResolver(schema)(data, context, options);
    },
    mode: 'onBlur',
    defaultValues: formData,
  });

  const onSubmit = (values) => {
    Api.create('sic/cuentas', values)
      .then((response) => {
        let data = response.data.data;
        setBlocking(false);
        Swal.fire({
          icon: 'success',
          title: '',
          text: `Se registró con éxito al productor -> ${data[0].nombres}`,
          allowOutsideClick: false,
        }).then(res => {
          if (res.isConfirmed) {
            if (addParcelas) {
              history.push({
                pathname: `/admin/parcelas/create/${data[0].uuid}`,
                state: {
                  redirect: `/admin/acopio/entregas_por_productor/form/${data[0].id}/${data[0].uuid}`,
                },
              });
            } else {
              if (redirect) {
                history.push(redirect);
              } else {
                history.push('/admin/cuentas/list');
              }
            }
          }
        });
      })
      .catch((e) => {
        setBlocking(false);
        Swal.fire({
          icon: 'error',
          title: '',
          text: e,
        });
      });
  };

  return (
    <div>
      <CardContainer height={'100%'}>
        <div className="row">
          <div className="col-xl-10 col-lg-12">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-xl-4 col-lg-12">
                  <p className="titulo-input">
                    {'Registro de nuevo productor'}
                  </p>
                </div>
              </div>

              <div className="row pt-3 pl-2">
                <p className="titulo-input">{'Datos personales'}</p>
              </div>

              <div className="row">
                <div className="col-xl-4">
                  <FormInput
                    id="nombres"
                    type="text"
                    name="nombres"
                    label=""
                    placeholder="Nombres"
                    error={errors.nombres}
                    register={register}
                  />
                </div>
                <div className="col-xl-4">
                  <FormInput
                    id="apellido_paterno"
                    type="text"
                    name="apellido_paterno"
                    label=""
                    placeholder="Primer apellido"
                    error={errors.apellido_paterno}
                    register={register}
                  />
                </div>
                <div className="col-xl-4">
                  <FormInput
                    id="apellido_materno"
                    type="text"
                    name="apellido_materno"
                    label=""
                    placeholder="Segundo apellido"
                    error={errors.apellido_materno}
                    register={register}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-xl-4">
                  <FormInput
                    id="ine"
                    type="text"
                    name="ine"
                    label=""
                    placeholder="INE (opcional)"
                    error={errors.ine}
                    register={register}
                  />
                </div>
                <div className="col-xl-4">
                  <FormInput
                    id="curp"
                    type="text"
                    name="curp"
                    label=""
                    placeholder="CURP (opcional)"
                    error={errors.curp}
                    register={register}
                  />
                </div>
                <div className="col-xl-4">
                  <FormInput
                    id="rfc"
                    type="text"
                    name="rfc"
                    label=""
                    placeholder="RFC (opcional)"
                    error={errors.rfc}
                    register={register}
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-xl-4">
                  <FormInput
                    id="no_celular"
                    type="text"
                    name="no_celular"
                    label=""
                    placeholder="Nº celular (opcional)"
                    error={errors.no_celular}
                    register={register}
                  />
                </div>
              </div>

              <div className="row pt-3 pl-2">
                <p className="titulo-input">{'Ubicación'}</p>
              </div>

              <div className="row">
                <div className="col-xl-3 col-lg-6">
                  <FormSelectApi
                    id="id_cuenta_region"
                    name="id_cuenta_region"
                    label=""
                    url={'sic/regiones'}
                    value={idRegion}
                    keyArray="id"
                    labelArray="nombre"
                    placeholder="Región"
                    error={errors.id_cuenta_region}
                    register={register}
                    onChange={e => setIdRegion(Number(e.target.value))}
                  />
                </div>
                <div className="col-xl-3 col-lg-6">
                  <FormSelectApi
                    id="id_municipio"
                    name="id_municipio"
                    label=""
                    url={'cooperativas_municipios'}
                    keyArray="id"
                    labelArray="nombre"
                    placeholder="Municipio"
                    error={errors.id_municipio}
                    register={register}
                    onChange={(e) => {
                      setIdMunicipio(Number(e.target.value));
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-6">
                  <FormSelectApi
                    id="id_localidad"
                    name="id_localidad"
                    label=""
                    url={'localidades_municipio_all/' + idMunicipio}
                    keyArray="id"
                    value={idLocalidad}
                    labelArray="nombre"
                    placeholder="Localidad"
                    error={errors.id_localidad}
                    register={register}
                    onChange={e => setIdLocalidad(Number(e.target.value))}
                  />
                </div>
                <div className="col-xl-3 col-lg-6">
                  <FormSelectApi
                    id="id_centro_acopio"
                    name="id_centro_acopio"
                    label=""
                    url={'sic/centros_de_acopio'}
                    value={idCentroAcopio}
                    keyArray="id"
                    labelArray="nombre"
                    placeholder="Centro de acopio"
                    error={errors.id_centro_acopio}
                    register={register}
                    onChange={e => setIdCentroAcopio(Number(e.target.value))}
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-xl-6 col-lg-12">
                  <p className="titulo-pregunta">
                    {'¿El productor pertenece a la cooperativa?'}
                  </p>
                  <input
                    type="radio"
                    name="pertenece_cooperativa"
                    value={1}
                    checked={perteneceCooperativa == 1}
                    onChange={() => {
                      setValue('pertenece_cooperativa', 1);
                      setPerteneceCooperativa(1);
                    }}
                  />{' '}
                  Si
                  <br />
                  <input
                    type="radio"
                    name="pertenece_cooperativa"
                    value={0}
                    checked={perteneceCooperativa == 0}
                    onChange={() => {
                      setValue('pertenece_cooperativa', 0);
                      setPerteneceCooperativa(0);
                    }}
                  />{' '}
                  No
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-xl-6 col-lg-12">
                  <p className="titulo-pregunta">
                    {'¿Quieres agregar una o más parcelas del productor?'}
                  </p>
                  <input
                    type="radio"
                    name="addParcelas"
                    value="si"
                    checked={addParcelas == true}
                    disabled={false}
                    onChange={() => {
                      setAddParcelas(true);
                    }}
                  />{' '}
                  Si
                  <br />
                  <input
                    type="radio"
                    name="addParcelas"
                    value="no"
                    checked={addParcelas == false}
                    disabled={false}
                    onChange={() => {
                      setAddParcelas(false);
                    }}
                  />{' '}
                  No
                </div>
              </div>

              <div className="row">
                <div className="col-xl-6">
                  <button className="btn btn-sirio mt-3" type="submit">
                    Guardar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </CardContainer>
    </div>
  );
};

export default ProductoresForm;
