import React, { useState, useEffect } from 'react';
import NoPagination from '../pagination/noPagination';
import Api from '../../api/global.service';
import { Link } from 'react-router-dom';
import EditLink from '../link/edit-link';
import LabelNumber from '../label/label-number';
import updateLocalData from '../../utils/updateLocalData';
import updateLocalDataAndParent from '../../utils/updateLocalDataAndParent';
import parsePoint from '../../utils/parsePoint';
import { useAuth } from '../content/auth';
import env from '../../environment';

const ModalParcelas = ({
  id_cuenta,
  nombreProductor,
  id_ciclo,
  show,
  handleClose,
  onClickEdit,
  onClickEditNumber,
  updateParentData,
  updateParentDataOriginal,
  parentRow,
}) => {
  const [data, setData] = useState([]);
  const [blocking, setBlocking] = useState(true);
  const [editing, setEditing] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    if (id_cuenta && id_ciclo) {
      setBlocking(true);
      Api.get(`tx_parcelas_productor_padron/${id_ciclo}`, id_cuenta)
        .then(response => {
          setData(response.data);
          setBlocking(false);
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log('error:', e);
          setBlocking(false);
        });
    }
    setEditing(false);
  }, [id_cuenta, id_ciclo]);

  const columns = React.useMemo(() => [
    {
      Header: 'PARCELAS CULTIVO ORGÁNICO',
      columns: [
        {
          Header: 'Ciclo Actual',
          columns: [
            {
              Header: 'NOMBRE DE PARCELA',
              accessor: 'nombre',
              sortable: true,
              minWidth: 50,
              Cell: ({ row, value }) => {
                const editData = {
                  ...row.original,
                  type: 'text',
                  accessor: 'parcela_nombre',
                  columnName: 'NOMBRE DE PARCELA',
                  nombreProductor: nombreProductor,
                  oldValue: value,
                  nombreParcela: value,
                  id_cuenta: id_cuenta,
                  id_sic_informacion_general: row.original.id_sic_informacion_general,
                  id_parcela: row.original.id,
                  onClose: setEditing,
                  setData: newValue => updateLocalData({ nombre: newValue }, setData, row.index),
                };
                if (row.original.uuid !== null) {
                  const updatedClass = Array.isArray(row.original.updated) && row.original.updated.includes('nombre') ? 'updated' : '';
                  return (
                    <div
                      className={updatedClass}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}>
                      <Link to={{ pathname: `/admin/parcelas/perfil/${row.original.uuid}` }} className="m-r-2" style={{ color: '#789D16' }}>
                        <span>{value}</span>
                      </Link>
                      <div className="edit_hover_class" style={{ minWidth: '45px', paddingLeft: '15px' }}>
                        <EditLink
                          onClickEdit={e => {
                            onClickEdit(e, editData);
                            setEditing(true);
                          }}
                        />
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <EditLink onClickEdit={e => onClickEdit(e, editData)} column="nombre" updated={row.original.updated}>
                      <p style={{ margin: 0 }}>{value}</p>
                    </EditLink>
                  );
                }
              },
            },
            {
              Header: 'SUPERFICIE DE LA PARCELA (HAS.)',
              accessor: 'tx_superficie',
              sortable: true,
              minWidth: 50,
              Cell: ({ row, value }) => {
                const editData = {
                  type: 'number',
                  accessor: 'tx_superficie',
                  oldValue: value,
                  columnName: 'SUPERFICIE DE LA PARCELA (HAS.)',
                  nombreProductor: nombreProductor,
                  nombreParcela: row.original.nombre,
                  id_cuenta: id_cuenta,
                  id_ciclo: id_ciclo,
                  id_sic_informacion_general: row.original.id_sic_informacion_general,
                  id_parcela: row.original.id,
                  onClose: setEditing,
                  setData: newValue =>
                    updateLocalDataAndParent(
                      newValue,
                      'tx_superficie',
                      setData,
                      value,
                      row.index,
                      updateParentData,
                      'cuenta_superficie_total',
                      parentRow,
                      parseFloat,
                      updateParentDataOriginal,
                      id_cuenta
                    ),
                };
                return (
                  <EditLink
                    onClickEdit={e => {
                      onClickEditNumber(e, editData);
                      setEditing(true);
                    }}
                    column="tx_superficie"
                    updated={row.original.updated}>
                    <LabelNumber value={value} digits={2}></LabelNumber>
                  </EditLink>
                );
              },
            },
            {
              Header: '# DE PLANTAS',
              accessor: 'tx_no_plantas',
              sortable: true,
              minWidth: 50,
              Cell: ({ row, value }) => {
                const editData = {
                  type: 'number',
                  accessor: 'tx_no_plantas',
                  oldValue: value,
                  columnName: '# DE PLANTAS',
                  nombreProductor: nombreProductor,
                  nombreParcela: row.original.nombre,
                  id_cuenta: id_cuenta,
                  id_ciclo: id_ciclo,
                  id_sic_informacion_general: row.original.id_sic_informacion_general,
                  id_parcela: row.original.id,
                  isIngeger: true,
                  onClose: setEditing,
                  setData: newValue =>
                    updateLocalDataAndParent(
                      newValue,
                      'tx_no_plantas',
                      setData,
                      value,
                      row.index,
                      updateParentData,
                      'tx_no_plantas_totales',
                      parentRow,
                      parseInt,
                      updateParentDataOriginal,
                      id_cuenta
                    ),
                };
                return (
                  <EditLink
                    onClickEdit={e => {
                      onClickEditNumber(e, editData);
                      setEditing(true);
                    }}
                    column="tx_no_plantas"
                    updated={row.original.updated}>
                    <LabelNumber value={value}></LabelNumber>
                  </EditLink>
                );
              },
            },
            {
              Header: 'ESTIMACIÓN DE COSECHA KGs',
              accessor: 'tx_estimacion_cosecha_inspector',
              sortable: true,
              minWidth: 50,
              show: auth.user.id_cooperativa != 3,
              Cell: ({ row, value }) => {
                const editData = {
                  type: 'number',
                  accessor: 'tx_estimacion_cosecha_inspector',
                  oldValue: value,
                  columnName: 'ESTIMACIÓN DE COSECHA KGs',
                  nombreProductor: nombreProductor,
                  nombreParcela: row.original.nombre,
                  id_cuenta: id_cuenta,
                  id_sic_informacion_general: row.original.id_sic_informacion_general,
                  id_parcela: row.original.id,
                  onClose: setEditing,
                  setData: newValue =>
                    updateLocalDataAndParent(
                      newValue,
                      'tx_estimacion_cosecha_inspector',
                      setData,
                      value,
                      row.index,
                      updateParentData,
                      'tx_estimacion_cosecha_inspector_total',
                      parentRow,
                      parseFloat,
                      updateParentDataOriginal,
                      id_cuenta
                    ),
                };
                return (
                  <EditLink
                    onClickEdit={e => {
                      onClickEditNumber(e, editData);
                      setEditing(true);
                    }}
                    column="tx_estimacion_cosecha_inspector"
                    updated={row.original.updated}>
                    <LabelNumber value={value} digits={2}></LabelNumber>
                  </EditLink>
                );
              },
            },
            {
              Header: 'ESTIMACIÓN DE ENTREGA DE COSECHA (PRODUCTOR) KGs',
              accessor: 'tx_estimacion_cosecha_productor',
              sortable: true,
              minWidth: 50,
              Cell: ({ row, value }) => {
                const editData = {
                  type: 'number',
                  accessor: 'tx_estimacion_cosecha_productor',
                  oldValue: value,
                  columnName: 'ESTIMACIÓN DE ENTREGA DE COSECHA (PRODUCTOR) KGs',
                  nombreProductor: nombreProductor,
                  nombreParcela: row.original.nombre,
                  id_cuenta: id_cuenta,
                  id_sic_informacion_general: row.original.id_sic_informacion_general,
                  id_parcela: row.original.id,
                  onClose: setEditing,
                  setData: newValue =>
                    updateLocalDataAndParent(
                      newValue,
                      'tx_estimacion_cosecha_productor',
                      setData,
                      value,
                      row.index,
                      updateParentData,
                      'tx_estimacion_cosecha_productor_total',
                      parentRow,
                      parseFloat,
                      updateParentDataOriginal,
                      id_cuenta
                    ),
                };
                return (
                  <EditLink
                    onClickEdit={e => {
                      onClickEditNumber(e, editData);
                      setEditing(true);
                    }}
                    column="tx_estimacion_cosecha_productor"
                    updated={row.original.updated}>
                    <LabelNumber value={value} digits={2}></LabelNumber>
                  </EditLink>
                );
              },
            },
            {
              Header: 'PARCELA DIGITALIZADA',
              accessor: 'croquis_digitalizado',
              sortable: true,
              minWidth: 50,
            },
            {
              Header: 'CENTROIDE',
              accessor: 'centroide',
              sortable: true,
              minWidth: 50,
              Cell: ({ row }) => {
                return (
                  <p>
                    {/* TODO: Lat/Long are inverted in backend. Fix when sql fix and all logic is updated */}
                    Lat:{parsePoint(row.original.centroide_lng, 4)} / Lon: {parsePoint(row.original.centroide_lat, 4)}
                  </p>
                );
              },
            },
            {
              Header: 'CULTIVO A CERTIFICAR',
              accessor: 'tx_cultivo_certificar',
              sortable: true,
              minWidth: 50,
            },
          ],
        },
        {
          Header: 'Ciclo Pasado',
          columns: [
            {
              Header: 'Superficie de la parcela (Has. café)',
              accessor: 'sup_anterior',
              sortable: true,
              minWidth: 50,
              Cell: ({ row }) => {
                const parcelaPreviousCycle = row.original.parcelas_previous_ciclo?.[0];
                if (parcelaPreviousCycle && parcelaPreviousCycle.length === 1) {
                  return <LabelNumber value={parcelaPreviousCycle[0].inspeccion_superficie_cafe} digits={2} />;
                }
                return null;
              },
            },
            {
              Header: '# de plantas',
              accessor: 'parcelas[0].num_plantas_anterior',
              sortable: true,
              minWidth: 50,
              Cell: ({ row }) => {
                const parcelaPreviousCycle = row.original.parcelas_previous_ciclo?.[0];
                if (parcelaPreviousCycle && parcelaPreviousCycle.length === 1) {
                  return <LabelNumber value={parcelaPreviousCycle[0].tx_no_plantas} digits={2} />;
                }
                return null;
              },
            },
            {
              Header: 'Estimación de cosecha KGs',
              accessor: 'parcelas[0].estimacion_anterior',
              sortable: true,
              minWidth: 50,
              Cell: ({ row }) => {
                const parcelaPreviousCycle = row.original.parcelas_previous_ciclo?.[0];
                if (parcelaPreviousCycle && parcelaPreviousCycle.length === 1) {
                  return <LabelNumber value={parcelaPreviousCycle[0].tx_estimacion_cosecha_inspector} digits={2}></LabelNumber>;
                }
                return null;
              },
            },
            {
              Header: 'CULTIVO ANTERIOR',
              accessor: 'parcelas[0].tx_cultivo_anterior',
              sortable: true,
              minWidth: 50,
            },
          ],
        },
      ],
    },
  ]);

  const onClose = () => {
    setData([]);
    handleClose();
  };

  const getBackgroundColor = (row, column) => {
    if (row === 1) {
      if (column % 2 === 0) return '#5F5F59';
      return '#91918A';
    }
    return 'rgba(240, 224, 207, 1.0)';
  };

  const getColor = row => (row === 1 ? 'white' : 'rgb(95, 95, 89)');

  return (
    <div className="modal" style={{ display: show ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.2)' }}>
      <div
        style={{
          top: 50,
          left: 25,
          position: 'absolute',
          width: editing ? 'calc(96% - 415px)' : '96%',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '20px 20px 20px 20px',
          border: '1px solid #000',
        }}>
        <button
          onClick={onClose}
          type="button"
          className="btn btn-outline-secondary"
          style={{ borderRadius: '20px 20px 20px 20px', position: 'absolute', right: '10px', top: '10px' }}>
          <i className="fa fa-times"></i>
        </button>
        <h4>Parcelas de {nombreProductor}</h4>
        <NoPagination
          blocking={blocking}
          columns={columns}
          data={data}
          pagination={false}
          fnBackgroundColor={getBackgroundColor}
          fnColor={getColor}
        />
      </div>
    </div>
  );
};

export default ModalParcelas;
