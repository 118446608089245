import React from 'react';
import { Link } from 'react-router-dom';
import LabelCategory from '../../components/label/label-category.jsx';
import LabelNumber from '../../components/label/label-number.jsx';
import LabelColor from '../../components/label/label-color.jsx';
import env from '../../environment.js';
import NoPagination from '../../components/pagination/noPagination.jsx';
import SearchColumn from '../../components/search/search-column.jsx';
import { useCoopConfig, getIdCatCreditoPrecosecha } from '../../components/content/coop-config.jsx';
import transformWord from '../../utils/transformWord.js';
import getBackgroundColor from '../../utils/getBackgroundColor.js';
import getColor from '../../utils/getColor.js';
import LinkDownload from '../../components/link/link-download.jsx';
import insertIf from '../../utils/insertIf.js';

const AnticiposXProductorList = ({ update, ciclo, etiquetaAnticipos, data, setData, originalData, blocking }) => {
  const coopConfig = useCoopConfig();

  const columns = React.useMemo(
    () => [
      {
        Header: 'INFORMACIÓN DE PRODUCTOR',
        id: 'acopio_uuid',
        columns: [
          {
            Header: 'CLAVE',
            accessor: 'clave_tx',
            sortable: true,
            minWidth: 60,
          },
          {
            Header: 'CÓDIGO',
            accessor: 'codigo_tx',
            sortable: true,
            minWidth: 80,
            show: !!+coopConfig.config?.usa_codigo_tx,
          },
          {
            Header: 'NOMBRE PRODUCTOR',
            accessor: 'productor_nombre',
            sortable: true,
            minWidth: 50,
            Cell: ({ value, row }) => {
              if (row.original.productor_uuid != null) {
                return (
                  <Link
                    to={{ pathname: `/admin/cuentas/perfil/${row.original.productor_uuid}` }}
                    className="m-r-2"
                    style={{ color: '#789D16' }}>
                    <span>{value}</span>
                  </Link>
                );
              } else {
                return value;
              }
            },
          },
          {
            Header: 'LOCALIDAD',
            accessor: 'localidad',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'MUNICIPIO',
            accessor: 'municipio',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'REGIÓN',
            accessor: 'region',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'CENTRO DE ACOPIO',
            accessor: 'centro_acopio',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'CATEGORÍA',
            accessor: 'cuenta_categoria',
            sortable: true,
            minWidth: 90,
            Cell: ({ value, row }) => {
              if (value != null) {
                return <LabelCategory label={value} color={row.original.cuenta_categoria_color} />;
              } else {
                return value;
              }
            },
          },
        ],
      },
      {
        Header: `ESTATUS DE ${transformWord(etiquetaAnticipos, ['plural', 'uppercase'])}`,
        columns: [
          {
            Header: `# ${transformWord(etiquetaAnticipos, ['plural', 'uppercase'])}`,
            accessor: 'num_creditos',
            sortable: true,
            minWidth: 80,
          },
          {
            Header: 'RECIBOS',
            accessor: 'recibo_pdf',
            sortable: true,
            width: 80,
            Cell: ({ row }) => {
              if (Number(row.original.num_creditos) > 0) {
                return (
                  <LinkDownload
                    isZip
                    url={`recibos/cuentas/anticipos/${ciclo.id}/${row.original.productor_uuid}`}
                    label="Descargar"
                    icon={env.images.icon.pdf}
                  />
                );
              } else return 'no hay recibos disponibles por el momento';
            },
          },
          {
            Header: 'IMPORTE TOTAL',
            accessor: 'total',
            sortable: true,
            minWidth: 50,
            show: getIdCatCreditoPrecosecha() > 2,
            Cell: ({ value }) => <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2} />,
          },
          {
            Header: `CAPITAL DESEMBOLSADO`,
            accessor: 'total_anticipos',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2} />,
          },
          {
            Header: 'INTERÉS ACUMULADO',
            accessor: 'total_interes',
            sortable: true,
            minWidth: 50,
            show: getIdCatCreditoPrecosecha() > 2,
            Cell: ({ value }) => <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={value === 0 ? 0 : 2} />,
          },
          {
            Header: transformWord(etiquetaAnticipos, ['singular', 'uppercase']) + ' RECUPERADO',
            accessor: 'total_anticipos_recuperados',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>,
          },
          {
            Header: 'COMISIÓN',
            accessor: 'total_comisiones',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>,
          },
          {
            Header: 'SALDO',
            accessor: 'total_saldo',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => <LabelNumber value={value} digits={2} currencyLabel={coopConfig.currency()?.symbol} />,
          },
          {
            Header: 'ESTATUS',
            accessor: 'estatus',
            sortable: true,
            minWidth: 50,
            Cell: ({ row, value }) => <LabelColor label={value} color={row.original.estatus_color} />,
          },
        ],
      },
    ],
    [etiquetaAnticipos]
  );

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'clave_tx', label: 'CLAVE' },
      ...insertIf(!!+coopConfig.config?.usa_codigo_tx, { value: 2, target: 'codigo_tx', label: 'CÓDIGO' }),
      { value: 3, target: 'productor_nombre', label: 'NOMBRE PRODUCTOR' },
      { value: 4, target: 'localidad', label: 'LOCALIDAD' },
      { value: 5, target: 'region', label: 'REGIÓN' },
      { value: 6, target: 'centro_acopio', label: 'CENTRO DE ACOPIO' },
      { value: 7, target: 'cuenta_categoria', label: 'CATEGORÍA' },
      { value: 8, target: 'fecha_ejecucion', label: 'FECHA DESEMBOLSO' },
      ...insertIf(getIdCatCreditoPrecosecha() > 2, { value: 9, target: 'total', label: 'IMPORTE TOTAL' }),
      { value: 10, target: 'total_anticipos', label: 'CAPITAL DESEMBOLSADO' },
      ...insertIf(getIdCatCreditoPrecosecha() > 2, { value: 11, target: 'total_interes', label: 'INTERÉS ACUMULADO' }),
      {
        value: 13,
        target: 'total_anticipos_recuperados',
        label: transformWord(etiquetaAnticipos, ['singular', 'uppercase']) + ' RECUPERADO',
      },
      { value: 14, target: 'total_saldo', label: 'SALDO' },
      { value: 15, target: 'estatus', label: 'ESTATUS' },
      { value: 16, target: 'registro', label: 'REGISTRO' },
    ],
    [update, etiquetaAnticipos]
  );

  return (
    <>
      <div className="row">
        <div className="col-10">
          <SearchColumn data={originalData} columns={searchColumns} setDataFilter={evt => setData(evt)} />
        </div>
      </div>

      <NoPagination
        blocking={blocking}
        selected="id"
        columns={columns}
        data={data}
        fnBackgroundColor={getBackgroundColor}
        fnColor={getColor}></NoPagination>
    </>
  );
};

export default AnticiposXProductorList;
