import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import BlockUi from 'react-block-ui';
import Api from '../../api/global.service';

const EudrSicAccordion = ({ uuid, isPrinting }) => {
  const [blocking, setBlocking] = useState(true);
  const [sicData, setSicData] = useState({});
  const [acc1, setAcc1] = useState(false);
  const [acc2, setAcc2] = useState(false);
  const [acc3, setAcc3] = useState(false);
  const [acc4, setAcc4] = useState(false);
  const [acc5, setAcc5] = useState(false);

  useEffect(() => {
    if (uuid != null) {
      Api.get('parcela_eudr_sic_info', uuid)
        .then(response => {
          setSicData(response.data);
          setBlocking(false);
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log('error:', e);
          setBlocking(false);
        });
    }
  }, []);

  useEffect(() => {
    if (isPrinting) {
      setAcc1(true);
      setAcc2(true);
      setAcc3(true);
      setAcc4(true);
      setAcc5(true);
    }
  }, [isPrinting]);

  return (
    <>
      <h4>Información adicional:</h4>
      <p>Ésta información es la capturada en la ficha acerca de las prácticas en parcela en relación a deforestación y colindantes:</p>
      <BlockUi tag="div" message="" blocking={blocking} renderChildren={true} style={{ marginTop: '20px' }}>
        <Accordion
          expanded={acc1}
          onChange={() => setAcc1(!acc1)}
          elevation={0}
          sx={{
            bgcolor: 'transparent',
            '&:before': { display: 'none' },
            '& .MuiAccordionSummary-root': {
              minHeight: 'auto',
              padding: 0,
            },
            '& .MuiAccordionDetails-root': {
              padding: '8px 16px',
            },
            borderBottom: '1px solid #e0e0e0',
          }}>
          <AccordionSummary expandIcon={<i className="fa fa-plus" aria-hidden="true"></i>}>
            <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>Información general de la parcela</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {sicData.inspeccion_fecha && (
              <Typography sx={{ fontSize: '12px' }}>
                Fecha de inspección: <span style={{ fontWeight: 'bold' }}>{sicData.inspeccion_fecha}</span>
              </Typography>
            )}
            {sicData.tipo_parcela && (
              <Typography sx={{ fontSize: '12px' }}>
                La parcela inspeccionada es la parcela principal: <span style={{ fontWeight: 'bold' }}>{sicData.tipo_parcela}</span>
              </Typography>
            )}
            {sicData.tenencia_papeles_sino && (
              <Typography sx={{ fontSize: '12px' }}>
                Cuenta con papeles de la tenencia: <span style={{ fontWeight: 'bold' }}>{sicData.tenencia_papeles_sino}</span>
              </Typography>
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={acc2}
          onChange={() => setAcc2(!acc2)}
          elevation={0}
          disableGutters
          sx={{
            bgcolor: 'transparent',
            '&:before': { display: 'none' },
            '& .MuiAccordionSummary-root': {
              minHeight: 'auto',
              padding: 0,
            },
            '& .MuiAccordionDetails-root': {
              padding: '8px 16px',
            },
            borderBottom: '1px solid #e0e0e0',
          }}>
          <AccordionSummary expandIcon={<i className="fa fa-plus" aria-hidden="true"></i>}>
            <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>Historial de uso de suelo</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {sicData.inspeccion_suelo_tipo && (
              <Typography sx={{ fontSize: '12px' }}>
                Tipo de suelo: <span style={{ fontWeight: 'bold' }}>{sicData.inspeccion_suelo_tipo}</span>
              </Typography>
            )}
            {sicData.inspeccion_suelo_tipo_otro && (
              <Typography sx={{ fontSize: '12px' }}>
                Otros tipos de suelo: <span style={{ fontWeight: 'bold' }}>{sicData.inspeccion_suelo_tipo_otro}</span>
              </Typography>
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={acc3}
          onChange={() => setAcc3(!acc3)}
          elevation={0}
          disableGutters
          sx={{
            bgcolor: 'transparent',
            '&:before': { display: 'none' },
            '& .MuiAccordionSummary-root': {
              minHeight: 'auto',
              padding: 0,
            },
            '& .MuiAccordionDetails-root': {
              padding: '8px 16px',
            },
            borderBottom: '1px solid #e0e0e0',
          }}>
          <AccordionSummary expandIcon={<i className="fa fa-plus" aria-hidden="true"></i>}>
            <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>Prácticas laborales y sociales</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontSize: '12px' }}>Información sobre prácticas laborales y sociales...</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={acc4}
          onChange={() => setAcc4(!acc4)}
          elevation={0}
          disableGutters
          sx={{
            bgcolor: 'transparent',
            '&:before': { display: 'none' },
            '& .MuiAccordionSummary-root': {
              minHeight: 'auto',
              padding: 0,
            },
            '& .MuiAccordionDetails-root': {
              padding: '8px 16px',
            },
            borderBottom: '1px solid #e0e0e0',
          }}>
          <AccordionSummary expandIcon={<i className="fa fa-plus" aria-hidden="true"></i>}>
            <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>Conservación medioambiental</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontSize: '12px' }}>Información sobre conservación medioambiental...</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={acc5}
          onChange={() => setAcc5(!acc5)}
          elevation={0}
          disableGutters
          sx={{
            bgcolor: 'transparent',
            '&:before': { display: 'none' },
            '& .MuiAccordionSummary-root': {
              minHeight: 'auto',
              padding: 0,
            },
            '& .MuiAccordionDetails-root': {
              padding: '8px 16px',
            },
            borderBottom: '1px solid #e0e0e0',
          }}>
          <AccordionSummary expandIcon={<i className="fa fa-plus" aria-hidden="true"></i>}>
            <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>Colindantes y riesgos</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontSize: '12px' }}>Información sobre colindantes y riesgos...</Typography>
          </AccordionDetails>
        </Accordion>
      </BlockUi>
    </>
  );
};

export default EudrSicAccordion;
