import React, { useState, useEffect, createRef } from 'react';
import CardContainer from '../../../components/card/card-container.jsx';
import Api from '../../../api/global.service';
import { subscribe, unsubscribe } from '../../../components/events';
import './entregas-x-productor.css';
import Swal from 'sweetalert2';
import Moment from 'moment';
import { Link } from 'react-router-dom';

import ModalFirma from './modal-firma.jsx';
import { publish } from '../../../components/events.js';
import { useCoopConfig, getIdCatCreditoPrecosecha } from '../../../components/content/coop-config.jsx';
import formatMoneyNumber, { formatNumber } from '../../../utils/formatNumber.js';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min.js';

const catUnidadesEntregas = { 
1: "Bultos",
2: "Costales",
3: "Tambores",
4: "Sacos",
5: "Costalillos",
6: "Botes",
7: "A granel",
8: "Bolsa de plásticos",
9: "Costales Yute",
10:  "Costales Pergaminero",
11:  "Garrafas",
12:  "Cubetas",
13:  "Costalillos de plástico",
14:  "Yutes"
}

const EntregasXProductorFirma = ({ metodosPago, data, cuenta, importeBruto, pesoNeto, unidadesEntregas }) => {
  if (!data.precio_por_kg) importeBruto = null;
  const [blocking, setBlocking] = useState(false);
  const dialog = createRef();
  const [imageURL, setImageURL] = useState(null);
  const [metodoPago, setMetodoPago] = useState();
  const coopConfig = useCoopConfig();
  const {id_cuenta, uuid_cuenta, uuid_acopio} = useParams();

  const handleFirma = event => {
    setImageURL(event.detail);
  };

  useEffect(() => {
    let id_metodo_pago = metodosPago.filter(metodo => [Number(data.id_metodo_pago)].includes(metodo.value));
    if (data.firma_image) setImageURL(data.firma_image);
    if (id_metodo_pago.length === 1) {
      setMetodoPago(id_metodo_pago[0].label);
    }
    subscribe('Firma::onSave', e => handleFirma(e));
    return () => {
      unsubscribe('Firma::onSave');
    };
  }, []);

  const crear = formData => {
    Api.createMultiPart('admin/acopios/entregas', formData)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: '',
          text: `Se registró con éxito la entrega`,
        }).then(() => {
            setBlocking(false);
            window.location.href = `/admin/acopio/entregas_por_productor/list`;
        })
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log(e);
        Swal.fire({
          icon: 'error',
          title: '',
          text: e.response.data.message,
        }).then((res) => {
          if (res.isConfirmed) {
            setBlocking(false);
          }
        });
      });
  };

  const enviarDatos = () => {
    setBlocking(true);
    const formData = new FormData();
    for (var key in data) {
      let v = data[key];
      if (v === "null" || v === "undefined" || v === null || v === undefined) {
        continue;
      } else if (v instanceof Date) {
        v = Moment(v).format('yyyy-MM-DD');
      } else if (v instanceof Array) {
        v = JSON.stringify(v);
      }
      formData.append(key, v);
    }
    crear(formData);
  };

  const showForm = () => {
    publish('Entrega::onReturn');
  };

  return (
    <div>
      <CardContainer height={'100%'}>
        <ModalFirma ref={dialog} title="Generar firma" productor_nombre={cuenta?.productor_nombre} />
        <div className="row">
          <div className="col-xl-8 col-lg-12 d-flex align-items-center">
            <span className="resumen-entrega-titulo mr-5">{'Resumen de la entrega'}</span>{' '}
            <button 
              className="text-green align-baseline mt-2 volver-button"
              onClick={() => window.location.reload()}>
              <i className="fa fa-arrow-left"></i> Volver a la entrega
            </button>
            </div>
            </div>
            <div className="pt-3">
              <div className="row">
                <div className="col-xl-12 resumen-entrega-subtitulo">{'Datos de la pesada'}</div>
              </div>
              <div className="row">
                <div className="col-xl-8 col-lg-6 resumen-entrega-elemento">{'Peso neto'}</div>
                <div className="col-xl-4 col-lg-6 resumen-entrega-valor">{formatMoneyNumber(pesoNeto) + " " + coopConfig.acopioUnits()?.abreviacion}</div>
              </div>
              {data.pesadas.length > 0 && 
              data.pesadas.map((pesada, i)=>{
                return (
                  <div key={i}>
                  <div className="row" key={i}>
                    <div className="col-xl-12 resumen-entrega-subtitulo pt-2">{`Pesada ${i+1}`}: </div>
                  </div>
                    <div className="row">
                          <div className="col-xl-8 col-lg-6 resumen-entrega-elemento">No. {catUnidadesEntregas[pesada.id_cat_unidades_entregas]}</div>
                          {pesada.bultos.map((bulto,i)=>{
                          return (
                            <div key={i} className="col-xl-4 col-lg-6 resumen-entrega-valor">{bulto.no_bultos}
                            </div>)})}
                    </div>
                    <div className="row">
                      <div className="col-xl-8 col-lg-6 resumen-entrega-elemento">{'Peso bruto'}</div>
                      <div className="col-xl-4 col-lg-6 resumen-entrega-valor">{pesada.peso_bruto}</div>
                    </div>
                    <div className="row">
                      <div className="col-xl-8 col-lg-6 resumen-entrega-elemento">{'Peso tara'}</div>
                      <div className="col-xl-4 col-lg-6 resumen-entrega-valor">{pesada.peso_tara}</div>
                    </div>
                    {pesada.peso_descuento && pesada.peso_descuento > 0 ? (
                      <div className="row">
                        <div className="col-xl-8 col-lg-6 resumen-entrega-elemento">{'Peso descuento'}</div>
                        <div className="col-xl-4 col-lg-6 resumen-entrega-valor">{pesada.peso_descuento}</div>
                    </div>
                    ) : null}
                  </div>
                  )})}
              {data.lote && (
                <div className="row">
                  <div className="col-xl-8 col-lg-6 resumen-entrega-elemento">{'Lote'}</div>
                  <div className="col-xl-4 col-lg-6 resumen-entrega-valor">{data.lote}</div>
                </div>
              )}
            </div>

              {/* // muestreo */}
              {(data.aroma || data.color_cafe || data.rendimiento || data.humedad || data.mancha || data.tipo_maduracion || data.observaciones) && (
                <>
                <div className="row pt-3">
                  <div className="col-xl-12 resumen-entrega-subtitulo">{'Datos de muestreo'}</div>
                </div>
                {data.aroma && (
                  <div className="row">
                    <div className="col-xl-8 col-lg-6 resumen-entrega-elemento">{'Aroma'}</div>
                    <div className="col-xl-4 col-lg-6 resumen-entrega-valor">{data.aroma}</div>
                  </div>
                )}
                {data.color_cafe && (
                  <div className="row">
                  <div className="col-xl-8 col-lg-6 resumen-entrega-elemento">{'Color de café'}</div>
                  <div className="col-xl-4 col-lg-6 resumen-entrega-valor">{data.color_cafe}</div>
                  </div>
                )}
                {data.rendimiento && (
                  <div className="row">
                  <div className="col-xl-8 col-lg-6 resumen-entrega-elemento">{'Rendimiento'}</div>
                  <div className="col-xl-4 col-lg-6 resumen-entrega-valor">{formatNumber(data.rendimiento) + '%'}</div>
                  </div>
                )}
                {data.humedad && (
                  <div className="row">
                  <div className="col-xl-8 col-lg-6 resumen-entrega-elemento">{'Humedad'}</div>
                  <div className="col-xl-4 col-lg-6 resumen-entrega-valor">{formatNumber(data.humedad) + '%'}</div>
                  </div>
                )}
                {data.mancha && (
                  <div className="row">
                  <div className="col-xl-8 col-lg-6 resumen-entrega-elemento">{'Mancha'}</div>
                  <div className="col-xl-4 col-lg-6 resumen-entrega-valor">{formatNumber(data.mancha) + '%'}</div>
                  </div>
                )}
                {data.tipo_maduracion && (
                  <div className="row">
                  <div className="col-xl-8 col-lg-6 resumen-entrega-elemento">{'Tipo de maduración'}</div>
                    <div className="col-xl-4 col-lg-6 resumen-entrega-valor">{data.tipo_maduracion}</div>
                    </div>
                  )}
                {data.observaciones && (
                  <div className="row">
                    <div className="col-xl-8 col-lg-6 resumen-entrega-elemento">{'Observaciones'}</div>
                    <div className="col-xl-4 col-lg-6 resumen-entrega-valor">{data.observaciones}</div>
                    </div>
                  )}
                </>
              )}

              {/* // precio */}
              {data.precio_por_kg && data.precio_por_kg > 0 ? (
                <div className="pt-3">
              <div className="row">
                <div className="col-xl-12 resumen-entrega-subtitulo">{'Pago'}</div>
              </div>
              <div className="row">
                <div className="col-xl-8 col-lg-6 resumen-entrega-elemento">{'Precio'}</div>
                <div className="col-xl-4 col-lg-6 resumen-entrega-valor">{'$ ' + formatMoneyNumber(data.precio_por_kg)}</div>
              </div>

              <div className="row">
                <div className="col-xl-8 col-lg-6 resumen-entrega-elemento">{'Importe bruto'}</div>
                <div className="col-xl-4 col-lg-6 resumen-entrega-valor">{'$ ' + formatMoneyNumber(importeBruto)}</div>
              </div>

              {getIdCatCreditoPrecosecha() > 1 && (
                <>
                  <div className="row">
                    <div className="col-xl-8 col-lg-6 resumen-entrega-elemento">{'Descuentos deudas'}</div>
                    <div className="col-xl-4 col-lg-6 resumen-entrega-valor">
                      {'$ ' + formatMoneyNumber(data.recuperacion_credito || 0)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-8 col-lg-6 resumen-entrega-elemento">{'Monto a pagar'}</div>
                    <div className="col-xl-4 col-lg-6 resumen-entrega-valor">
                      {'$ ' + formatMoneyNumber(importeBruto - (data.recuperacion_credito || 0))}
                    </div>
                  </div>
                </>
              )}
              {data.monto_pagado && (
                  <div className="row">
                    <div className="col-xl-8 col-lg-6 resumen-entrega-elemento">{'Monto pagado'}</div>
                    <div className="col-xl-4 col-lg-6 resumen-entrega-valor">
                      {'$ ' + (isNaN(data.monto_pagado) ? 0 : formatMoneyNumber(data.monto_pagado))}
                    </div>
                  </div>
              )}

              <div className="row">
                <div className="col-xl-8 col-lg-6 resumen-entrega-elemento">{'Pago pendiente'}</div>
                <div className="col-xl-4 col-lg-6 resumen-entrega-valor">
                  {'$ ' + formatMoneyNumber(importeBruto - (data.recuperacion_credito || 0) - (data.monto_pagado || 0))}
                </div>
              </div>

              {metodoPago && (
                <div className="row">
                  <div className="col-xl-8 col-lg-6 resumen-entrega-elemento">{'Método de pago'}</div>
                  <div className="col-xl-4 col-lg-6 resumen-entrega-valor">{metodoPago || ''}</div>
                </div>
              )}
            </div>
              ) : null}

            {data.firma_image.length === 0 && (
              <>
                <div className="pt-3">
                  <div className="row">
                    <div className="col-xl-12 resumen-entrega-subtitulo pt-4">{'Firma de conformidad'}</div>
                    <div className="col-xl-12 resumen-entrega-firma">
                      <>
                        {imageURL ? (
                          <img
                            src={imageURL}
                            alt="Firma generada"
                            style={{
                              display: 'block',
                              margin: '0 auto',
                              width: '200px',
                              height: '125px',
                            }}
                          />
                        ) : null}
                      </>
                    </div>
                    <p className="resumen-entrega-firmante">{cuenta?.productor_nombre}</p>
                    <br />
                  </div>
                </div>
                <button
                  onClick={() => {
                    dialog.current.show();
                    setImageURL(null);
                  }}
                  type="button"
                  style={{ marginTop: 0 }}
                  className="btn btn-outline-secondary">
                  Generar firma
                </button>
              </>
            )}

        <div className="row">
          <div className="col-xl-12 resumen-entrega-subtitulo pt-4">
            <button
              disabled={blocking}
              onClick={() => {
                enviarDatos();
              }}
              type="button"
              style={{ marginTop: 0 }}
              className="btn btn-sirio">
              Guardar
            </button>
          </div>
        </div>
      </CardContainer>
    </div>
  );
};

export default EntregasXProductorFirma;
