import React, { useState, useEffect } from 'react';
import { useAuth } from '../../components/content/auth.jsx';
import CardContainer from '../../components/card/card-container.jsx';
import Api from '../../api/global.service';

import NoPagination from '../../components/pagination/noPagination.jsx';

const VersionesList = () => {
  const auth = useAuth();

  const [name] = useState('Versiones');
  const [table] = useState('versiones');

  const [data, setData] = useState([]);
  const [blocking, setBlocking] = useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: 'NOMBRE',
        accessor: 'nombre',
        sortable: true,
        Cell: cell => (
          // <span className={(cell.value == env.appVersion) ? 'badge rounded-0 badge-danger': 'badge rounded-0 badge-default'} style={{fontSize: '0.75rem'}}>{cell.value}</span>
          <span className="badge rounded-0 badge-default" style={{ fontSize: '0.75rem', padding: 8 }}>
            {cell.value}
          </span>
        ),
      },
      {
        Header: 'FECHA',
        accessor: 'fecha',
        sortable: true,
      },
      // {
      // 	Header: 'FECHA DE CREACIÓN',
      // 	accessor: 'created_at',
      // 	sortable: true
      // },
      // {
      // 	Header: 'FECHA DE ACTUALIZACIÓN',
      // 	accessor: 'updated_at',
      // 	sortable: true
      // }
    ],
    []
  );

  useEffect(() => {
    setBlocking(true);
    Api.search(table, {
      // filter: [
      // 	{column: "id", operator: ">", value: 2},
      // 	{column: "id", operator: "<", value: 5}
      // ],
      sort: [{ column: 'id', sort: 'desc' }],
    })
      .then(response => {
        setData(response.data);
        setBlocking(false);
      })
      .catch(e => {
        setBlocking(false);
      });
  }, []);

  return (
    <div>
      {auth.hasAccess('VERSIONES') && (
        <>
          {auth.hasPermission('VERSIONES_VER') && (
            <CardContainer height={'100%'}>
              <NoPagination blocking={blocking} columns={columns} data={data}></NoPagination>
            </CardContainer>
          )}
        </>
      )}
    </div>
  );
};

export default VersionesList;
