import chroma from 'chroma-js';

const colorLabel = (color = 'transparent') => ({
  backgroundColor: color,
  borderRadius: '4px',
  padding: '2px 8px',
  color: color ? (chroma.contrast(color, 'white') > 2 ? 'white' : 'black') : 'black',
});

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white', zIndex: 8 }),
  container: base => ({
    ...base,
    zIndex: 100,
  }),
  menu: styles => ({
    ...styles,
    zIndex: 9999,
    position: 'absolute',
  }),
  menuPortal: base => ({
    ...base,
    zIndex: 9999,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled ? undefined : isSelected ? data.color : isFocused ? color.alpha(0.4).css() : undefined,
      color: isDisabled ? '#ccc' : isSelected ? (chroma.contrast(color, 'white') > 2 ? 'white' : 'black') : 'black',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled ? (isSelected ? data.color : color.alpha(0.5).css()) : undefined,
      },
    };
  },
  input: styles => ({ ...styles }),
  placeholder: styles => ({ ...styles }),
  singleValue: (styles, { data }) => ({
    ...styles,
    ...colorLabel(data.color),
  }),
};

export default colourStyles;
