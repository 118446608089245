import React from 'react';

const LabelNumber = ({
  value,
  suffix = '',
  digits = 0,
  currency = false,
  position = 'text-right',
  currencyLabel = false,
  asString = false,
}) => {
  let isCurrency = currency;
  let val = '';
  if (value != null) {
    let v = parseFloat(value) || 0;

    // rounder to avoid -$0.00
    const rounder = 1 / 10 ** digits;
    v = v < rounder && v > -rounder ? 0 : v;

    val = isCurrency
      ? v.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: digits,
          maximumFractionDigits: digits,
        })
      : currencyLabel
      ? currencyLabel + v.toLocaleString('en-US', { minimumFractionDigits: digits, maximumFractionDigits: digits })
      : v.toLocaleString('en-US', { minimumFractionDigits: digits, maximumFractionDigits: digits });

    if (asString) return val + suffix;
    return (
      <p className={position} style={{ margin: 0 }}>
        {val}
        {suffix}
      </p>
    );
  }
  if (asString) return '--';
  return (
    <p className={position} style={{ margin: 0 }}>
      --
    </p>
  );
};

export default LabelNumber;
