import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import CardContainer from '../../components/card/card-container.jsx';
import LinkDownload from '../../components/link/link-download';
import LabelCategory from '../../components/label/label-category.jsx';
import LabelNumber from '../../components/label/label-number.jsx';
import Api from '../../api/global.service';
import { useAuth } from '../../components/content/auth.jsx';
import env from '../../environment.js';
import NoPagination from '../../components/pagination/noPagination.jsx';
import SelectCiclo from '../../components/select/select-ciclo.jsx';
import SearchColumn from '../../components/search/search-column.jsx';
import Swal from 'sweetalert2';
import ModalPadronAltasBajas from '../../components/modal/modal-padron-altas-bajas.jsx';
import ModalPadronAltasBajasCancelar from '../../components/modal/modal-padron-altas-bajas-cancelar.jsx';
import ModalPadron from '../../components/modal/modal-padron.jsx';
import EditLink from '../../components/link/edit-link.jsx';
import CheckboxList from '../../components/forms/checkbox-list.jsx';
import getColor from '../../utils/getColor.js';

const PadronAltasList = () => {
  const auth = useAuth();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [ciclo, setCiclo] = useState({});
  const [blocking, setBlocking] = useState(true);
  const [btnPendientes, setBtnPendientes] = useState(true);
  const [idsSeleccionados, setIdsSeleccionados] = useState([]);
  const [confirmAltas, setConfirmAltas] = useState(false);
  const [edit, setEdit] = useState(null);
  const dialog = React.createRef();
  const dialogEdit = React.createRef();
  const dialogDelete = React.createRef();

  const report = 'padron_altas';

  const handleCheck = event => {
    let prodSel = idsSeleccionados;
    if (event.target.checked === true) {
      setIdsSeleccionados([...prodSel, event.target.value]);
    } else {
      prodSel = prodSel.filter(item => item !== event.target.value);
      setIdsSeleccionados(prodSel);
    }
  };

  const onClickEdit = (event, data) => {
    event.preventDefault();
    setEdit(data);
  };

  const columns = [
    {
      Header: 'INFORMACIÓN DE PRODUCTOR',
      columns: [
        {
          Header: '-',
          accessor: false,
          sortable: false,
          width: 25,
          Cell: cell => {
            return (
              <>
                <div className="form-check">
                  {parseInt(cell.row.original.confirmado) === 0 && (
                    <CheckboxList
                      name="productores"
                      value={cell.row.original.uuid_cuentas_altas_solicitud}
                      handleCheck={handleCheck}
                      checked={idsSeleccionados.includes(cell.row.original.uuid_cuentas_altas_solicitud)}
                    />
                  )}
                  <label className="form-check-label" htmlFor="flexCheckChecked" style={{ marginTop: 5, paddingLeft: 5 }}>
                    {cell.value}
                  </label>
                </div>
              </>
            );
          },
        },
        {
          Header: 'CLAVE',
          accessor: 'productor_clave_tx',
          sortable: true,
          minWidth: 50,
          Cell: ({ row, value }) => {
            const editData = {
              type: 'text',
              accessor: 'productor_clave_tx',
              oldValue: value,
              columnName: 'CLAVE',
              nombreProductor: row.original.productor_nombre,
              id_cuenta: row.original.id,
            };
            return (
              <EditLink onClickEdit={e => onClickEdit(e, editData)}>
                <p style={{ margin: 0 }}>{value}</p>
              </EditLink>
            );
          },
        },
        {
          Header: 'LOCALIDAD',
          accessor: 'localidad',
          sortable: true,
          minWidth: 50,
        },
        {
          Header: 'REGIÓN',
          accessor: 'region',
          sortable: true,
          minWidth: 50,
        },
        {
          Header: 'MUNICIPIO',
          accessor: 'municipio',
          sortable: true,
          minWidth: 50,
        },
        {
          Header: 'CÓDIGO',
          accessor: 'productor_codigo_tx',
          sortable: true,
          minWidth: 50,
        },
        {
          Header: 'CURP',
          accessor: 'curp',
          sortable: true,
          minWidth: 50,
        },
        {
          Header: 'NOMBRE PRODUCTOR',
          accessor: 'productor_nombre',
          sortable: true,
          minWidth: 50,
          Cell: cell => (
            <Link
              to={{ pathname: `/admin/cuentas/perfil/${cell.row.original.productor_uuid}` }}
              className="m-r-2"
              style={{ color: '#789D16' }}>
              <span>{cell.value}</span>
            </Link>
          ),
        },
        {
          Header: 'CATEGORÍA',
          accessor: 'cuenta_categoria',
          sortable: true,
          minWidth: 90,
          Cell: cell => <LabelCategory label={cell.value} color={cell.row.original.cuenta_categoria_color}></LabelCategory>,
        },
      ],
    },
    {
      Header: 'INFORMACIÓN PROGRAMA ORGÁNICO',
      columns: [
        {
          Header: 'FECHA INGRESO ORGÁNICO',
          accessor: 'productor_fecha_ingreso',
          sortable: true,
          minWidth: 50,
        },
        {
          Header: 'FECHA INGRESO TRANSICIÓN',
          accessor: 'productor_fecha_transicion',
          sortable: true,
          minWidth: 50,
        },
        {
          Header: 'HAS. CAFÉ ORGÁNICO',
          accessor: 's3_inspeccion_superficie',
          sortable: true,
          minWidth: 50,
          Cell: cell => {
            return <LabelNumber value={cell.value} digits={2}></LabelNumber>;
          },
        },
        {
          Header: 'ESTIMACIÓN DE COSECHA',
          accessor: 's6_pergamino_actual',
          sortable: true,
          minWidth: 50,
          Cell: cell => {
            return <LabelNumber value={cell.value} digits={2}></LabelNumber>;
          },
        },
      ],
    },
  ];

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'productor_clave_tx', label: 'NO' },
      { value: 2, target: 'localidad', label: 'LOCALIDAD' },
      { value: 3, target: 'region', label: 'REGIÓN' },
      { value: 5, target: 'municipio', label: 'MUNICIPIO' },
      { value: 6, target: 'productor_codigo_tx', label: 'CÓDIGO' },
      { value: 7, target: 'productor_nombre', label: 'NOMBRE PRODUCTOR' },
      { value: 8, target: 'cuenta_categoria', label: 'CATEGORÍA' },
      { value: 9, target: 'productor_fecha_ingreso', label: 'FECHA INGRESO ORGÁNICO' },
      { value: 10, target: 'productor_fecha_transicion', label: 'FECHA INGRESO TRANSICIÓN' },
    ],
    []
  );

  const getData = () => {
    setBlocking(true);
    Api.search('admin/sic/padron_altas', {
      filter: [
        { column: 'id_ciclo', operator: '=', value: ciclo.id },
        { column: 'confirmado', operator: '=', value: btnPendientes ? 0 : 1 },
      ],
    })
      .then(response => {
        setData(response.data);
        setOriginalData(response.data);
        setBlocking(false);
      })
      .catch(e => {
        setBlocking(false);
      });
  };

  const setFilter = value => {
    setCiclo(value.target);
  };

  const setDataFilter = data => {
    setData(data);
  };

  const getBackgroundColor = (i, j) => {
    if (i == 1) {
      return 'rgb(247, 239, 231)';
    } else {
      switch (j) {
        // case 0: return env.colors.neutral_600; break;
        case 1:
          return '#91918A';
          break;
        // case 2: return '#C7AE34'; break;
        case 3:
          return '#91918A';
          break;
        default:
          return env.colors.neutral_600;
          break;
      }
    }
  };

  const getPendientes = () => {
    setBtnPendientes(true);
  };

  const getHistorial = () => {
    setBtnPendientes(false);
  };

  useEffect(() => {
    if (ciclo?.id) {
      getData();
    }
  }, [btnPendientes, ciclo]);

  useEffect(() => {}, [idsSeleccionados]);

  const enviarAlta = padronesSeleccionados => {
    if (idsSeleccionados.length > 0) {
      setBlocking(true);
      let data = { ids: idsSeleccionados, id_ciclo: ciclo.id, padronesSeleccionados };
      dialog.current.close();
      Api.create('admin/sic/confirmar_altas', data)
        .then(response => {
          if (response.status === 200) {
            setIdsSeleccionados([]);
            getData();
            setConfirmAltas(!confirmAltas);
            setBlocking(false);
            Swal.fire({
              icon: 'success',
              title: '',
              text: `Se ha confirmado el alta de ${idsSeleccionados.length} productores`,
            });
          }
        })
        .catch(e => {
          setBlocking(false);
        });
    }
  };

  const cancelarAlta = () => {
    if (idsSeleccionados.length > 0) {
      setBlocking(true);
      dialogDelete.current.close();
      let data = { ids: idsSeleccionados, id_ciclo: ciclo.id };
      Api.create('admin/sic/cancelar_altas', data)
        .then(response => {
          if (response.status === 200) {
            setIdsSeleccionados([]);
            getData();
            setConfirmAltas(!confirmAltas);
            setBlocking(false);
            Swal.fire({
              icon: 'success',
              title: '',
              text: `Se ha cancelado el alta de ${idsSeleccionados.length} productores`,
            });
          }
        })
        .catch(e => {
          setBlocking(false);
        });
    }
  };

  useEffect(() => {
    if (edit !== null) {
      dialogEdit.current.editText(edit);
    }
  }, [edit]);

  return (
    <div>
        <div className="row mb-1">
          <div className="col-4">
            <SelectCiclo
              setFilter={evt => {
                setFilter(evt);
              }}></SelectCiclo>
          </div>
        </div>

      <div className="row justify-content-between mb-2 ml-1">
        <div className="btn-group" role="group" style={{ borderRadius: 1 }}>
          <button
            type="button"
            onClick={() => getPendientes()}
            className={'btn btn-light'}
            style={{
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
              backgroundColor: btnPendientes ? '#FFEED9' : 'transparent',
              borderWidth: 1,
              borderColor: '#d4d4d4',
            }}
            disabled={btnPendientes}>
            Pendientes
          </button>

          <button
            type="button"
            onClick={() => getHistorial()}
            className={'btn btn-light'}
            style={{
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
              backgroundColor: !btnPendientes ? '#FFEED9' : 'transparent',
              borderWidth: 1,
              borderColor: '#d4d4d4',
            }}
            disabled={!btnPendientes}>
            Historial
          </button>
        </div>

        <div className="col-3 float-right align-self-center">
            {ciclo?.uuid && auth.hasPermission('PADRON_ALTAS_EXPORTAR') && (
              <div className="d-flex">
                <LinkDownload url={`xls/${report}/${ciclo?.uuid}`} label="Descargar Excel" icon={env.images.icon.xls}></LinkDownload>
              </div>
            )}
          </div>
      </div>

      <CardContainer height={'100%'}>
        <div className="row">
          <div className="col-8">
            <SearchColumn
              data={originalData}
              columns={searchColumns}
              setDataFilter={evt => {
                setDataFilter(evt);
              }}></SearchColumn>
          </div>
        </div>

        <NoPagination
          blocking={blocking}
          columns={columns}
          data={data}
          fnBackgroundColor={getBackgroundColor}
          fnColor={getColor}></NoPagination>
        {btnPendientes && (
          <>
            <button
              disabled={idsSeleccionados.length == 0}
              onClick={() => dialog.current.open()}
              className="btn btn-sirio"
              style={{ borderRadius: '20px' }}>
              Confirmar alta
            </button>
            <button
              disabled={idsSeleccionados.length == 0}
              onClick={() => dialogDelete.current.open()}
              className="btn btn-outline-secondary btn-sirio"
              style={{
                borderRadius: '20px 20px 20px 20px',
                marginLeft: 10,
                backgroundColor: idsSeleccionados.length == 0 ? 'transparent' : '#93000A',
              }}>
              <span style={{ color: idsSeleccionados.length == 0 ? '#333' : 'white' }}>Cancelar alta</span>
            </button>
          </>
        )}
      </CardContainer>
      <ModalPadron ref={dialogEdit} />
      <ModalPadronAltasBajas
        ref={dialog}
        type="Alta"
        title="Confirmar alta de productores"
        onConfirm={padronesSeleccionados => {
          enviarAlta(padronesSeleccionados);
        }}></ModalPadronAltasBajas>

      <ModalPadronAltasBajasCancelar
        ref={dialogDelete}
        type="altas"
        title="Cancelar alta de productores"
        onConfirm={() => {
          cancelarAlta();
        }}></ModalPadronAltasBajasCancelar>
    </div>
  );
};

export default PadronAltasList;
