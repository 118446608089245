import React from 'react';
import IDs_WHISP from '../../constants/IdsWhisp';
import IconAlert from '../icon/icon-alert';
import IconCheck from '../icon/icon-check';
import IconWrong from '../icon/icon-wrong';

const LabelWhisp = ({ whisp, blocking }) => {
  if (blocking) return null;
  let icon = <IconAlert />;
  if (whisp && whisp.id === IDs_WHISP.BAJO) {
    icon = <IconCheck />;
  }

  if (whisp && whisp.id === IDs_WHISP.ALTO) {
    icon = <IconWrong />;
  }

  return (
    <div>
      {icon}
      <span>Riesgo de deforestación: {whisp?.nombre ?? 'Se necesita más información.'} </span>
    </div>
  );
};

export default LabelWhisp;
