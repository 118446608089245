import React, { useState, useEffect, createRef } from 'react';
import { Link } from 'react-router-dom';
import CardContainer from '../../../components/card/card-container.jsx';
import LabelCategory from '../../../components/label/label-category.jsx';
import LabelNumber from '../../../components/label/label-number.jsx';
import ModalAsignarLote from './modals/modal-asignar-lote.jsx';
import ModalNuevoLote from './modals/modal-nuevo-lote.jsx';
import ModalInfoLote from './modals/modal-info-lote.jsx';
import Api from '../../../api/global.service';
import { useAuth } from '../../../components/content/auth.jsx';
import env from '../../../environment.js';
import Swal from 'sweetalert2';

import NoPagination from '../../../components/pagination/noPagination.jsx';
import SelectCiclo from '../../../components/select/select-ciclo.jsx';

import { subscribe, unsubscribe } from '../../../components/events';
import LinkDownload from '../../../components/link/link-download.jsx';
import FormSelectMaterial from '../../../nuup/componentes/Forms/FormSelectMaterial.jsx';
import CheckboxList from '../../../components/forms/checkbox-list.jsx';
import './entregas-x-lote.css';
import ModalCambiarEstado from './modals/modal-cambiar-estado.jsx';
import LabelEstatus from '../../../components/label/label-estatus.jsx';
import LotesGraficaEstatus from './components/lotes-grafica-estatus.js';
import LotesExport from './components/lotes-export.js';
import LotesEntregasExport from './components/lotes-entregas-export.js';
import { useCoopConfig } from '../../../components/content/coop-config.jsx';
import { Colors } from '../../../constants';
import SearchColumn from '../../../components/search/search-column.jsx';
import colourStyles from '../../../utils/colourStyles';
import Select from 'react-select';
import transformWord from '../../../utils/transformWord.js';
import getBackgroundColor from '../../../utils/getBackgroundColor.js';
import getColor from '../../../utils/getColor.js';

const EntregasXLoteList = () => {
  const auth = useAuth();
  const coopConfig = useCoopConfig();
  const [data, setData] = useState([]);
  const [registros, setRegistros] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [ciclo, setCiclo] = useState({});
  //const [ids, setIds] = useState([]);
  const [folios, setFolios] = useState([]);
  const [blocking, setBlocking] = useState(true);
  const [menuVisible, setMenuVisible] = useState(false);
  const [date, setDate] = useState(null);
  const [report, setReport] = useState('all');
  const [blockingReport, setBlockingReport] = useState(false);
  const [btnLotes, setBtnLotes] = useState(true);
  const [lotes, setLotes] = useState([]);
  const [idLote, setIdLote] = useState(0);
  const [idsSeleccionados, setIdsSeleccionados] = useState([]);
  const dialogAsignarLote = createRef();
  const dialogNuevoLote = createRef();
  const dialogInfoLote = createRef();
  const dialogCambiarEstado = createRef();
  const [dataLotes, setDataLotes] = useState([]);
  const [infoLote, setInfoLote] = useState({});
  // const [idMunicipio, setIdMunicipio] = useState(0);
  const [estados, setEstados] = useState([]);
  const [producto, setProducto] = useState('café');
  const [showInfoLote, setShowInfoLote] = useState(false);
  const [showNuevoLote, setShowNuevoLote] = useState(false);
  const [cicloActual, setCicloActual] = useState(null);

  useEffect(() => {
    if (coopConfig?.config?.cooperativa?.producto?.nombre) {
      setProducto(coopConfig.config.cooperativa.producto.nombre);
    }
  }, [coopConfig.config]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'ENTREGA',
        columns: [
          {
            Header: (
              <input
                type="checkbox"
                name="entregasAll"
                onChange={e => handleCheckAll(e)}
                style={{ width: 20, height: 20, accentColor: Colors.primary_600, display: data.length > 0 ? 'block' : 'none' }}
                checked={idsSeleccionados.length > 0 && idsSeleccionados.length === originalData.length}
                ref={el => el && (el.indeterminate = idsSeleccionados.length > 0 && originalData.length > idsSeleccionados.length)}
              />
            ),
            id: 'acopio_uuid',
            //accessor: 'acopio_uuid',
            sortable: false,
            width: 50,
            Cell: ({ row }) => {
              return (
                <>
                  <div className="form-check">
                    <CheckboxList
                      name="entregas"
                      value={row.original.acopio_uuid}
                      handleCheck={handleCheck}
                      checked={idsSeleccionados.includes(row.original.acopio_uuid) === true}
                    />
                  </div>
                </>
              );
            },
          },
          {
            Header: 'FECHA ENTREGA',
            accessor: 'fecha_acopio',
            sortable: true,
            minWidth: 80,
            Cell: ({ value }) => {
              return <p className="text-left">{value != null ? value.substr(0, 10) : ''}</p>;
            },
          },
          {
            Header: 'FOLIO ENTREGA',
            accessor: 'folio_acopio',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'ESTATUS',
            accessor: 'estatus_entrega',
            sortable: true,
            minWidth: 80,
            show: !btnLotes,
            Cell: ({ value, row }) => {
              return <LabelEstatus color={row.original.estatus_color_entrega} label={row.original.estatus_entrega} />;
            },
          },
          {
            Header: 'FOLIO/LOTE',
            accessor: 'nombre_lote',
            sortable: true,
            minWidth: 50,
            Cell: ({ value, row }) => {
              if (row.original.nombre_lote != null) {
                return value;
              } else {
                return '-';
              }
            },
          },
          {
            Header: 'RECIBOS',
            accessor: 'recibo_pdf',
            sortable: true,
            Cell: ({ value, row }) => {
              let v = value;
              return (
                <LinkDownload
                  url={`recibos/entregas/descargar/${row.original.acopio_uuid}`}
                  label="Descargar"
                  icon={env.images.icon.pdf}></LinkDownload>
              );
            },
          },
        ],
      },
      {
        Header: 'INFORMACIÓN DE PRODUCTOR',
        columns: [
          {
            Header: 'CLAVE',
            accessor: 'productor_clave_tx',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return (
                <p className="text-right" style={{ margin: 0 }}>
                  {value}
                </p>
              );
            },
          },
          {
            Header: 'CÓDIGO',
            accessor: 'productor_codigo_tx',
            sortable: true,
            minWidth: 50,
            show: !!+coopConfig.config?.usa_codigo_tx,
          },
          {
            Header: 'NOMBRE PRODUCTOR',
            accessor: 'productor_nombre',
            sortable: true,
            minWidth: 50,
            Cell: ({ value, row }) => {
              if (row.original.productor_uuid != null) {
                return (
                  <Link
                    to={{ pathname: `/admin/cuentas/perfil/${row.original.productor_uuid}` }}
                    className="m-r-2"
                    style={{ color: '#789D16' }}>
                    <span>{value}</span>
                  </Link>
                );
              } else {
                return value;
              }
            },
          },
          {
            Header: 'LOCALIDAD',
            accessor: 'localidad',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'REGIÓN',
            accessor: 'region',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'CENTRO DE ACOPIO',
            accessor: 'centro_acopio',
            sortable: true,
            minWidth: 50,
          },
        ],
      },
      {
        Header: 'INFORMACIÓN DE ENTREGA',
        columns: [
          {
            Header: 'Nº RECIPIENTES',
            accessor: 'no_bultos',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value}></LabelNumber>;
            },
          },
          {
            Header: `PESO BRUTO (${coopConfig.acopioUnits()?.abreviacion_plural})`,
            accessor: 'peso_bruto',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2}></LabelNumber>;
            },
          },
          {
            Header: `PESO TARA (${coopConfig.acopioUnits()?.abreviacion_plural})`,
            accessor: 'peso_tara',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2}></LabelNumber>;
            },
          },
          {
            Header: `PESO DESC. (${coopConfig.acopioUnits()?.abreviacion_plural})`,
            accessor: 'peso_descuento',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2}></LabelNumber>;
            },
          },
          {
            Header: `PESO NETO (${coopConfig.acopioUnits()?.abreviacion_plural})`,
            accessor: 'peso_neto',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2}></LabelNumber>;
            },
          },
        ],
      },
      {
        Header: 'TIPO DE ENTREGA',
        columns: [
          {
            Header: 'TIPO DE CAFÉ',
            accessor: 'tipo_cafe',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'PROCESO',
            accessor: 'proceso',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'CATEGORÍA',
            accessor: 'tipos_entregas',
            sortable: true,
            minWidth: 50,
            show: coopConfig.mostrarTipo(),
          },
        ],
      },
      {
        Header: 'RESULTADOS DE MUESTREO',
        columns: [
          {
            Header: 'MADURACIÓN',
            accessor: 'tipo_maduracion',
            sortable: true,
            minWidth: 50,
            show: !!+coopConfig?.config?.config_muestreo?.mostrar_tipo_maduracion,
          },
          {
            Header: 'RENDIMIENTO',
            accessor: 'rendimiento',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2} suffix={'%'}></LabelNumber>;
            },
            show: !!+coopConfig?.config?.config_muestreo?.mostrar_rendimiento,
          },
          {
            Header: 'HUMEDAD',
            accessor: 'humedad',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2} suffix={'%'}></LabelNumber>;
            },
            show: !!+coopConfig?.config?.config_muestreo?.mostrar_mancha,
          },
          {
            Header: 'DEFECTO',
            accessor: 'mancha',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2} suffix={'%'}></LabelNumber>;
            },
            show: !!+coopConfig?.config?.config_muestreo?.mostrar_humedad,
          },
          {
            Header: 'COLOR',
            accessor: 'color',
            sortable: true,
            minWidth: 50,
            show: !!+coopConfig?.config?.config_muestreo?.mostrar_color,
          },
          {
            Header: 'COLOR',
            accessor: 'color_de_cafe',
            sortable: true,
            minWidth: 50,
            show: !!+coopConfig?.config?.config_muestreo?.mostrar_color_de_cafe,
          },
          {
            Header: 'AROMA',
            accessor: 'aroma',
            sortable: true,
            minWidth: 50,
            show: !!+coopConfig?.config?.config_muestreo?.mostrar_aroma,
          },
          {
            Header: 'OBSERVACIONES',
            accessor: 'observaciones',
            sortable: true,
            minWidth: 50,
            show: !!+coopConfig?.config?.config_muestreo?.mostrar_observaciones,
          },
          {
            Header: 'OBSERVACIONES',
            accessor: 'muestreo_observaciones',
            sortable: true,
            minWidth: 50,
            show: !!+coopConfig?.config?.config_muestreo?.mostrar_observaciones,
          },
          {
            Header: 'TIPO DE ' + transformWord(producto, ['uppercase']) + ' ENTREGADO',
            accessor: 'tipos_cafe',
            sortable: true,
            minWidth: 50,
            show: coopConfig.mostrarTipo(),
          },
          {
            Header: 'ESTATUS COSTALERA',
            accessor: 'nombre_cat_costaleras_estatus',
            sortable: true,
            minWidth: 50,
            show: coopConfig.mostrarCostalera(),
            Cell: ({ value }) => {
              if (value != null) {
                return (
                  <p className="text-left" style={{ margin: 0 }}>
                    {value}
                  </p>
                );
              } else {
                return (
                  <p className="text-left" style={{ margin: 0 }}>
                    {'-'}
                  </p>
                );
              }
            },
          },
        ],
      },
      {
        Header: 'INFORMACIÓN DE PAGO',
        columns: [
          {
            Header: `PRECIO A PRODUCTOR (${coopConfig.currency()?.symbol}/${coopConfig.acopioUnits()?.abreviacion})`,
            accessor: 'precio_por_kg',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
          {
            Header: `IMPORTE TOTAL (${coopConfig.currency()?.symbol})`,
            accessor: 'importe_total',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'DESCUENTOS DEUDAS',
            accessor: 'total_anticipos_recuperados',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
          {
            Header: `PAGADO A PRODUCTOR (${coopConfig.currency()?.symbol})`,
            accessor: 'pagado_a_productor',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
          {
            Header: `SALDO DE LA ENTREGA (${coopConfig.currency()?.symbol})`,
            accessor: 'total_saldo',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'MÉTODO DE PAGO',
            accessor: 'metodo_pago',
            sortable: true,
            minWidth: 50,
          },
        ],
      },
    ],
    [idsSeleccionados, btnLotes, data]
  );

  const columnsLotes = React.useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            name="lotesAll"
            checked={false}
            onChange={() => {}}
            style={{ width: 20, height: 20, accentColor: Colors.primary_600, display: 'block' }}
          />
        ),
        id: 'id',
        accessor: 'id',
        sortable: false,
        width: 50,
        Cell: ({ row }) => {
          return (
            <>
              <div className="form-check">
                <CheckboxList
                  name="lotes"
                  value={row.original.id}
                  handleCheck={event => handleLotesCheck(event, row.original)}
                  checked={idLote === Number(row.original.id)}
                />
              </div>
            </>
          );
        },
      },
      {
        Header: 'NOMBRE',
        accessor: 'nombre',
        sortable: true,
        minWidth: 80,
        Cell: ({ value, row }) => {
          return (
            <>
              <a
                onClick={() => {
                  setIdLote(row.original.id);
                  handleShow();
                }}
                className="btn btn-link text-left d-inline"
                style={{ color: Colors.primary_500 }}>
                <span>{value}</span>
              </a>
            </>
          );
        },
      },
      {
        Header: 'CÓDIGO',
        accessor: 'folio',
        sortable: true,
        minWidth: 50,
        Cell: ({ value, row }) => {
          if (row.original.folio != null) {
            return value;
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'TOTAL',
        accessor: 'volumen',
        sortable: true,
        minWidth: 50,
        Cell: ({ value, row }) => {
          if (row.original.volumen != null) {
            return <LabelNumber value={value} currency={false} digits={2} position="text-left"></LabelNumber>;
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'VOLUMEN POR ENTREGAR',
        accessor: 'lote_kgs_por_entregar',
        sortable: true,
        minWidth: 50,
        Cell: ({ value, row }) => {
          if (row.original.volumen != null) {
            return <LabelNumber value={value} currency={false} digits={2} position="text-left"></LabelNumber>;
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'VOLUMEN ACOPIADO',
        accessor: 'lote_kgs_entregados',
        sortable: true,
        minWidth: 50,
        Cell: ({ value, row }) => {
          if (row.original.lote_kgs_entregados != null) {
            return <LabelNumber value={value} currency={false} digits={2} position="text-left"></LabelNumber>;
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'AVANCE ACOPIO',
        accessor: 'avance_acopio',
        sortable: true,
        minWidth: 50,
        Cell: ({ value, row }) => {
          let avance_por = ((Number(row.original.lote_kgs_entregados) / Number(row.original.volumen)) * 100).toFixed(2) + ' %';
          if (row.original.volumen != null) {
            return <LabelNumber value={avance_por} currency={false} suffix=" %" digits={2} position="text-left"></LabelNumber>;
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'ESTADO',
        sortable: true,
        minWidth: 200,
        Cell: ({ value, row }) => {
          return (
            <Select
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              key={row.original.id}
              loading={blocking}
              options={estados}
              onChange={value => handlerSelectChange(value, row.original.uuid)}
              value={estados.find(it => it.value == row.original.id_recoleccion_acopio_estatus)}
              styles={colourStyles}
            />
          );
        },
      },
      {
        Header: 'OBSERVACIONES',
        accessor: 'observaciones',
        sortable: true,
        minWidth: 50,
      },
      {
        Header: 'FECHA DE ENTREGA O VENTA',
        accessor: 'fecha_entrega_venta',
        sortable: true,
        minWidth: 50,
        Cell: ({ value, row }) => {
          if (row.original.fecha_entrega_venta != null) {
            return value;
          } else {
            return '-';
          }
        },
      },
    ],
    [idsSeleccionados, estados, idLote]
  );

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'fecha_acopio', label: 'FECHA ENTREGA' },
      { value: 2, target: 'estatus', label: 'ESTATUS' },
      { value: 3, target: 'folio_entrega', label: 'FOLIO ENTREGA' },
      { value: 4, target: 'fecha_entrega', label: 'FECHA ENTREGA' },

      { value: 5, target: 'productor_clave_tx', label: 'CLAVE' },
      { value: 6, target: 'productor_codigo_tx', label: 'CÓDIGO' },
      { value: 7, target: 'folio_acopio', label: 'FOLIO RECIBIDO' },
      { value: 8, target: 'productor_nombre', label: 'NOMBRE PRODUCTOR' },
      { value: 9, target: 'localidad', label: 'LOCALIDAD' },
      { value: 10, target: 'region', label: 'REGIÓN' },
      { value: 11, target: 'centro_acopio', label: 'CENTRO DE ACOPIO' },
      { value: 12, target: 'cuenta_categoria', label: 'CATEGORÍA' },

      { value: 13, target: 'no_bultos', label: 'Nº RECIPIENTES' },
      { value: 14, target: 'peso_bruto', label: `PESO BRUTO (${coopConfig.acopioUnits()?.abreviacion_plural})` },
      { value: 15, target: 'peso_tara', label: `PESO TARA (${coopConfig.acopioUnits()?.abreviacion_plural})` },
      { value: 16, target: 'peso_neto', label: `PESO NETO (${coopConfig.acopioUnits()?.abreviacion_plural})` },
      { value: 17, target: 'precio_por_kg', label: 'PRECIO A PRODUCTOR' },
      { value: 18, target: 'importe_total', label: `IMPORTE TOTAL (${coopConfig.currency()?.symbol})` },
      { value: 19, target: 'total_anticipos_recuperados', label: 'DESCUENTOS DEUDAS' },
      { value: 20, target: 'pagado_a_productor', label: `PAGADO A PRODUCTOR (${coopConfig.currency()?.symbol})` },
      { value: 21, target: 'total_saldo', label: `SALDO DE LA ENTREGA (${coopConfig.currency()?.symbol})` },
    ],
    []
  );

  useEffect(() => {
    if (ciclo.id) {
      dialogAsignarLote.current.setIdCiclo(ciclo.id);
      dialogCambiarEstado.current.setIdCiclo(ciclo.id);
      getLotes();
    }
  }, [ciclo]);

  useEffect(() => {
    if (ciclo.id > 0) {
      //updateData(ciclo.id);
      filtrarPorLote();
    }
  }, [idLote, showInfoLote]);

  useEffect(() => {
    var curr = new Date();
    curr.setDate(curr.getDate());
    setCicloActual(coopConfig?.activeCicloAcopio()?.id);
    setDate(curr.toISOString().substring(0, 10));

    subscribe('Lote::onSave', e => handleSave(e));
    subscribe('LoteAsignado::onSave', e => handleSaveAsignado(e));
    subscribe('CambioEstado::onSave', e => handleCambioEstado(e));
    getEstados();
    return () => {
      unsubscribe('Lote::onSave');
      unsubscribe('LoteAsignado::onSave');
      unsubscribe('CambioEstado::onSave');
    };
  }, []);

  const setFilter = value => {
    setCiclo(value.target);
    updateData(value.target.id);
  };

  const filtrarPorLote = () => {
    if (idLote > 0) {
      setData(originalData.filter(d => Number(d.id_lote) === Number(idLote)));
    } else {
      setData(originalData);
    }
  };

  const getOriginalDataFromLote = (originalData) => {
    return originalData.filter(d => Number(d.id_lote) === Number(idLote))
  }

  const updateData = id_ciclo => {
    setBlocking(true);
    let filter = [{ column: 'id_ciclo', operator: '=', value: id_ciclo }];
    if (idLote > 0) {
      filter.push({ column: 'id_lote', operator: '=', value: idLote });
    }
    Api.Acopio.search('entregas_por_productor', { filter: filter })
      .then(async response => {
        let _data = response.data;
        setOriginalData(_data);
        var folios = _data
          .filter(function(item) {
            return item.folio_entrega != null && item.folio_entrega != '-' && item.folio_entrega != '';
          })
          .map(function(item) {
            return { value: item.folio_entrega, label: item.folio_entrega, date: item.fecha_entrega + ' 12:00:00' };
          })
          .sort(function(a, b) {
            if (a.date < b.date) return 1;
            if (a.date > b.date) return -1;
            return 0;
          })
          .filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                t => t.value === value.value // && t.name === value.name
              )
          )
          .sort(function(a, b) {
            if (a.value < b.value) return -1;
            if (a.value > b.value) return 1;
            return 0;
          });

        setFolios(folios);
        setBlocking(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('error', e);
        setBlocking(false);
      });
  };

  const setDataFilter = data => {
    setData(data);
  };

  const handleSave = event => {
    //setIds([]);

    Swal.fire({
      icon: 'success',
      title: '',
      text: `Se registró con éxito el lote ${event.detail.nombre}.`,
    });
    getLotes();
  };

  const handleSaveAsignado = event => {
    setIdsSeleccionados([]);
    Swal.fire({
      icon: 'success',
      title: '',
      text: `Entregas asociadas al lote seleccionado.`,
    });
    setIdLote(0);
    getLotes();
    updateData(event.detail.id_ciclo);
  };

  const handleCambioEstado = event => {
    setIdsSeleccionados([]);
    Swal.fire({
      icon: 'success',
      title: '',
      text: `Cambio de estado realizado.`,
    });
    setIdLote(0);
    updateData(event.detail.id_ciclo);
  };

  const handleDownloadReport = event => {
    event.preventDefault();
    let reportUrl = `xls/entregas_acopios/${ciclo?.uuid}`;
    if (report !== 'all') {
      // reportUrl = `xls/entregas_acopios/${ciclo?.uuid}/2022-12-14`;
      reportUrl = `xls/entregas_acopios/${ciclo?.uuid}/${date}`;
    }

    setBlockingReport(true);
    Api.download(reportUrl)
      .then(response => {
        let fileName = response.headers['content-disposition'].split('filename=')[1];

        const url = window.URL.createObjectURL(new Blob([response.data])); // you can mention a type if you wish
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); //this is the name with which the file will be downloaded
        link.click();
        // no need to append link as child to body.
        setTimeout(() => window.URL.revokeObjectURL(url), 0); // this is important too, otherwise we will be unnecessarily spiking memory!
        setBlockingReport(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        setBlockingReport(false);
      });
  };

  const getLotes = () => {
    Api.get('admin/acopios/entregas/lotes', localStorage.getItem(`${env.appName}-ciclo_id`), {})
      .then(response => {
        let lotes = response.data;
        setDataLotes(lotes);
        setLotes(
          lotes.map(function(lote) {
            return { label: lote.nombre, value: lote.id };
          })
        );
        setBlocking(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
      });
  };

  const handleCheckAll = event => {
    if (event.target.checked === true) {
      setCheckData(data);
    } else {
      setIdsSeleccionados([]);
    }
  };

  const handleCheck = event => {
    let idsSel = idsSeleccionados;
    if (event.target.checked === true) {
      setIdsSeleccionados([...idsSel, event.target.value]);
    } else {
      idsSel = idsSel.filter(item => item !== event.target.value);
      setIdsSeleccionados(idsSel);
    }
  };

  const handleLotesCheck = (event, row) => {
    if (event.target.checked) {
      setInfoLote(row);
      setIdLote(Number(event.target.value));
    } else {
      setIdLote(0);
      setInfoLote({});
    }
  };

  const handleLotesDropdown = event => {
    if (Number(event) > 0) {
      const _lote = dataLotes.filter(lote => Number(lote.id) === Number(event));
      setInfoLote(_lote[0]);
      setIdLote(Number(event));
    }
  };

  const openModalAsignarLote = () => {
    if (idsSeleccionados.length > 0) {
      dialogAsignarLote.current.show();
    } else {
      Swal.fire({
        icon: 'error',
        title: '',
        text: `Debe seleccionar al menos una entrega pora asignar`,
      });
    }
  };

  const openModalCambiarEstado = () => {
    if (idsSeleccionados.length > 0) {
      dialogCambiarEstado.current.show();
    } else {
      Swal.fire({
        icon: 'error',
        title: '',
        text: `Debe seleccionar al menos una entrega cambiar el estado`,
      });
    }
  };

  const getNombreLote = id_lote => {
    let nombre = '';
    if (id_lote > 0) {
      let _lote = dataLotes.filter(lote => Number(lote.id) === Number(id_lote));
      nombre = _lote[0]?.nombre;
    }
    return nombre;
  };

  const exportarXlsx = () => {
    if (idLote === 0 && btnLotes) {
      LotesExport(dataLotes, ciclo, estados);
    } else {
      LotesEntregasExport(data, getNombreLote(idLote), coopConfig.mostrarCostalera());
    }
  };

  const handleShow = () => {
    setShowInfoLote(true);
  };

  const handleNuevoLote = () => {
    setShowNuevoLote(true);
  };

  const setCheckData = () => {
    if (data.length > 0) {
      // console.log('setCheckData');
      // console.log('event.target.checked === true');
      let uuids = [];
      let idSel = idsSeleccionados;
      if (idSel.length === 0) {
        console.log('idSel.length === 0');
        uuids = data.map(d => {
          return d.acopio_uuid;
        });
      } else {
        // console.log('idSel.length > 0');
        // console.log('data.length:' + data.length);
        // console.log('idSel.length:' + idSel.length);
        let uuidsData = data.map(d => {
          return d.acopio_uuid;
        });
        uuids = idSel.concat(uuidsData.filter(item => idSel.indexOf(item) < 0));
      }
      setIdsSeleccionados(uuids);
    }
  };

  useEffect(() => {
    setRegistros(originalData);
    setData(originalData);
  }, [originalData]);

  const handlerSelectChange = (selectedOption, uuid) => {
    setBlocking(true);
    Api.update('admin/acopios/lotes/cambia_estado_lote', uuid, { id_recoleccion_acopio_estatus: selectedOption.value })
      .then(() => {
        getLotes();
        setBlocking(false);
      })
      .then(() => {
        setBlocking(true);
        updateData(ciclo.id);
        setBlocking(false);
      })
      .catch(e => {
        setBlocking(false);
        console.error('Error actualizando estado:', e);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'No se pudo cambiar el estado. Inténtalo de nuevo.',
        });
      });
  };

  const resetLotes = () => {
    setIdLote(0);
  };

  const getEstados = () => {
    Api.getAll('acopios/catalogos/recoleccion_acopios_estatus')
      .then(response => {
        let estados = response.data;
        setEstados(
          estados.map(function(estado) {
            return { label: estado.nombre, value: estado.id, color: estado.color };
          })
        );
      })
      .catch(e => {
        // eslint-disable-next-line no-console
      });
  };
  const eliminarLote = () => {
    setBlocking(true);
    const warningText =
      data.length > 0
        ? 'El lote tiene entregas vinculadas. Al eliminar el lote NO se eliminarán las entregas, sólo la información del lote'
        : 'El registro de lote seleccionado se eliminará';

    Swal.fire({
      title: '',
      icon: 'warning',
      text: warningText,
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
      confirmButtonColor: '#FF5733',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#f4f4f4',
    }).then(result => {
      if (result.isConfirmed) {
        Api.delete('admin/acopios/lotes', idLote)
          .then(response => {
            if (response.data.success === 1) {
              Swal.fire({
                icon: 'success',
                title: '',
                text: 'Se eliminó el lote seleccionado.',
              });
              resetLotes();
              getLotes();

              //esto pudo ser un updateData(ciclo), pero esta función se llama cuando idLote no ha cambiado a 0
              let filter = [{ column: 'id_ciclo', operator: '=', value: ciclo }];
              Api.Acopio.search('entregas_por_productor', { filter: filter })
                .then(async response => {
                  let _data = response.data;
                  setOriginalData(_data);
                  var folios = _data
                    .filter(function(item) {
                      return item.folio_entrega != null && item.folio_entrega != '-' && item.folio_entrega != '';
                    })
                    .map(function(item) {
                      return { value: item.folio_entrega, label: item.folio_entrega, date: item.fecha_entrega + ' 12:00:00' };
                    })
                    .sort(function(a, b) {
                      if (a.date < b.date) return 1;
                      if (a.date > b.date) return -1;
                      return 0;
                    })
                    .filter((value, index, self) => index === self.findIndex(t => t.value === value.value))
                    .sort(function(a, b) {
                      if (a.value < b.value) return -1;
                      if (a.value > b.value) return 1;
                      return 0;
                    });
                  setFolios(folios);
                  setBlocking(false);
                })
                .catch(err => {
                  console.error(err);
                });
            }
          })
          .catch(error => {
            if (error.response) {
              setBlocking(false);
              Swal.fire({
                title: 'Error',
                icon: 'error',
                text: error.response.data.message || 'Acceso prohibido',
              });
            } else {
              // eslint-disable-next-line no-console
              console.error('error: ', error);
              setBlocking(false);
            }
          });
      } else {
        setBlocking(false);
      }
    });
  };

  const handleInfoModalClose = () => {
    setShowInfoLote(false);
  };

  const handleNuevoModalClose = () => {
    setShowNuevoLote(false);
  };

  return (
    <div>
      <div className="row justify-content-between mb-2">
        <div className="col-2">
          <SelectCiclo
            width="150px"
            setFilter={evt => {
              setFilter(evt);
            }}
            acopio
          />
        </div>
        <div className="col-2 offset-1 poquitoAbajo">
          {ciclo?.uuid && auth.hasPermission('ACOPIOS_ENTREGAS_X_PRODUCTOR_EXPORTAR') && (
            <>
              <Link to="#" onClick={() => exportarXlsx()} className="m-r-2" style={{ color: '#789D16' }}>
                <img src={env.images.icon.xls} className="pr-1" /> <span>Descargar Excel</span>
              </Link>
              {menuVisible === true && (
                <form className="dropdown-menu dropdown-menu dropdown-menu-right" style={{ display: 'block', padding: '16px', top: 32 }}>
                  <div className="form-check pb-2">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="report"
                      value="all"
                      checked={report === 'all'}
                      onClick={() => setReport('all')}
                    />
                    <label className="form-check-label" htmlFor="dropdownCheck">
                      Todas las entregas
                    </label>
                  </div>
                  <div className="form-check pb-2">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="report"
                      value="date"
                      checked={report !== 'all'}
                      onClick={() => setReport('date')}
                    />
                    <label className="form-check-label" htmlFor="dropdownCheck">
                      Entregas por fecha
                    </label>
                  </div>
                  {report === 'date' && (
                    <div className="form-group">
                      <input
                        type="date"
                        className="form-control"
                        placeholder="DD/MM/YYYY"
                        defaultValue={date}
                        onChange={e => setDate(e.target.value)}
                      />
                    </div>
                  )}
                  {blockingReport && (
                    <div className="d-flex justify-content-start float-right">
                      <div
                        className="spinner-border text-warning mr-2"
                        role="status"
                        aria-hidden="true"
                        style={{ height: 24, width: 24 }}></div>
                      <span className="mt-1">Descargando ...</span>
                    </div>
                  )}
                  {!blockingReport && (
                    <button
                      type="submit"
                      className="btn btn-xs btn-outline-secondary btn-sirio float-right"
                      onClick={e => handleDownloadReport(e)}>
                      Descargar
                    </button>
                  )}
                </form>
              )}
            </>
          )}
        </div>
      </div>

      <CardContainer height={'100%'}>
        <div className="row align-items-center mb-3 mt-3">
          <div className="col-4 poquitoArriba">
            <FormSelectMaterial
              id="lote"
              error={false}
              label={'Lote'}
              value={idLote}
              onChange={handleLotesDropdown}
              options={lotes}
              showAll={true}
              showAllLabel="Elige un lote"
            />
          </div>
          <div className="col-2 d-flex justify-content-center">
            <button
              onClick={() => {
                setShowInfoLote(true);
              }}
              type="button"
              className="btn btn-outline-secondary btn-sirio pr-3"
              disabled={idLote === 0}
              style={{
                color: Colors.gray_base,
                borderColor: Colors.gray_base,
                borderRadius: '20px 20px 20px 20px',
                flexGrow: 1,
                flexBasis: 'auto',
                opacity: idLote > 0 ? 1 : 0.4,
              }}>
              <span style={{ color: Colors.gray_base }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-info-circle mr-2"
                  viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8  0 0 0 0 16" />
                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                </svg>{' '}
                {'Ver lote'}
              </span>
            </button>
          </div>
          {ciclo && ciclo?.id == cicloActual ? (
            <>
              <div className="col-2 d-flex justify-content-center">
                <button
                  onClick={() => {
                    handleNuevoLote();
                  }}
                  type="button"
                  className="btn btn-outline-secondary btn-sirio pr-3"
                  disabled={idLote === 0}
                  style={{
                    borderRadius: '20px 20px 20px 20px',
                    flexGrow: 1,
                    flexBasis: 'auto',
                    opacity: idLote > 0 ? 1 : 0.4,
                  }}>
                  <span>
                    <i className="fa fa-edit mr-2"></i> {'Editar lote'}
                  </span>
                </button>
              </div>
              <div className="col-2 d-flex justify-content-center">
                <button
                  onClick={() => eliminarLote()}
                  type="button"
                  className="btn btn-outline-secondary btn-sirio pr-3"
                  disabled={idLote === 0}
                  style={{
                    borderColor: 'red',
                    borderRadius: '20px 20px 20px 20px',
                    flexGrow: 1,
                    flexBasis: 'auto',
                    opacity: idLote > 0 ? 1 : 0.4,
                  }}>
                  <span style={{ color: 'red' }}>
                    <i className="fa fa-trash pr-2"></i> {' Eliminar lote'}
                  </span>
                </button>
              </div>
            </>
          ) : null}

          <div className="col-2 d-flex justify-content-center">
            {idLote === 0 && ciclo && ciclo?.id == cicloActual ? (
              <button
                style={{ borderRadius: '20px 20px 20px 20px', color: '#333', fontWeight: 'normal', fontSize: 16 }}
                className="btn btn-sirio pr-3"
                onClick={() => {
                  setInfoLote({});
                  setShowInfoLote(false);
                  setShowNuevoLote(true);
                }}>
                <i className="fa fa-plus mr-2"></i> Nuevo lote
              </button>
            ) : null}
            {idLote !== 0 ? (
              <button
                className="div-lote-seleccionado"
                style={{ border: 'none', borderRadius: 0, appearance: 'none' }}
                onClick={() => setIdLote(0)}>
                <span className="texto-lote-seleccionado">{'Lote: ' + getNombreLote(idLote)}</span>
                <span className="x-lote-seleccionado mh-100" aria-label="Close" aria-hidden="true">
                  &times;
                </span>
              </button>
            ) : null}
          </div>
        </div>

        <div className="col-lg-6">
          <p>
            <span className="entregas-titulo">LOTES</span>
          </p>
        </div>

        <NoPagination blocking={blocking} selected="id" columns={columnsLotes} data={dataLotes} />

        {!btnLotes && (
          <div style={{ marginTop: 10 }}>
            <NoPagination
              blocking={blocking}
              selected="id"
              columns={columns}
              data={data}
              fnBackgroundColor={getBackgroundColor}
              fnColor={getColor}></NoPagination>

            <div className="row mt-3">
              <div className="col-md-12">
                <p>{idsSeleccionados.length + ' seleccionados de un total de ' + originalData.length + ' registros'}</p>
              </div>

              <div className="col-md-12">
                <button
                  style={{ borderRadius: '20px 20px 20px 20px', color: '#333', fontWeight: 'normal', fontSize: 16 }}
                  className="btn btn-sirio"
                  onClick={() => openModalCambiarEstado()}>
                  <i className="fa fa-plus"></i> Cambiar estado
                </button>
              </div>
            </div>
          </div>
        )}
      </CardContainer>

      {!btnLotes && (
        <div className="mt-3">
          <CardContainer height={'100%'}>
            <LotesGraficaEstatus data={data} />
          </CardContainer>
        </div>
      )}

      {btnLotes && (
        <CardContainer height={'100%'} style={{ marginTop: 10 }}>
          <div className="row">
            <div className="col-lg-4">
              <p>
                <span className="entregas-titulo">ENTREGAS</span>
                {idLote === 0 ? (
                  <span className="entregas-subtitulo"> Asigna o edita entregas a los lotes</span>
                ) : (
                  <span className="entregas-subtitulo"> asociadas al lote</span>
                )}
              </p>
            </div>
            <div className="col-lg-4 mr-0">
              <SearchColumn
                data={getOriginalDataFromLote(originalData)}
                columns={searchColumns}
                setDataFilter={evt => {
                  setDataFilter(evt);
                }}></SearchColumn>
            </div>
            {idLote !== 0 ? (
              <button
                className="div-lote-seleccionado col-2 mb-2"
                style={{ border: 'none', borderRadius: 0, appearance: 'none' }}
                onClick={() => setIdLote(0)}>
                <span className="texto-lote-seleccionado">{'Lote: ' + getNombreLote(idLote)}</span>
                <span className="x-lote-seleccionado mh-100" aria-label="Close" aria-hidden="true">
                  &times;
                </span>
              </button>
            ) : null}
            <div className="col-md-2">
              <button
                style={{ borderRadius: '20px 20px 20px 20px', color: '#333', fontWeight: 'normal', fontSize: 16 }}
                className="btn btn-sirio"
                onClick={() => openModalAsignarLote()}>
                <i className="fa fa-plus"></i> Asignar lote
              </button>
            </div>
          </div>

          <NoPagination
            blocking={blocking}
            selected="id"
            columns={columns}
            data={data}
            fnBackgroundColor={getBackgroundColor}
            fnColor={getColor}></NoPagination>

          <div className="row mt-3">
            <div className="col-md-12">
              <p>{idsSeleccionados.length + ' seleccionados de un total de ' + data.length + ' registros'}</p>
            </div>
          </div>
        </CardContainer>
      )}

      <ModalAsignarLote
        lotes={lotes}
        entregas={idsSeleccionados}
        ref={dialogAsignarLote}
        title="Asignar entregas a lote"></ModalAsignarLote>
      <ModalCambiarEstado entregas={idsSeleccionados} ref={dialogCambiarEstado} title="Cambiar estado de entregas"></ModalCambiarEstado>
      {showNuevoLote && (
        <ModalNuevoLote
          ref={dialogNuevoLote}
          infoLote={infoLote ? infoLote : {}}
          idLote={idLote}
          onClose={handleNuevoModalClose}
          show={showNuevoLote}
          cicloActual={cicloActual}></ModalNuevoLote>
      )}
      {showInfoLote && <ModalInfoLote ref={dialogInfoLote} idLote={idLote} onClose={handleInfoModalClose} title="" />}
    </div>
  );
};

export default EntregasXLoteList;
