import { useEffect, useState } from 'react';
import { getIdCatCreditoPrecosecha, useCoopConfig } from '../components/content/coop-config';
import DebtCalculator from '../nuup/utils/debtCalculator';
import Api from '../api/global.service';
import getEstatus from '../nuup/utils/getStatusDebtColor';
import moment from 'moment/moment';
import xlsx from 'json-as-xlsx';
import transformWord from '../utils/transformWord';
import insertIf from '../utils/insertIf';

const useAnticiposXproductor = ({ ciclo, update, etiquetaAnticipos }) => {
  const [blocking, setBlocking] = useState(true);
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  const coopConfig = useCoopConfig();

  const updateData = id_ciclo => {
    setBlocking(true);
    const interesCoop = coopConfig.catCreditoPrecosecha()?.interes;
    Api.Acopio.search('anticipos_por_productor', {
      filter: [{ column: 'id_ciclo', operator: '=', value: id_ciclo }],
    })
      .then(response => {
        const updatedData = response.data.map(productor => {
          let total = 0;
          let total_anticipos = 0;
          let total_interes = 0;
          let total_anticipos_recuperados = 0;
          let total_comisiones = 0;
          let total_saldo = 0;
          let saldoEstatus = -1;

          // RETURN DATA WITH DEBT CALCULATION
          if (getIdCatCreditoPrecosecha() > 2) {
            productor.anticipos.forEach(anticipo => {
              const debtCalc = new DebtCalculator({
                credito: anticipo,
                interestRate: anticipo.interes && +anticipo.interes >= 0 ? +anticipo.interes * 100 : +interesCoop,
                pagos: anticipo.anticipo_pagos,
                stringDate: null,
              });
              const calc = debtCalc.getDebt();
              if (calc.debtToday.pendingCapital / 100 >= 0.01) saldoEstatus = 1;
              total += +calc.debtToday.totalInterest / 100 + +anticipo.cantidad_anticipo / 100;
              total_anticipos += +anticipo.cantidad_anticipo / 100;
              total_interes += calc.debtToday.totalInterest / 100;
              total_anticipos_recuperados += anticipo.anticipo_pagos.reduce((acc, pg) => acc + +pg.cantidad_pago / 100, 0);
              total_comisiones += anticipo.anticipo_pagos.reduce((acc, pg) => acc + +pg.comision_efectivo / 100, 0);
              total_saldo += calc.debtToday.pendingCapital / 100;
            });
          } else {
            // RETURN DATA WITH NO INTEREST RATE

            productor.anticipos.forEach(anticipo => {
              const recuperado = anticipo.anticipo_pagos.reduce((acc, pg) => acc + +pg.cantidad_pago / 100, 0);
              total += +anticipo.cantidad_anticipo / 100;
              total_anticipos += +anticipo.cantidad_anticipo / 100;
              total_interes += 0;
              total_anticipos_recuperados += recuperado;
              total_comisiones += anticipo.anticipo_pagos.reduce((acc, pg) => acc + +pg.comision_efectivo / 100, 0);
              total_saldo += +anticipo.cantidad_anticipo / 100 - recuperado;
              if (+anticipo.cantidad_anticipo / 100 - +recuperado >= 0.01) saldoEstatus = 1;
            });
          }

          const estatus = getEstatus(saldoEstatus);
          return {
            ...productor,
            total,
            total_anticipos,
            total_interes,
            total_anticipos_recuperados,
            total_comisiones,
            total_saldo,
            estatus: estatus.label,
            estatus_color: estatus.color,
          };
        });
        setData(updatedData);
        setOriginalData(updatedData);
        setBlocking(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('error: ', e);
        setBlocking(false);
      });
  };

  useEffect(() => {
    if (ciclo.id) {
      updateData(ciclo.id);
    }
  }, [ciclo, update]);

  const exportarAnticipos = () => {
    let xlsData1 = [
      {
        sheet: 'Anticipos',
        columns: [
          { label: 'CLAVE', value: 'clave_tx' },
          ...insertIf(!!+coopConfig.config?.usa_codigo_tx, { label: 'CÓDIGO', value: 'codigo_tx' }),
          { label: 'NOMBRE PRODUCTOR', value: 'productor_nombre' },
          { label: 'LOCALIDAD', value: 'localidad' },
          { label: 'REGIÓN', value: 'region' },
          { label: 'CENTRO DE ACOPIO', value: 'centro_acopio' },
          { label: 'CATEGORÍA', value: 'cuenta_categoria' },
          ...insertIf(getIdCatCreditoPrecosecha() > 2, {
            label: 'IMPORTE TOTAL',
            value: 'total',
            format: `${coopConfig.currency()?.symbol}#,##0.00`,
          }),
          { label: 'CAPITAL DESEMBOLSADO', value: row => +row.total_anticipos, format: `${coopConfig.currency()?.symbol}#,##0.00` },
          ...insertIf(getIdCatCreditoPrecosecha() > 2, {
            label: 'INTERÉS ACUMULADO',
            value: 'total_interes',
            format: `${coopConfig.currency()?.symbol}0.00`,
          }),
          ...insertIf(getIdCatCreditoPrecosecha() > 2, {
            label: 'INTERÉS ACUMULADO',
            value: 'total_interes',
            format: `${coopConfig.currency()?.symbol}0.00`,
          }),
          {
            label: transformWord(etiquetaAnticipos, ['singular', 'uppercase']) + ' RECUPERADOS',
            value: row => +row.total_anticipos_recuperados,
            format: `${coopConfig.currency()?.symbol}0.00`,
          },
          {
            label: 'COMISIÓN',
            value: 'total_comisiones',
            format: `${coopConfig.currency()?.symbol}0.00`,
          },
          {
            label: ' SALDO ',
            value: row => (row.total_saldo < 0.01 && row.total_saldo > -0.01 ? 0 : row.total_saldo),
            format: `${coopConfig.currency()?.symbol}0.00`,
          },
          { label: 'ESTATUS', value: 'estatus' },
        ],
        content: data,
      },
    ];

    let settings = {
      fileName: `Anticipos_X_Productor_${ciclo.nombre}_${moment().format('YYYYMMDD_HHmmss')}`,
    };
    xlsx(xlsData1, settings);
  };

  return {
    blocking,
    data,
    originalData,
    exportarAnticipos,
    setData,
  };
};

export default useAnticiposXproductor;
