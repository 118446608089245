import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CardContainer from "../../components/card/card-container.jsx";
import Api from "../../api/global.service";
import { useAuth } from "../../components/content/auth.jsx";
import env from "../../environment.js";
import Swal from "sweetalert2";
import { useCoopConfig } from "../../components/content/coop-config.jsx";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {ReactHookFormSelect} from "../../nuup/componentes/Forms/ReactHookFormSelect.jsx";
import {ReactHookFormInput} from "../../nuup/componentes/Forms/ReactHookFormInput.jsx";

import CalidadAnalisisFisicoZarandas from './calidad-analisis-fisico-zarandas.js';
import { useHistory } from "react-router-dom";
import {getCalidadLS} from './components/helpers.js';

const CalidadAnalisisFisico = () => {
  const queryParameters = new URLSearchParams(window.location.search)
  const MSG_REQUIRED = "Información obligatoria";
  const MSG_LT_PMP = "El valor debe ser menor al peso muestra pergamino";

  const history = useHistory();
  const auth = useAuth();
  const coopConfig = useCoopConfig();
  const [data, setData] = useState([]);
  const [ciclo, setCiclo] = useState({});
  const [blocking, setBlocking] = useState(false);
  const [pesoMuestraMismaAnterior, setPesoMuestraMismaAnterior] = useState(1);


  const [defaultValues] = useState({
    id_calidad_analisis: getCalidadLS('id_calidad_analisis'),
    id_calidad_analisis_fisico: getCalidadLS('id_calidad_analisis_fisico'),
    id_calidad_analisis_sensorial: getCalidadLS('id_calidad_analisis_sensorial'),
    id_cuenta: getCalidadLS('id_cuenta'),
    id_acopio: getCalidadLS('id_acopio'),
    id_recoleccion_acopio: getCalidadLS('id_lote'),
    id_tipo_analisis: getCalidadLS('tipo_analisis'),
    analisis_por_entrega: getCalidadLS('analisis_por_entrega'),
    nombre_proveedor: getCalidadLS('nombre_proveedor'),
    region_proveedor: getCalidadLS('region_proveedor'),
    notas_adicionales: getCalidadLS('notas_adicionales'),
    id_municipio: getCalidadLS('id_municipio'),
    id_localidad: getCalidadLS('id_localidad'),
    uuid: "",
    fecha: '',
    id_tipo_proceso: "",
    pergamino_peso_muestra_pergamino: "",
    pergamino_peso_muestra_oro: "",
    pergamino_peso_muestra_cerezo: "",
    pergamino_peso_mancha: "",
    pergamino_densidad: "",
    pergamino_humedad: "",
    pergamino_actividad_agua: "",
    id_color_muestra: "",
    olor_muestra: "",
    peso_muestra_misma_anterior: 1,
    pergamino_peso_muestra_oro_2: "",
    observaciones: "",
    zarandas: [
      {
        id_calidad_analisis_fisicos_zaranda: 0,
        id_zaranda: 0,
        valor: "",
        eliminar: 0,
        defectos_primarios: [
          {
          id_calidad_analisis_fisicos_defecto: 0,
          id_tipo_mancha: "",
          no_granos: "",
          gramos: "",
          eliminar: 0,
          }
        ],
        defectos_secundarios: [
          {
            id_calidad_analisis_fisicos_defecto: 0,
            id_tipo_mancha: "",
            no_granos: "",
            gramos: "",
            eliminar: 0,
          }
        ],
      },
    ],
    id_ciclo: localStorage.getItem(`${env.appName}-ciclo_id`)
  });

  let patternNumero2Decimales = /^\d+(\.\d{0,2})?$/;
  const validacionNumero2Decimales = yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .min(0, 'se espera que sea mayor o igual a 0')
    .test('is-decimal', 'Solo se permiten dos decimales', val => {
      if (val != undefined) {
        return patternNumero2Decimales.test(val);
      }
      return true;
    });
  
    const zarandaDefectosSchema = {
      id_tipo_mancha: yup
        .number()
        .transform(value => (isNaN(value) ? undefined : value))
        .min(1, 'Seleccione una opción')
        .required('Seleccione una opción'),
      no_granos: validacionNumero2Decimales.min(0.01, 'se espera que sea mayor a 0').required(MSG_REQUIRED),
      gramos: validacionNumero2Decimales.min(0.01, 'se espera que sea mayor a 0').required(MSG_REQUIRED),
      eliminar: yup.number().transform(value => (isNaN(value) ? undefined : value)).nullable(),
    };

  const zarandaSchema = {
    id_zaranda: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .min(1, 'Seleccione una opción')
      .required('Seleccione una opción'),
    valor: validacionNumero2Decimales.min(0.01, 'se espera que sea mayor a 0').required(MSG_REQUIRED),
    eliminar: yup.number().transform(value => (isNaN(value) ? undefined : value)).nullable(),
    defectos_primarios: yup.array().of(yup.object().shape(zarandaDefectosSchema)),
    defectos_secundarios: yup.array().of(yup.object().shape(zarandaDefectosSchema)),
  };

    const schema = yup.object().shape({
        id_calidad_analisis_fisico: yup.string().nullable(),
          id_calidad_analisis: yup.string().nullable(),
          fecha: yup.date().transform((curr, orig) => orig === '' ? null : curr).required(MSG_REQUIRED).nullable(),
          id_tipo_proceso: yup.number().transform(value => (isNaN(value) ? undefined : value)).min(1,MSG_REQUIRED).required(MSG_REQUIRED),
          pergamino_peso_muestra_pergamino: validacionNumero2Decimales.min(0.01,MSG_REQUIRED).required(MSG_REQUIRED),
          pergamino_peso_muestra_oro: validacionNumero2Decimales.min(0.01,MSG_REQUIRED).lessThan(yup.ref('pergamino_peso_muestra_pergamino'), MSG_LT_PMP).required(MSG_REQUIRED),
          pergamino_peso_muestra_cerezo: validacionNumero2Decimales.min(0.01,MSG_REQUIRED).lessThan(yup.ref('pergamino_peso_muestra_pergamino'), MSG_LT_PMP).required(MSG_REQUIRED),
          pergamino_peso_mancha: validacionNumero2Decimales.min(0.01,MSG_REQUIRED).lessThan(yup.ref('pergamino_peso_muestra_pergamino'), MSG_LT_PMP).required(MSG_REQUIRED),
          pergamino_densidad: validacionNumero2Decimales.min(0.01,MSG_REQUIRED).nullable(),
          pergamino_humedad: validacionNumero2Decimales.min(0.01,MSG_REQUIRED).required(MSG_REQUIRED),
          olor_muestra: yup.string().nullable(),
          pergamino_actividad_agua: validacionNumero2Decimales.min(0.01,MSG_REQUIRED).nullable(),
          id_color_muestra: yup.number().transform(value => (isNaN(value) ? undefined : value)).min(1,MSG_REQUIRED).required(MSG_REQUIRED),
          peso_muestra_misma_anterior: yup.number().transform(value => (isNaN(value) ? undefined : value)).min(0).required(MSG_REQUIRED),
          pergamino_peso_muestra_oro_2: validacionNumero2Decimales.nullable().when("peso_muestra_misma_anterior", (peso_muestra_misma_anterior, schema) => {
            if (peso_muestra_misma_anterior === 0)
              return schema.min(0.01, MSG_REQUIRED).required(MSG_REQUIRED);
            return schema;
          }),
          observaciones: yup.string().nullable(),
          zarandas: yup.array().of(yup.object().shape(zarandaSchema))
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    getValues,
    clearErrors,
    setError,
    reset
  } = useForm({
    resolver: async (data, context, options) => {
      return yupResolver(schema)(data, context, options);
    },
    mode: "all",
    defaultValues: defaultValues,
  });

  const setFilter = (value) => {
    setCiclo(value.target);
  };

  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: "zarandas",
  });

  const agregaZaranda = () => {
    append({
      id_zaranda: 0,
      valor: ""
    });
  };

  const eliminaZaranda = (index) => {
    remove(index);
  }

  useEffect(() => {
    watch(values => {
      setPesoMuestraMismaAnterior(Number(values.peso_muestra_misma_anterior));
    });
  }, [watch]);

  useEffect(() => {

    let id_calidad_analisis_fisico = getCalidadLS('id_calidad_analisis_fisico');
    if(id_calidad_analisis_fisico.length !== 36){
      if(Number(getValues().peso_muestra_misma_anterior) === 1){
        setValue('pergamino_peso_muestra_oro_2', getValues().pergamino_peso_muestra_oro);
      }else{
        setValue('pergamino_peso_muestra_oro_2', '');
      }
    }
  }, [getValues().pergamino_peso_muestra_oro]);

  const onSubmit = values => {
    console.log('onSubmit');
    console.log(values);
  };

  const sendData = (data) => {

    let id_calidad_analisis_fisico = getCalidadLS('id_calidad_analisis_fisico');
    if(id_calidad_analisis_fisico.length === 36){
      Api.update('calidad/analisis/fisico', id_calidad_analisis_fisico ,data).then((response) => {
        setBlocking(false);
        history.push({pathname: '/admin/calidad/analisis/fisico/info'});
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log(e);
        setBlocking(false);
        Swal.fire({
          icon: 'error',
          title: '',
          text: e.response.data.message,
        });
      });
    }else{
      Api.create('calidad/analisis', data).then((response) => {
        setBlocking(false);
        let configAnalisis = JSON.parse(localStorage.getItem(`${env.appName}-calidad`));
        configAnalisis.id_calidad_analisis = response.data.data.id_calidad_analisis;
        configAnalisis.id_calidad_analisis_fisico = response.data.data.id_calidad_analisis_fisico;
        configAnalisis.id_calidad_analisis_sensorial = response.data.data.id_calidad_analisis_sensorial;
        localStorage.setItem(`${env.appName}-calidad`, JSON.stringify(configAnalisis));
        history.push({pathname: '/admin/calidad/analisis/fisico/info'});
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log(e);
        setBlocking(false);
        Swal.fire({
          icon: 'error',
          title: '',
          text: e.response.data.message,
        });
      });
    }
  };

	const validateHandler = () => {
    let data = getValues();

		schema.validate(data, { abortEarly: false })
			.then(function (valid) {
        sendData(data);
        clearErrors();
			}).catch(function (err) {
				err.inner.reduce(function (map, obj) {
          setError(obj.path,{message : obj.message});
					map[obj.path] = obj.message;
					return map;
				}, {});
				setBlocking(false);
			});
	}

  const getData = (id_calidad_analisis_fisico) => {
    Api.get('calidad/analisis/fisico',id_calidad_analisis_fisico).then((response) => {
      setBlocking(false);
      if (typeof response.data !== "undefined") {
        reset(response.data);
        setData(response.data);
      }
      setBlocking(false);
    })
    .catch((e) => {
      console.log("e", e);
      setBlocking(false);
    });
  }

  useEffect(() => {
    let id_calidad_analisis_fisico  = getValues().id_calidad_analisis_fisico;
    if(id_calidad_analisis_fisico.length === 36){
      getData(id_calidad_analisis_fisico);
    }
  }, []);

  return (
    <div>
      <div>
        <button
          className="btn btn-outline-dark mt-3"
          type="button"
          onClick={() => history.push({pathname: '/admin/calidad/seleccion',})}
           >
          <i className="fa fa-arrow-left"></i>
          {" Volver a tipo de muestra"}
        </button>
      </div>

      <CardContainer height={"100%"} style={{ marginTop: 10 }}>
        <div className="row mt-3">
          <div className="col-lg-6">
            <h4>{"Registro de análisis físico"}</h4>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-2">
            <ReactHookFormInput
              type="date"
              id="fecha"
              name="fecha"
              label=""
              placeholder=""
              error={errors.fecha}
              control={control}
              value={getValues().fecha}
            />
          </div>

          <div className="col-lg-2">


          <ReactHookFormSelect
            placeholder="Tipo de proceso"
            label="Tipo de proceso"
            id={'id_tipo_proceso'}
            name={'id_tipo_proceso'}
            error={errors.id_tipo_proceso ? true : false}
            url={"calidad/catalogos/tipo_procesos"}
            keyArray="id"
            labelArray="nombre"
            apiSearch={false}
            control={control}
            value={getValues().id_tipo_proceso}
          />
            {errors?.id_tipo_proceso && (
              <div className="invalid-feedback">
                {errors?.id_tipo_proceso.message}
              </div>
            )}
          </div>
          <div className="col-lg-2"></div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-12">
            <h5>{"Análisis pergamino"}</h5>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-5">
                <ReactHookFormInput
                  type="number"
                  id="pergamino_peso_muestra_pergamino"
                  name="pergamino_peso_muestra_pergamino"
                  label="Peso muestra pergamino (grs.)"
                  error={errors.pergamino_peso_muestra_pergamino}
                  control={control}
                  value={getValues().pergamino_peso_muestra_pergamino}
                />
              </div>
              <div className="col-lg-5">
              <ReactHookFormInput
                  type="number"
                  id="pergamino_peso_muestra_cerezo"
                  name="pergamino_peso_muestra_cerezo"
                  label="Peso muestra cerezo (grs.)"
                  error={errors.pergamino_peso_muestra_cerezo}
                  control={control}
                  value={getValues().pergamino_peso_muestra_cerezo}
                />
              </div>
              <div className="col-lg-2"></div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-6">
              <ReactHookFormInput
                  type="text"
                  id="olor_muestra"
                  name="olor_muestra"
                  label="Olor de la muestra"
                  error={errors.olor_muestra}
                  control={control}
                  value={getValues().olor_muestra}
                />
              </div>


              <div className="col-lg-6">
              <ReactHookFormInput
                  type="text"
                  id="pergamino_densidad"
                  name="pergamino_densidad"
                  label="Densidad (g./l.)"
                  error={errors.pergamino_densidad}
                  control={control}
                  value={getValues().pergamino_densidad}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-6">
            <div className="row">

            <div className="col-lg-5">
            <ReactHookFormInput
                  type="number"
                  id="pergamino_peso_muestra_oro"
                  name="pergamino_peso_muestra_oro"
                  label="Peso muestra oro (grs.)"
                  error={errors.pergamino_peso_muestra_oro}
                  control={control}
                  value={getValues().pergamino_peso_muestra_oro}
                />
              </div>

              <div className="col-lg-5">

              <ReactHookFormInput
                  type="number"
                  id="pergamino_peso_mancha"
                  name="pergamino_peso_mancha"
                  label="Mancha (grs.)"
                  error={errors.pergamino_peso_mancha}
                  control={control}
                  value={getValues().pergamino_peso_mancha}
                />

              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-6">
              <ReactHookFormInput
                  type="number"
                  id="pergamino_humedad"
                  name="pergamino_humedad"
                  label="Humedad (%)"
                  error={errors.pergamino_humedad}
                  control={control}
                  value={getValues().pergamino_humedad}
                />
              </div>
              <div className="col-lg-6">
              <ReactHookFormInput
                  type="number"
                  id="pergamino_actividad_agua"
                  name="pergamino_actividad_agua"
                  label="Actividad en agua"
                  error={errors.pergamino_actividad_agua}
                  control={control}
                  value={getValues().pergamino_actividad_agua}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-12">
            <hr />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-12">
            <h5>{"Análisis oro"}</h5>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-5">
                <p>
                  {
                    "¿El peso de tu muestra es la misma que el resultado anterior?"
                  }
                </p>
                <div className="row g-3">
                  <div className="col-auto">
                    <input
                      type="radio"
                      name="peso_muestra_misma_anterior"
                      checked={pesoMuestraMismaAnterior === 1}
                      onChange={() => {
                        setValue("peso_muestra_misma_anterior", 1);
                        setPesoMuestraMismaAnterior(1);
                      }}
                    />{" "}
                    Sí
                  </div>
                  <br />

                  <div className="col-auto">
                    <input
                      type="radio"
                      name="peso_muestra_misma_anterior"
                      checked={pesoMuestraMismaAnterior === 0}
                      onChange={() => {
                        setValue("peso_muestra_misma_anterior", 0);
                        setPesoMuestraMismaAnterior(0);
                      }}
                    />{" "}
                    No
                  </div>

                  {errors?.peso_muestra_misma_anterior && (
                    <div className="invalid-feedback">
                      {errors?.peso_muestra_misma_anterior.message}
                    </div>
                  )}
                </div>
              </div>

              {pesoMuestraMismaAnterior === 0 && (
                <div className="col-lg-5">
                  <ReactHookFormInput
                    type="number"
                    id="pergamino_peso_muestra_oro_2"
                    name="pergamino_peso_muestra_oro_2"
                    label="Peso muestra oro (grs.)"
                    error={errors.pergamino_peso_muestra_oro_2}
                    control={control}
                    value={getValues().pergamino_peso_muestra_oro_2}
                  />
                </div>
              )}


              <div className={"col-lg-5" + (pesoMuestraMismaAnterior === 0 ? " mt-3" : "")}>
                <ReactHookFormSelect
                  placeholder="Color de la muestra"
                  label="Color de la muestra"
                  id={'id_color_muestra'}
                  name={'id_color_muestra'}
                  error={errors.id_color_muestra ? true : false}
                  url={"calidad/catalogos/color_muestras"}
                  keyArray="id"
                  labelArray="nombre"
                  apiSearch={false}
                  control={control}
                  value={getValues().id_color_muestra}
                />
                {errors?.id_color_muestra && (
                  <div className="invalid-feedback">
                    {errors?.id_color_muestra.message}
                  </div>
                )}
              </div>


            </div>
          </div>

          <div className="col-lg-6">
            <ReactHookFormInput
              type="text"
              id="observaciones"
              name="observaciones"
              label="Observaciones"
              error={errors.observaciones}
              control={control}
              value={getValues().observaciones}
              multiline
            />
          </div>
        </div>

        <CalidadAnalisisFisicoZarandas {...{ control, register, defaultValues, getValues, setValue, errors }}/>

        <div className="row mt-5">

          <div className="col-xs-6 col-xl-3 col-xxl-2">
            <button
              onClick={() => validateHandler()}
              style={{
                borderRadius: "20px 20px 20px 20px",
                color: "#333",
                fontWeight: "normal",
                fontSize: 16,
              }}
              className="btn btn-sirio"
            >
               {'Continuar'} 
            </button>
          </div>
        </div>

      </CardContainer>
    </div>
  );
};

export default CalidadAnalisisFisico;
