import React from 'react';
import env from '../../environment';

const IconAlert = () => (
  <i
    className="fa fa-exclamation-triangle"
    aria-hidden="true"
    style={{
      color: env.colors.secondary_300,
      marginRight: 10,
    }}
  />
);

export default IconAlert;
