import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
import Api from '../../../../api/global.service.js';
import 'react-datepicker/dist/react-datepicker.css';
import formatNumber from '../../../../utils/formatNumber.js';
import './../entregas-x-lote.css';

import { PieChart, Pie, Cell, ResponsiveContainer, Label } from 'recharts';

import BlockUi from 'react-block-ui';
import env from '../../../../environment.js';
import { useCoopConfig } from '../../../../components/content/coop-config.jsx';

const ModalInfoLote = forwardRef((props, ref) => {
  const COLORS_DON = ['#FFEED9', '#BC8268'];
  const coopConfig = useCoopConfig();
  const [show, setShow] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [dataGrafica, setDataGrafica] = useState([]);
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState(null);
  const modalRef = useRef();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        props.onClose();
      }  
    };

    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        props.onClose();
      }
    };

    if (show) {
      document.addEventListener('keydown', handleKeyDown);    
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [show, props]);

  useEffect(() => {
    setData([]);
    setDataGrafica([]);
    if (props.idLote > 0) {
      getData();
    }
  }, [props.idLote]);

  const getData = () => {
    setBlocking(true);
    Api.Acopio.get('lotes_info/' + props.idLote)
      .then(response => {
        setBlocking(false);
        setShow(true);
        if (typeof response.data[0] !== 'undefined') {
          setData(response.data[0]);
          setDataGrafica([
            { name: 'Totales', value: 100 },
            {
              name: 'Por entregar',
              value: (Number(response.data[0].lote_kgs_entregados) / Number(response.data[0].lote_kgs_totales)) * 100,
            },
          ]);
        } else {
          let err = response.data.data;
          setErrors(err);
        }
      })
      .catch(e => {
        setShow(false);
        setBlocking(false);
      });
  };

  return (
    <div className="modal" style={{ display: show ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.2)' }} role="dialog">
      <div className="modal-dialog modal-dialog-scrollable" ref={modalRef} style={{ top: 0, right: 25, position: 'absolute', width: 400, height: 'calc(100% - 50px)' }} role="document">
        <div className="modal-content" style={{ height: '100%' }}>
          <div className="modal-header" style={{ flex: 'inherit', position: 'relative' }}>
            <button onClick={() => props.onClose()} type="button" className="btn" aria-label="Close" style={{ borderRadius: '20px 20px 20px 20px' }}>
              <strong>{'>>'}</strong>
            </button>
            <div className="modal-title" style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%', width: 'fit-content' }}>
              {data.nombre && (
                <h2>
                  {data.nombre}
                </h2>
              )}
            </div>
          </div>

          <div className="modal-body">
            <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
              {data.lote_kgs_totales && (
                <ResponsiveContainer width="100%" height={'100%'}>
                  <div className="row align-items-center">
                    {data.lote_kgs_totales > 0 && (
                      <>
                        <div className="col-6">
                          <span className="lotes-kgs-total-label">{`${coopConfig.acopioUnits()?.abreviacion_plural} totales de lote: `}</span>{' '}
                        </div>
                        <div className="col-6">
                          <span className="lotes-kgs-total-valor">{formatNumber(data?.lote_kgs_totales)}</span>
                        </div>
                      </>
                    )}
                    <div className="col-6">
                      {data.folio && (<p className="lotes-kgs-total-label m-b-0">folio: </p>)}
                    </div>
                    <div className="col-6"><span className="lotes-kgs-total-valor">{data.folio}</span></div>
                    <div className="col-6">
                      {data.fecha_entrega_venta && (<p className="lotes-kgs-total-label">fecha de venta/entrega: </p>)}
                    </div>
                    <div className="col-6">
                      <span className="lotes-kgs-total-valor">{data.fecha_entrega_venta}</span>
                    </div>
                  </div>
                  <>
                    {data.lote_kgs_totales > 0 && (
                      <div className="row justify-content-center">
                          <PieChart width={250} height={250} className={"offset-1"}>
                            <Pie
                              data={dataGrafica}
                              cx={100}
                              cy={125}
                              innerRadius={60}
                              outerRadius={90}
                              fill="#8884D8"
                              paddingAngle={1}
                              dataKey="value">
                              {dataGrafica.map((entry, index) => (
                                <React.Fragment key={`cell-${index}`}>
                                  <Cell fill={COLORS_DON[index % COLORS_DON.length]} />
                                  <Label
                                    style={{ fontSize: 18 }}
                                    value={parseFloat(dataGrafica[1]?.value).toFixed(2) + '%'}
                                    position="center"
                                  />
                                </React.Fragment>
                              ))}
                            </Pie>
                          </PieChart>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-1 offset-3">
                        <img style={{ paddingTop: 20 }} src={env.images.card.circulo_BC8268} />
                      </div>

                      <div className="col-6">
                        <p className="lotes-kgs-entregados-valor">{formatNumber(data?.lote_kgs_entregados)}</p>
                        <p className="lotes-kgs-entregados-label">{coopConfig.acopioUnits()?.abreviacion_plural} entregados</p>
                      </div>
                    </div>

                    {data.lote_kgs_totales > 0 && (
                      <div className="row">
                        <div className="col-1 offset-3">
                          <img style={{ paddingTop: 20 }} src={env.images.card.circulo_FFEED9} />
                        </div>
                        <div className="col-6">
                          <p className="lotes-kgs-entregados-valor">{formatNumber(data?.lote_kgs_por_entregar)}</p>
                          <p className="lotes-kgs-entregados-label">{coopConfig.acopioUnits()?.abreviacion_plural} por entregar</p>
                        </div>
                      </div>
                    )}

                    {data.observaciones && (
                      <>
                        <div className="col-4">
                          <span className="lotes-kgs-total-label">Observaciones: </span>
                        </div>
                        <div className="col-8">
                          <span className="lotes-text">{data?.observaciones}</span>
                        </div>
                      </>
                    )}

                    {/* <hr />
								<div className='row'>
									<div className='col-6 pl-5'>
										<p className='lotes-kgs-entregados-valor'>{formatNumber(data?.lote_kgs_hoy)}</p>
										<p className='lotes-kgs-entregados-label'>kgs entregados hoy</p>
									</div>
									<div className='col-6'>
										<p className='lotes-kgs-entregados-valor'>{formatNumber(data?.lote_kgs_semana)}</p>
										<p className='lotes-kgs-entregados-label'>kgs entregados esta semana</p>
									</div>
								</div> */}
                  </>
                </ResponsiveContainer>
              )}
            </BlockUi>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ModalInfoLote;
