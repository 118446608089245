import React, { useState, useEffect } from 'react';
import Card from '../../components/card/card.jsx';
import CardColor from '../../components/card/card-color.jsx';
import HalfDonut from '../../components/chart/half-donut.jsx';
import Api from '../../api/global.service';
import { useAuth } from '../../components/content/auth.jsx';
import BlockUi from 'react-block-ui';
import env from '../../environment.js';
import SelectCiclo from '../../components/select/select-ciclo.jsx';
import SelectCentroAcopio from '../../components/select/select-centro-acopio.jsx';
import './acopio-resume.css';
import { useCoopConfig, getIdCatCreditoPrecosecha, usaCentrosAcopios } from '../../components/content/coop-config.jsx';
import transformWord from '../../utils/transformWord.js';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, LabelList } from 'recharts';
import DebtCalculator from '../../nuup/utils/debtCalculator.js';
import TotalItemChart from '../../components/dashboards/acopio/total-item-chart.js';
import AcopioKgsCategorias from '../../components/accordions/acopio-kgs-categorias.jsx';

const AcopioResume = () => {
  const auth = useAuth();
  const coopConfig = useCoopConfig();
  const [blocking, setBlocking] = useState(true);
  const [blockingCreditos, setBlockingCreditos] = useState(false);
  const [ciclo, setCiclo] = useState(3);
  const [centroAcopio, setCentroAcopio] = useState(0);

  // TOTALES
  const [totalesKgs, setTotalesKgs] = useState(0);
  const [totalesEnProceso, setTotalesEnProceso] = useState(0);
  const [totalesInventario, setTotalesInventario] = useState(0);
  // For future use
  // const [totalesKgsEnt, setTotalesKgsEnt] = useState(0);
  // const [totalesKgsDis, setTotalesKgsDis] = useState(0);
  const [totalesVendidos, setTotalesVendidos] = useState(0);
  const [totalesRechazados, setTotalesRechazados] = useState(0);

  // ORG VS CONVENCIONAL
  const [kgsOrgConv, setKgsOrgConv] = useState([]);
  const [kgsCatCert, setKgsCatCert] = useState([]);

  // por MES Y SEMANA
  const [kgsPorSemana, setKgsPorSemana] = useState([]);
  const [kgsPorMes, setKgsPorMes] = useState([]);
  const [producto, setProducto] = useState('café');
  const [porMes, setPorMes] = useState(false);

  // TOTALES EFECTIVO
  const [totalesEfectivo, setTotalesEfectivo] = useState([]);

  // CRÉDITOS
  const [totalAnticipoRecuperado, setTotalAnticipoRecuperado] = useState([]);
  const [totalAnticipoOtorgado, setTotalAnticipoOtorgado] = useState([]);
  const [totalIntereses, setTotalIntereses] = useState(0);
  const [avanceAnticipos, setAvanceAnticipos] = useState(null);
  const [etiquetaAnticipos, setEtiquetaAnticipos] = useState('-');
  const [convencLabel, setConvencLabel] = useState('convencional');

  useEffect(() => {
    getEtiquetaAnticipo();
    if (coopConfig?.config?.cooperativa?.producto?.nombre) {
      setProducto(transformWord(coopConfig.config.cooperativa.producto.nombre, ['singular', 'lowercase']));
      if (coopConfig.config?.cooperativa?.producto?.id === env.productos.MIEL) {
        setConvencLabel('transición');
      }
    }
  }, [coopConfig.config]);

  const getEtiquetaAnticipo = async () => {
    let etiqueta = await coopConfig.etiquetaAnticipos();
    setEtiquetaAnticipos(etiqueta);
  };

  const setFilterCiclo = value => setCiclo(value.target.id);

  const setFilterCentroA = value => {
    if (value.target == null) {
      setCentroAcopio(0);
    } else {
      setCentroAcopio(value.target.id);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (ciclo != 0 && centroAcopio != -1) {
      setTotalesKgs(0);
      setTotalesInventario(0);
      // for future use
      // setTotalesKgsEnt(0);
      // setTotalesKgsDis(0);
      setTotalesVendidos(0);
      setTotalesEnProceso(0);
      setTotalesRechazados(0);

      setKgsOrgConv([]);
      setKgsCatCert([]);

      setKgsPorSemana([]);
      setKgsPorMes([]);

      setTotalesEfectivo(null);

      setBlocking(true);

      Api.Acopio.get(`dashboard/${ciclo}/${auth.user.id_cooperativa}/${centroAcopio}`)
        .then(response => {
          if (!isMounted) return;
          // console.log('response', response);
          const entregas = response.data.entregas;
          setTotalesKgs(entregas.kg_totales);
          setTotalesInventario(entregas.kg_en_inventario);
          // for future use
          // setTotalesKgsEnt(entregas.kg_en_bodega);
          // setTotalesKgsDis(entregas.kg_en_centro_acopio);
          setTotalesVendidos(entregas.kg_vendidos);
          setTotalesEnProceso(entregas.kgs_en_proceso);
          setTotalesRechazados(entregas.kg_en_rechazo);

          // ORG CONVENCIONAL
          const kgsCategorias = [
            { label: 'Orgánico', value: entregas.kgs_organicos, value2: entregas.kgs_estimados_pendientes, fill: '#508b08' },
            { label: 'Convencional', value: entregas.kgs_convencional, fill: '#de7804' },
            { label: 'Transición', value: entregas.kgs_transicion, fill: '#934E00' },
          ];
          setKgsOrgConv(kgsCategorias);

          // CERTIFICACIONES
          const dataCatCert = response.data.certificaciones.map(cert => {
            cert.label == 'Orgánico' && (cert.fill = '#508b08');
            return cert;
          });
          setKgsCatCert(dataCatCert);

          // PER WEEK MONTH
          setKgsPorSemana(response.data.per_week);
          setKgsPorMes(response.data.per_month);

          const efectivo = response.data.efectivo;
          setTotalesEfectivo({
            pagado_a_productores: efectivo.pagado_prod,
            pagado_esta_semana: efectivo.pagos_this_week,
            deuda_a_productores: efectivo.saldo,
          });

          const anticipos = response.data.anticipos;
          const anticipos_prod = anticipos.total;
          const anticipos_rec = anticipos.total_recuperados;
          if (getIdCatCreditoPrecosecha() > 2) {
            setBlockingCreditos(true);
            Api.Acopio.search('anticipos', {
              filter: [{ column: 'id_ciclo', operator: '=', value: ciclo }],
            })
              .then(response => {
                if (!isMounted) return;
                const interesCoop = coopConfig.catCreditoPrecosecha()?.interes / 100;
                const summedInterest = response.data.reduce((acc, anticipo) => {
                  // RETURN DATA WITH DEBT CALCULATION
                  const interes = anticipo.tasa_interes && +anticipo.tasa_interes >= 0 ? +anticipo.tasa_interes : +interesCoop;
                  const debtCalc = new DebtCalculator({
                    credito: anticipo,
                    interestRate: interes * 100,
                    pagos: anticipo.anticipo_pagos,
                    stringDate: null,
                  });
                  const calc = debtCalc.getDebt();
                  return acc + calc.debtToday.totalInterest / 100;
                }, 0);
                setTotalIntereses(summedInterest);
                setBlockingCreditos(false);

                setAvanceAnticipos(parseInt(anticipos_prod != 0 ? (anticipos_rec * 100) / (anticipos_prod + summedInterest) : 0));
                setTotalAnticipoRecuperado(anticipos_rec);
                setTotalAnticipoOtorgado(anticipos_prod + summedInterest);
              })
              .catch(e => {
                // eslint-disable-next-line no-console
                console.log('error: ', e);
                setBlockingCreditos(false);
              });
          } else {
            setAvanceAnticipos(parseInt(anticipos_prod != 0 ? (anticipos_rec * 100) / anticipos_prod : 0));
            setTotalAnticipoRecuperado(anticipos_rec);
            setTotalAnticipoOtorgado(anticipos_prod);
          }

          setBlocking(false);
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log('e', e);
          setBlocking(false);
        });
    }
    return () => {
      isMounted = false;
    };
  }, [ciclo, centroAcopio]);

  const getOtorgadoLabel = () => {
    const interest = getIdCatCreditoPrecosecha() > 2 ? '+ intereses' : '';
    return `Capital otorgado de ${transformWord(etiquetaAnticipos, ['lowercase', 'plural'])} ${interest}`;
  };

  return (
    <div>
      <div className="row justify-content-between mr-2 mb-1">
        <SelectCiclo
          setFilter={evt => {
            setFilterCiclo(evt);
          }}
          className="mr-2"
          acopio
        />
        {usaCentrosAcopios() ? (
          <SelectCentroAcopio
            setFilter={evt => {
              setFilterCentroA(evt);
            }}
            className=""
          />
        ) : null}
      </div>

      <div className="row">
        <div className="col-xl-4">
          <BlockUi tag="div" message="" blocking={blocking} renderChildren={true} className="mr-3">
            <Card
              height={100}
              label={coopConfig.acopioUnits()?.abreviacion_plural + ' totales entregados'}
              value={totalesKgs.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              image={env.images.acopio.disponible}
              imageStyle={{ width: '36px', height: '36px' }}
            />
          </BlockUi>
          <BlockUi tag="div" message="" blocking={blocking} renderChildren={true} className="mr-3">
            <Card
              height={coopConfig.mostrarDashboardRechazados() ? 91 : 100}
              label={coopConfig.acopioUnits()?.abreviacion_plural + ' en inventario'}
              value={totalesInventario.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              image={env.images.acopio.disponible}
              imageStyle={{ width: '36px', height: '36px' }}
            />
          </BlockUi>
          <BlockUi tag="div" message="" blocking={blocking} renderChildren={true} className="mr-3">
            <Card
              height={coopConfig.mostrarDashboardRechazados() ? 91 : 100}
              label={coopConfig.acopioUnits()?.abreviacion_plural + ' en proceso de transformación'}
              value={totalesEnProceso.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              image={env.images.acopio.disponible}
              imageStyle={{ width: '36px', height: '36px' }}
            />
          </BlockUi>
          {coopConfig.mostrarDashboardRechazados() ? (
            <BlockUi tag="div" message="" blocking={blocking} renderChildren={true} className="mr-3">
              <Card
                height={91}
                label={coopConfig.acopioUnits()?.abreviacion_plural + ' rechazados'}
                value={totalesRechazados.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                image={env.images.acopio.rechazado}
                imageStyle={{ width: '36px', height: '36px' }}
              />
            </BlockUi>
          ) : null}
          <BlockUi tag="div" message="" blocking={blocking} renderChildren={true} className="mr-3">
            <Card
              height={coopConfig.mostrarDashboardRechazados() ? 91 : 100}
              label={coopConfig.acopioUnits()?.abreviacion_plural + ' vendidos'}
              value={totalesVendidos.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              image={env.images.acopio.entregado}
              imageStyle={{ width: '36px', height: '36px' }}
            />
          </BlockUi>
        </div>
        <div className="col-xl-8">
          <div
            className="row card-graph mb-3"
            style={{
              minHeight: coopConfig.mostrarDashboardRechazados() ? 512 : 434,
              backgroundColor: 'white',
              borderRadius: '20px',
              padding: '20px',
            }}>
            <div className="col">
              {/* Main chart entregas vs acopiado */}
              <div className="row">
                <div className="col">
                  <h6 className="font-lato-normal-400 text-center">
                    {coopConfig.acopioUnits()?.abreviacion_plural}. totales entregados de {producto} orgánico y {convencLabel}
                  </h6>
                  <ResponsiveContainer width={'100%'} height={160}>
                    <BarChart data={kgsOrgConv.filter(kg => kg.value > 0)} layout="vertical" stackOffset="sign">
                      <XAxis hide type="number" />
                      <YAxis hide dataKey="label" type="category" axisLine={false} angle={-90} tickLine={false} />
                      <Bar dataKey="value" fill="#508b08" stackId="a" radius={[10, 0, 0, 10]} maxBarSize={60} />
                      <Bar dataKey="value2" fill="#f4efd8" stackId="a" radius={[0, 10, 10, 0]} maxBarSize={60}>
                        {kgsOrgConv
                          .filter(kg => kg.value > 0)
                          .map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={'#f4efd8'} />
                          ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
              {kgsOrgConv.length > 1 ? (
                <>
                  <div className="row mb-2">
                    <div className="col" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '15px' }}>
                      <TotalItemChart
                        title={kgsOrgConv.find(k => k.label == 'Orgánico')}
                        subtitle={coopConfig.acopioUnits()?.abreviacion_plural + ' entregados orgánico'}
                        backgroundColor="#508b08"
                      />
                    </div>
                    <div className="col" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '15px' }}>
                      <TotalItemChart
                        title={kgsOrgConv.find(k => k.label == 'Convencional')}
                        subtitle={coopConfig.acopioUnits()?.abreviacion_plural + ' entregados ' + convencLabel}
                        backgroundColor="#de7804"
                      />
                    </div>
                    <div className="col" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '15px' }}>
                      <TotalItemChart
                        title={kgsOrgConv.find(k => k.label == 'Transición')}
                        subtitle={coopConfig.acopioUnits()?.abreviacion_plural + ' entregados transición'}
                        backgroundColor="#934E00"
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '15px' }}>
                      <TotalItemChart
                        title={kgsOrgConv.find(k => k.label == 'Orgánico')}
                        subtitle={coopConfig.acopioUnits()?.abreviacion_plural + ' estimados por entregar'}
                        backgroundColor="#f4efd8"
                        valueKey="value2"
                      />
                    </div>
                    <div className="col" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '15px' }}>
                      <TotalItemChart
                        title={(
                          +kgsOrgConv.find(k => k.label == 'Orgánico').value + +kgsOrgConv.find(k => k.label == 'Orgánico').value2
                        ).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                        subtitle={coopConfig.acopioUnits()?.abreviacion_plural + ' estimados totales'}
                        backgroundColor="#ffffff"
                        value="value2"
                        fullTitle={true}
                      />
                    </div>
                  </div>
                  {/* Categorias/Certificaciones chart */}
                  <div className="row">
                    <div className="col">
                      <AcopioKgsCategorias kgsCatCert={kgsCatCert} />
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className={getIdCatCreditoPrecosecha() > 1 ? 'col-xl-5 pr-4' : 'col-xl-8 pr-4'}>
          <div className="row card-graph mb-3" style={{ height: 420, backgroundColor: 'white', borderRadius: '20px', padding: '20px' }}>
            <h6 className="font-lato-normal-400 text-center">{`${coopConfig.acopioUnits()?.abreviacion_plural}. entregados por ${
              porMes ? 'Mes' : 'Semana'
            }`}</h6>
            <a
              onClick={() => setPorMes(prev => !prev)}
              style={{ color: env.colors.primary_500, fontSize: 14 }}
              className="float-right btn btn-link">
              {porMes ? 'ver por semana' : 'ver por mes'}
            </a>
            <ResponsiveContainer width="100%" height={340}>
              <BarChart data={porMes ? kgsPorMes : kgsPorSemana}>
                <CartesianGrid horizontal={false} vertical={false} strokeDasharray="3 3" />
                <XAxis angle={0} dataKey={'label'} />
                <YAxis tick={false} hide padding={{ bottom: 10 }} />
                {/* <Tooltip /> */}
                <Legend />

                <Bar legendType={'none'} name="Entregado" radius={12} barSize={150} dataKey="value" isAnimationActive={false}>
                  <LabelList
                    position={'insideBottom'}
                    style={{ textAnchor: 'middle', fontSize: '16', fill: 'white', textShadow: '0px 0px 3px rgba(0,0,0,0.8)' }}
                    offset={20}
                    formatter={v => v.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className={getIdCatCreditoPrecosecha() > 1 ? 'col-xl-3 pr-4' : 'col-xl-4 pr-4'}>
          <BlockUi tag="div" message="" blocking={blocking} renderChildren={true} className="mr-2">
            <div className="row card-graph  mb-3" style={{ height: 420, backgroundColor: 'white', borderRadius: '20px', padding: '20px' }}>
              <h6 className="font-lato-normal-400 text-center">Pagos</h6>
              <Card
                height={90}
                label={`Pagado en total a productores por sus entregas de ${producto}`}
                value={
                  !isNaN(totalesEfectivo?.pagado_a_productores)
                    ? coopConfig.currency()?.symbol +
                      Number(totalesEfectivo.pagado_a_productores).toLocaleString('en-US', {
                        currency: 'USD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      })
                    : ''
                }
                image={env.images.icon.money}
                imageStyle={{ width: '36px', height: '36px' }}
              />
              <div className="ml-5">
                <Card
                  height={90}
                  label="en esta semana"
                  value={
                    !isNaN(totalesEfectivo?.pagado_esta_semana)
                      ? coopConfig.currency()?.symbol +
                        Number(totalesEfectivo.pagado_esta_semana).toLocaleString('en-US', {
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        })
                      : ''
                  }
                />
              </div>
              <Card
                height={90}
                label="Se deben en total en pago a productores"
                value={
                  !isNaN(totalesEfectivo?.deuda_a_productores)
                    ? coopConfig.currency()?.symbol +
                      Number(totalesEfectivo.deuda_a_productores).toLocaleString('en-US', {
                        currency: 'USD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      })
                    : ''
                }
                image={env.images.icon.moneyRed}
                imageStyle={{ width: '36px', height: '36px' }}
              />
            </div>
          </BlockUi>
        </div>
        {getIdCatCreditoPrecosecha() > 1 && (
          <div className="col-xl-4">
            <BlockUi tag="div" message="" blocking={blocking && blockingCreditos} renderChildren={true}>
              <div
                className="row card-graph mb-3"
                style={{
                  height: getIdCatCreditoPrecosecha() > 2 ? 290 : 400,
                  backgroundColor: 'white',
                  borderRadius: '20px 20px 20px 20px',
                  padding: '20px',
                }}>
                <h6 className="font-lato-normal-400 text-center">{transformWord(etiquetaAnticipos, ['plural', 'capitalize'])}</h6>
                {avanceAnticipos != null && (
                  <div className="col-xl-12 col-md-12">
                    <h1 className="font-lato-normal-600 halfdonut-percent">
                      {avanceAnticipos.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}%
                    </h1>
                    <HalfDonut value={avanceAnticipos} color={'#BC8268'} width={250}></HalfDonut>
                  </div>
                )}
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-6 col-md-6">
                      <CardColor
                        height={120}
                        label={transformWord(etiquetaAnticipos, ['plural', 'capitalize']) + ' recuperados'}
                        value={
                          coopConfig.currency()?.symbol +
                          (totalAnticipoRecuperado ? totalAnticipoRecuperado : 0).toLocaleString('en-US', {
                            currency: 'USD',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        }
                        color="#E49D2A"
                        mode={'onlyData'}
                        size={'18px'}
                        style={{ textAlign: 'center' }}
                      />
                    </div>
                    <div className="col-xl-6 col-md-6">
                      <CardColor
                        height={120}
                        label={getOtorgadoLabel()}
                        value={
                          coopConfig.currency()?.symbol +
                          (totalAnticipoOtorgado ? totalAnticipoOtorgado : 0).toLocaleString('en-US', {
                            currency: 'USD',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        }
                        color="#4A6700"
                        mode={'onlyData'}
                        size={'18px'}
                        style={{ textAlign: 'center' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </BlockUi>
            {getIdCatCreditoPrecosecha() > 2 && (
              <BlockUi tag="div" message="" blocking={blocking && blockingCreditos} renderChildren={true}>
                <div className="row card-graph" style={{ backgroundColor: 'white', borderRadius: '20px', padding: '20px' }}>
                  <div className="col-xl-5 col-md-5">
                    <CardColor
                      height={60}
                      label="capital inicial"
                      value={(totalAnticipoOtorgado ? +totalAnticipoOtorgado - +totalIntereses : 0).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                      mode={'onlyData'}
                      size={'18px'}
                      style={{ textAlign: 'center' }}
                    />
                  </div>
                  <div className="col-xl-2 col-md-2 mt-4">
                    <p>+</p>
                  </div>
                  <div className="col-xl-5 col-md-5">
                    <CardColor
                      height={60}
                      label="intereses generados"
                      value={totalIntereses.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                      mode={'onlyData'}
                      size={'18px'}
                      style={{ textAlign: 'center' }}
                    />
                  </div>
                </div>
              </BlockUi>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AcopioResume;
