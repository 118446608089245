import React from 'react';
import IconCheck from '../icon/icon-check';
import IconWrong from '../icon/icon-wrong';
import IconAlert from '../icon/icon-alert';

const LabelEudr = ({ riesgo }) => {
  if (!riesgo) return null;

  let icon = riesgo;
  if (riesgo === 'BAJO') {
    icon = <IconCheck />;
  }
  if (riesgo === 'ALTO') {
    icon = <IconWrong />;
  }
  if (riesgo === 'MEDIO') {
    icon = <IconAlert />;
  }

  return <p className="text-center">{icon}</p>;
};

export default LabelEudr;
