import React, { useState, useRef, useCallback,useEffect, createRef } from "react";
import { Link } from "react-router-dom";
import CardContainer from "../../components/card/card-container.jsx";
import { useAuth } from "../../components/content/auth.jsx";
import SelectCiclo from "../../components/select/select-ciclo.jsx";
import { useHistory } from "react-router-dom";
import { useCoopConfig } from "../../components/content/coop-config.jsx";
import GraficaSensorial from "./components/grafica-sensorial.jsx";
import { useReactToPrint } from 'react-to-print';
import Api from "../../api/global.service";
import {getCalidadLS} from "./components/helpers.js";
import LinkDownload from '../../components/link/link-download.jsx';

const pageStyle = `
  @page {
    size: 80mm 50mm;
  }

  @media print {
    .pagebreak {
      clear: both;
      page-break-after: always !important;
    }

    tr  {
      border: 2px solid #000 !important;
      padding: 5px 10px !important;
    }

    td, th  {
      font-size: 10px !important;
      padding: 5px 10px !important;
    }

  }
`;

const CalidadAnalisisSensorialInfo = () => {
  const MSG_REQUIRED = "Información obligatoria";
  const queryParameters = new URLSearchParams(window.location.search)
  const history = useHistory();
  const auth = useAuth();
  const coopConfig = useCoopConfig();
  const [data, setData] = useState([]);
  const [ciclo, setCiclo] = useState({});
  const [blocking, setBlocking] = useState(false);
  const componentRef = useRef(null);
  const [nameToPrint, setNameToPrint] = useState('AnalisisSensorial');
  const [dataSensorial, setDataSensorial] = useState([]);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: nameToPrint,
    //onAfterPrint: useCallback(() => history.goBack(), [history]),
    removeAfterPrint: true,
    copyStyles: true,
    pageStyle: pageStyle,
  });

  
  const getData = (id_calidad_analisis_sensorial) => {
    Api.get('calidad/analisis/sensorial',id_calidad_analisis_sensorial).then((response) => {
        setBlocking(false);
        if (typeof response.data !== "undefined") {
          setDataSensorial(response.data[0]);
        }
        setBlocking(false);
      })
      .catch((e) => {
        console.log("e", e);
        setBlocking(false);
      });
  };


  useEffect(() => {
    let id_calidad_analisis_sensorial = getCalidadLS("id_calidad_analisis_sensorial");
    if(id_calidad_analisis_sensorial.length > 0){
      getData(id_calidad_analisis_sensorial);
    }
  },[]);


  
  return (
    <div>
      <div className="row justify-content-between">
        <button
          className="btn btn-outline-dark mt-3"
          type="button"
          onClick={() => history.push({pathname: '/admin/calidad/analisis/sensorial/registro',})}
           >
          <i className="fa fa-arrow-left"></i>
          {" Volver a registro"}
        </button>
        <div className="float-right">
          <LinkDownload url={`calidad/analisis/recibo/descargar/${getCalidadLS("id_calidad_analisis")}`} label={<button className="btn btn-outline-secondary ml-2" style={{ borderRadius: '20px 20px 20px 20px' }} type="button">Exportar PDF</button>}></LinkDownload>
        </div>
      </div>

      <CardContainer height={"100%"} style={{ marginTop: 10 }}>
        <div className="row" ref={componentRef}>

        <div className="col-md-12 col-xl-12">
        <h4>{"Registro de análisis sensorial"}</h4>
          </div>

          <div className="col-md-6 col-xl-6">
            
          <div className="row mt-3">
            <div className="col-xs-12 col-xl-12">
                {"Perfil de la taza"}
              </div>
              <div className="col-md-12 col-xl-12">
                <span>{dataSensorial.perfil_taza}</span>
              </div>

          </div>


          <div className="row mt-2">
            <div className="col-xs-4 col-xl-2">
                <span>{'Fragancia / aroma'}</span>
              </div>
              <div className="col-xs-4 col-xl-2">
                <span>{dataSensorial.fragancia_aroma}</span>
              </div>
              <div className="col-xs-4 col-xl-8">
              <span>{dataSensorial.fragancia_aroma_desc}</span>
              </div>
          </div>

          <div className="row mt-2">
            <div className="col-xs-4 col-xl-2">
                <span>{'Sabor'}</span>
              </div>
              <div className="col-xs-2 col-xl-2">
              <span>{dataSensorial.sabor}</span>
              </div>
              <div className="col-xs-4 col-xl-8">
              <span>{dataSensorial.sabor_desc}</span>
              </div>
          </div>

          <div className="row mt-2">
            <div className="col-xs-4 col-xl-2">
                <span>{'Sabor residual'}</span>
              </div>
              <div className="col-xs-2 col-xl-2">
              <span>{dataSensorial.sabor_residual}</span>
              </div>
              <div className="col-xs-4 col-xl-8">
              <span>{dataSensorial.sabor_residual_desc}</span>
              </div>
          </div>

          <div className="row mt-2">
            <div className="col-xs-4 col-xl-2">
                <span>{'Acidez'}</span>
              </div>
              <div className="col-xs-2 col-xl-2">
              <span>{dataSensorial.acidez}</span>
              </div>
              <div className="col-xs-4 col-xl-8">
              <span>{dataSensorial.acidez_desc}</span>
              </div>
          </div>


          <div className="row mt-2">
            <div className="col-xs-4 col-xl-2">
                <span>{'Cuerpo'}</span>
              </div>
              <div className="col-xs-2 col-xl-2">
              <span>{dataSensorial.cuerpo}</span>
              </div>
              <div className="col-xs-4 col-xl-8">
              <span>{dataSensorial.cuerpo_desc}</span>
              </div>
          </div>

          <div className="row mt-2">
            <div className="col-xs-4 col-xl-2">
                <span>{'Uniformidad'}</span>
              </div>
              <div className="col-xs-2 col-xl-2">
              <span>{dataSensorial.uniformidad}</span>
              </div>
              <div className="col-xs-4 col-xl-8">
              <span>{dataSensorial.uniformidad_desc}</span>
              </div>
          </div>

          <div className="row mt-2">
            <div className="col-xs-4 col-xl-2">
                <span>{'Balance'}</span>
              </div>
              <div className="col-xs-2 col-xl-2">
              <span>{dataSensorial.balance}</span>
              </div>
              <div className="col-xs-4 col-xl-8">
              <span>{dataSensorial.balance_desc}</span>
              </div>
          </div>

          <div className="row mt-2">
            <div className="col-xs-4 col-xl-2">
                <span>{'Taza limpia'}</span>
              </div>
              <div className="col-xs-2 col-xl-2">
              <span>{dataSensorial.taza_limpia}</span>
              </div>
              <div className="col-xs-4 col-xl-8">
              <span>{dataSensorial.taza_limpia_desc}</span>
              </div>
          </div>

          <div className="row mt-2">
            <div className="col-xs-4 col-xl-2">
                <span>{'Dulzor'}</span>
              </div>
              <div className="col-xs-2 col-xl-2">
              <span>{dataSensorial.dulzor}</span>
              </div>
              <div className="col-xs-4 col-xl-8">
              <span>{dataSensorial.dulzor_desc}</span>
              </div>
          </div>

          <div className="row mt-2">
            <div className="col-xs-4 col-xl-2">
                <span>{'Catador'}</span>
              </div>
              <div className="col-xs-2 col-xl-2">
              <span>{dataSensorial.catador}</span>
              </div>
              <div className="col-xs-4 col-xl-8">
              <span>{dataSensorial.catador_desc}</span>
              </div>
          </div>

            <div className="row mt-5">
            <div className="col-xs-12 col-xl-12">
                {'Observaciones'}
          </div>
          <div className="col-xs-12 col-xl-12">
          <span>{dataSensorial.descripcion}</span>
          </div>
        </div>
          </div>

          
          <div className="col-md-6 col-xl-6">
              <GraficaSensorial data={dataSensorial} />
          </div>
        </div>

        <div className="row mt-5">

          <div className="col-md-3">
            <Link
              style={{
                borderRadius: "20px 20px 20px 20px",
                color: "#333",
                fontWeight: "normal",
                fontSize: 16,
              }}
              className="btn btn-sirio"
              to="/admin/calidad"
            >
              Guardar
            </Link>
          </div>

        </div>
      </CardContainer>
    </div>
  );
};

export default CalidadAnalisisSensorialInfo;
