import React, { useEffect, useState, useMemo } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import CardContainer from '../../components/card/card-container.jsx';
import LabelNumber from '../../components/label/label-number.jsx';
import Api from '../../api/global.service';

import NoPagination from '../../components/pagination/noPagination.jsx';
import SearchColumn from '../../components/search/search-column.jsx';
import FormSelectMaterial from '../../nuup/componentes/Forms/FormSelectMaterial.jsx';
import transformWord from '../../utils/transformWord.js';
import useEtiquetas from '../../hooks/useEtiquetas.jsx';
import xlsx from 'json-as-xlsx';
import moment from 'moment';
import BlockUi from 'react-block-ui';
import env from '../../environment.js';
import { useCoopConfig } from '../../components/content/coop-config.jsx';
import LabelEudr from '../../components/label/label-eudr.jsx';
import insertIf from '../../utils/insertIf.js';
import IDs_WHISP from '../../constants/IdsWhisp.js';
import SelectCiclo from '../../components/select/select-ciclo.jsx';

const FILTER = {
  ALL: 0,
  GEO_ONLY: 1,
  NON_GEO: 2,
};

const ParcelasList = () => {
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [geoData, setGeoData] = useState([]);
  const [geoFilter, setGeoFilter] = useState(0);
  const [blocking, setBlocking] = useState(true);
  const [ciclo, setCiclo] = useState({});
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const { etiquetas } = useEtiquetas();
  const coopConfig = useCoopConfig();

  const getGeolicalizedLabel = (poligono, centroide) => {
    if (poligono == 1) return 'polígono';
    if (centroide == 1) return 'centroide';
    return 'sin geolocalizar';
  };

  const getRiesgoEudrLabel = (poligono, id_riesgo_eudr_user, alertas_poligono_count, id_riesgo_eudr_whisp, areas_naturales_count) => {
    if (poligono != 1) return null;
    if (id_riesgo_eudr_user) {
      if (id_riesgo_eudr_user == IDs_WHISP.ALTO) return 'ALTO';
      if (id_riesgo_eudr_user && id_riesgo_eudr_user == IDs_WHISP.BAJO) return 'BAJO';
      return 'MEDIO';
    }

    if (id_riesgo_eudr_whisp == IDs_WHISP.ALTO) return 'ALTO';
    if (id_riesgo_eudr_whisp == IDs_WHISP.BAJO && alertas_poligono_count == 0 && areas_naturales_count == 0) return 'BAJO';
    return 'MEDIO';
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        sortable: false,
        width: 65,
      },
      {
        Header: 'PRODUCTOR',
        accessor: 'cuenta',
        sortable: true,
        Cell: ({ row, value }) => (
          <Link to={{ pathname: `/admin/cuentas/perfil/${row.original.cuenta_uuid}` }} className="m-r-2" style={{ color: '#789D16' }}>
            <span>{value}</span>
          </Link>
        ),
      },
      {
        Header: 'NOMBRE ' + transformWord(etiquetas?.parcela, ['uppercase']),
        accessor: 'nombre',
        sortable: true,
        Cell: ({ row, value }) => (
          <Link to={{ pathname: `/admin/parcelas/perfil/${row.original.uuid}` }} className="m-r-2" style={{ color: '#789D16' }}>
            <span>{value}</span>
          </Link>
        ),
      },
      {
        Header: 'GEOLOCALIZADA',
        accessor: 'geoLabel',
        sortable: true,
      },
      {
        Header: 'MUNICIPIO',
        accessor: 'municipio',
        sortable: true,
      },
      {
        Header: 'COMUNIDAD',
        accessor: 'localidad',
        sortable: true,
      },
      {
        Header: 'SUPERFICIE (HA)',
        accessor: 'superficie',
        sortable: true,
        Cell: ({ value }) => {
          return <LabelNumber value={value} digits={2}></LabelNumber>;
        },
      },
      {
        Header: transformWord(etiquetas?.planta, ['plural', 'uppercase']) + ' TOTALES',
        accessor: 'plantas',
        sortable: true,
        Cell: ({ value }) => {
          return <LabelNumber value={value}></LabelNumber>;
        },
      },
      {
        Header: 'ALERTAS POLÍGONO',
        accessor: 'alertas_poligono',
        sortable: true,
        minWidth: 80,
      },
      {
        Header: 'RIESGO EUDR',
        accessor: 'riskLabel',
        sortable: true,
        minWidth: 80,
        show: !!+coopConfig.config?.usa_eudr,
        Cell: ({ value }) => <LabelEudr riesgo={value} />,
      },
    ],
    [etiquetas]
  );

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'cuenta', label: 'PRODUCTOR' },
      { value: 2, target: 'nombre', label: 'NOMBRE PARCELA' },
      { value: 2, target: 'geoLabel', label: 'GEOLOCALIZADA' },
      { value: 3, target: 'municipio', label: 'MUNICIPIO' },
      { value: 4, target: 'localidad', label: 'LOCALIDAD' },
      { value: 5, target: 'superficie', label: 'SUPERFICIE (HA)' },
      { value: 6, target: 'plantas', label: transformWord(etiquetas?.planta, ['plural', 'uppercase']) + ' TOTALES' },
      // { value: 7, target: 'variedades', label: 'VARIEDADES' },
      { value: 7, target: 'alertas_poligono', label: 'ALERTAS POLÍGONO' },
      ...insertIf(+coopConfig.config?.usa_eudr, {
        value: 8,
        target: 'riskLabel',
        label: 'RIESGO EUDR',
      }),
      // { value: 6, target: 'ciclo', label: 'CICLO' },
    ],
    []
  );

  useEffect(() => {
    const getData = async id_ciclo => {
      setBlocking(true);
      Api.search('parcelas_all', {
        sort: [{ column: 'cuenta', sort: 'asc' }],
        filter: [{ column: 'id_ciclo', operator: '=', value: id_ciclo }],
      })
        .then(response => {
          const updatedData = response.data.map(item => {
            const geoLabel = getGeolicalizedLabel(item.poligono, item.centroide);
            const riskLabel = getRiesgoEudrLabel(
              item.poligono,
              item.id_riesgo_eudr_user,
              item.alertas_poligono_count,
              item.id_riesgo_eudr_whisp,
              item.areas_naturales_count
            );
            return { ...item, geoLabel, riskLabel };
          });
          setOriginalData(updatedData);
          const geoParam = searchParams.get('geoFilter');
          filterGeo(geoParam, updatedData);
          setBlocking(false);
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log('error: ', e);
          setBlocking(false);
        });
    };
    if (ciclo.id) {
      getData(ciclo.id);
    }
  }, [ciclo]);

  const filterGeo = (filter, recievedData = null) => {
    const data = recievedData ? recievedData : originalData;
    if (filter == FILTER.GEO_ONLY) {
      setGeoData(data.filter(item => item.poligono == 1));
      setData(data.filter(item => item.poligono == 1));
      setGeoFilter(FILTER.GEO_ONLY);
      searchParams.set('geoFilter', FILTER.GEO_ONLY);
      history.replace({
        search: searchParams.toString(),
      });
    } else if (filter == FILTER.NON_GEO) {
      setGeoData(data.filter(item => item.poligono != 1));
      setData(data.filter(item => item.poligono != 1));
      setGeoFilter(FILTER.NON_GEO);
      searchParams.set('geoFilter', FILTER.NON_GEO);
      history.replace({
        search: searchParams.toString(),
      });
    } else {
      setGeoData(data);
      setData(data);
      setGeoFilter(FILTER.ALL);
      history.replace({
        search: '',
      });
    }
  };

  const exportarXlsx = event => {
    event.preventDefault();
    const xlsData1 = [
      {
        sheet: 'Parcelas',
        columns: [
          { label: 'ID', value: 'id' },
          { label: 'PRODUCTOR', value: 'cuenta' },
          { label: 'NOMBRE ' + transformWord(etiquetas?.parcela, ['uppercase']), value: 'nombre' },
          { label: 'GEOLOCALIZADA', value: 'geoLabel' },
          { label: 'MUNICIPIO', value: 'municipio' },
          { label: 'COMUNIDAD', value: 'localidad' },
          { label: 'SUPERFICIE (HA)', value: row => +row.superficie },
          { label: transformWord(etiquetas?.planta, ['plural', 'uppercase']) + ' TOTALES', value: row => +row.plantas },
          { label: 'ALERTAS POLÍGONO', value: 'alertas_poligono', maxWidth: 150 },
          ...insertIf(+coopConfig.config?.usa_eudr, { label: 'RIESGO EUDR', value: 'riskLabel' }),
        ],
        content: data,
      },
    ];

    const settings = {
      fileName: `${transformWord(etiquetas?.parcela, ['uppercase'])}_${moment().format('YYYYMMDD_HHmmss')}`,
    };
    xlsx(xlsData1, settings);
  };

  return (
    <div>
      <div className="row justify-content-between">
        <div className="col-6">
          <div className="d-flex">
            <Link to="/dashboard" className="btn m-r-2">
              <i style={{ color: '#789D16' }} className="fa fa-arrow-left"></i> <span style={{ color: '#789D16' }}>Volver al inicio</span>
            </Link>
            <SelectCiclo setFilter={evt => setCiclo(evt?.target)} />
          </div>
        </div>
        <div className="col-3">
          <div className="d-flex justify-content-end">
            <button onClick={e => exportarXlsx(e)} className={`btn m-r-2`} type="button">
              <img src={env.images.icon.xls} className="pr-1" />
              <span style={{ color: '#789D16', fontSize: '0.76rem' }}>Descargar Excel</span>
            </button>
          </div>
        </div>
      </div>
      <CardContainer height={'100%'}>
        <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
          <div className="row justify-content-between">
            <div className="col-8">
              <SearchColumn data={geoData} columns={searchColumns} setDataFilter={evt => setData(evt)} clearSearchItem={geoFilter} />
            </div>
            <div className="col-4">
              <FormSelectMaterial
                id="geolocalizado"
                error={false}
                label="Geo"
                value={geoFilter}
                onChange={filterGeo}
                height={30}
                options={[
                  { value: 1, label: 'Sólo geolocalizadas' },
                  { value: 2, label: 'Sólo pendientes de geolocalizar' },
                ]}
                showAll={true}
                showAllLabel="Todas"
              />
            </div>
          </div>
        </BlockUi>

        <NoPagination blocking={blocking} columns={columns} data={data}></NoPagination>
      </CardContainer>
    </div>
  );
};

export default ParcelasList;
