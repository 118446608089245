
import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import Api from '../../../../api/global.service.js';
import 'react-datepicker/dist/react-datepicker.css';
import * as yup from "yup";
import { publish } from '../../../../components/events.js';
import FormSelectMaterial from "../../../../nuup/componentes/Forms/FormSelectMaterial.jsx";

const ModalAsignarLote = forwardRef((props, ref) => {
	const lotes = [{label: "Sin asignar", value: "-1"}, ...props.lotes] || []
	const [show, setShow] = useState(false);
	const [blocking, setBlocking] = useState(false);
	const [idCiclo, setIdCiclo] = useState(0);
	const [idLote, setIdLote] = useState(0);

	const [errors, setErrors] = useState(null);
	const schema = yup.object().shape({
		id_lote: yup.number()
			.integer("Debe seleccionar un lote")
			.transform((value) => (isNaN(value) ? undefined : value))
			.required("Debe seleccionar un lote")
	})

	//To call outside
	useImperativeHandle(ref, () => ({
		show: showHandler,
		setIdCiclo: (idCiclo) => setIdCiclo(idCiclo)
	}));

	useEffect(() => {

	}, []);


	const showHandler = () => {
		setShow(true);
		setErrors(null);
	}

	const closeHandler = () => {
		setShow(false);
	}

	const validateHandler = () => {

		var data = {
			id_lote: idLote
		}
		var that = this;
		schema.validate(data, { abortEarly: false })
			.then(function (valid) {
				//Datos validos
				const data = {
					id_lote: idLote,
					entregas: props.entregas
				}

				Api.Acopio.create('lotes_asignacion', data).then(response => {
					if (response.data.success) {
						setBlocking(false);
						setShow(false);
						publish('LoteAsignado::onSave', { ...response.data.data, id_ciclo: idCiclo });
					} else {
						let err = response.data.data;
						setErrors(err);
					}
				})
					.catch(e => {
						setBlocking(false);
					});

			})
			.catch(function (err) {
				let errors = err.inner.reduce(function (map, obj) {
					map[obj.path] = obj.message;
					return map;
				}, {});
				setErrors(errors);
			});

	}




	return (
		<div className="modal" style={{ display: (show) ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.2)' }}>
			<div className="modal-dialog" style={{ top: 0, right: 25, position: 'absolute', width: 400, height: 'calc(100% - 50px)' }}>
				<div className="modal-content" style={{ height: '100%' }}>
					<div className="modal-header" style={{ flex: 'inherit' }}>
						<h4 className="modal-title">{props.title}</h4>
						<button onClick={() => closeHandler()} type="button" className="btn" style={{ borderRadius: '20px 20px 20px 20px' }}>
							<i className="fa fa-times"></i>
						</button>
					</div>
					<div className="modal-body">
						<div className="row my-4">
							<div className="col-12 acopio-lote-asignacion-div">
								<p className='acopio-lote-asignacion-desc'>{'Asigna o cambia el lote a una o varias entregas'}</p>
							</div>
							<div className="col-12">
								<FormSelectMaterial id="lote" error={errors?.id_lote} label={'Lote'} value={idLote} onChange={setIdLote} options={lotes} showAll={true} showAllLabel='Elige un lote' />
							</div>
							<div className="col-12">
								<span>Selecciona una opción</span>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						{blocking && (
							<div className="spinner-border text-warning" role="status">
								<span className="sr-only">Guardando...</span>
							</div>
						)}
						{!blocking && (
							<>
								<button onClick={() => closeHandler()} type="button" className="btn btn-outline-secondary" style={{ borderRadius: '20px 20px 20px 20px' }}>
									<i className="fa fa-times"></i> Cancelar
								</button>
								<button onClick={() => validateHandler()} type="button" className="btn btn-outline-secondary btn-sirio" style={{ borderRadius: '20px 20px 20px 20px' }}>
									<i className="fa fa-save"></i> Guardar
								</button>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);

});

export default ModalAsignarLote;