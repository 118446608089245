import React from 'react';
import env from '../../environment';
import './card-title-parcela.css';

const CardTitleParcela = ({ cuenta_uuid = null, cuenta_name, parcela_name, localidad, municipio, certifications }) => {
  return (
    <>
      <div className="col-xs-3 col-md-3">
        <img src={env.images.icon.parcela} className="parcela-icon" />
        {/* <img src={env.images.icon.parcela} style={{ width: window.innerWidth < 1200 ? '50px' : '80px' }} /> */}
      </div>
      <div className="col-xs-7 col-md-7">
        <h6 className="font-lato-normal-400">
          {cuenta_uuid ? (
            <a style={{ color: 'black' }} href={'/admin/cuentas/perfil/' + cuenta_uuid}>
              Propietario: {cuenta_name}
            </a>
          ) : (
            <>Propietario: {cuenta_name}</>
          )}
        </h6>

        <h4 className="font-lato-normal-500">{parcela_name || '-'}</h4>

        <h6 className="font-lato-normal-400">
          {localidad}, {municipio}
        </h6>
      </div>
      <div className="col-xs-2 col-md-2">
        <div className="row">
          {certifications?.map(cert => {
            return (
              <div key={cert.id} className="col-4 col-sm-12 col-md-12 col-lg-12">
                <img
                  src={`${env.assetURL}${cert.url}`}
                  alt={cert.name}
                  style={{
                    width: '45px',
                    marginBottom: '15px',
                  }}></img>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CardTitleParcela;
