import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import Api from '../../api/global.service';
import ParcelaProfile from './parcelas-profile';
import ParcelasEUDR from './parcelas-eudr';
import BackButton from '../../components/link/back-button';
import { getUsaEUDR } from '../../components/content/coop-config';
import generalCatchErrorsModal from '../../components/modal/modal-error';

const ParcelasProfileWrapper = () => {
  const { uuid } = useParams();
  const location = useLocation();
  const history = useHistory();
  const [parcela, setParcela] = useState({});
  const [parcelaData, setParcelaData] = useState({});
  const [centroid, setCentroid] = useState(null);
  const [blocking, setBlocking] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const showEUDR = new URLSearchParams(location.search).get('view') === 'eudr';

  useEffect(() => {
    window.addEventListener('resize', function() {
      setWidth(window.innerWidth);
    });
    if (uuid != null) {
      setBlocking(true);
      Api.get('parcela_profile', uuid)
        .then(response => {
          setParcela(response.data);
          let cent;
          if (response?.data?.poligono) {
            cent = { lat: parseFloat(response?.data?.centroide_pol_lat), lng: parseFloat(response?.data?.centroide_pol_lng) };
            setCentroid(cent);
          } else if (!isNaN(response?.data?.centroide_lat) && !isNaN(response?.data?.centroide_lat)) {
            cent = { lat: parseFloat(response?.data?.centroide_lat), lng: parseFloat(response?.data?.centroide_lng) };
            setCentroid(cent);
          }
          setParcelaData({
            parcelaPolygon: response?.data?.poligono,
            centroid: cent,
          });
          setBlocking(false);
        })
        .catch(e => {
          generalCatchErrorsModal(e.response?.status, '/admin/parcelas/list');
          setBlocking(false);
        });
    }
  }, []);

  const setView = isEUDR => {
    const searchParams = new URLSearchParams(location.search);
    if (isEUDR) {
      searchParams.set('view', 'eudr');
    } else {
      searchParams.delete('view');
    }
    history.replace({ search: searchParams.toString() });
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '-30px',
          alignItems: 'flex-start',
        }}>
        <div className="d-flex flex-column">
          <div className="mb-3">
            <BackButton goBackDefault="/admin/parcelas/list" />
          </div>
          {getUsaEUDR() ? (
            <div className="btn-group" role="group" style={{ borderRadius: 1 }}>
              <button
                type="button"
                onClick={() => setView(false)}
                className={'btn btn-light'}
                style={{
                  borderTopLeftRadius: 10,
                  borderBottomLeftRadius: 10,
                  backgroundColor: !showEUDR ? '#FFEED9' : 'transparent',
                  borderWidth: 1,
                  borderColor: '#d4d4d4',
                }}
                disabled={false}>
                Perfil General
              </button>

              <button
                type="button"
                onClick={() => setView(true)}
                className={'btn btn-light'}
                style={{
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  backgroundColor: showEUDR ? '#FFEED9' : 'transparent',
                  borderWidth: 1,
                  borderColor: '#d4d4d4',
                }}
                disabled={false}>
                EUDR
              </button>
            </div>
          ) : null}
        </div>
      </div>

      {!showEUDR ? (
        <ParcelaProfile uuid={uuid} parcela={parcela} parcelaData={parcelaData} centroid={centroid} blocking={blocking} width={width} />
      ) : (
        <ParcelasEUDR uuid={uuid} parcela={parcela} parcelaData={parcelaData} centroid={centroid} blocking={blocking} width={width} />
      )}
    </div>
  );
};

export default ParcelasProfileWrapper;
