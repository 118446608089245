import React, { useRef } from 'react';
import { useTable, useSortBy, useFilters } from 'react-table';
import BlockUi from 'react-block-ui';
import flattenColumnsRecursive from '../../utils/flattenColumns.js';
import { useVirtualizer } from '@tanstack/react-virtual';

const NoPagination = ({ blocking, columns, data, fnBackgroundColor, fnColor, sticky = true }) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: flattenColumnsRecursive(columns).map(column => {
          if (column.show === false) {
            return column.accessor || column.id;
          }
        }),
      },
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
      autoResetHiddenColumns: false,
    },
    useFilters,
    useSortBy
  );

  const getBackgroundColor =
    fnBackgroundColor != null
      ? fnBackgroundColor
      : () => {
          return 'rgba(240, 224, 207, 1.0)';
        };

  const getColor =
    fnColor != null
      ? fnColor
      : i => {
          return 'rgb(95, 95, 89)';
        };

  const tableRef = useRef(null);

  // Virtualización con @tanstack/react-virtual
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableRef.current, // Elemento con scroll
    estimateSize: () => 35,
    overscan: 5, // Renderizar filas adicionales fuera de la vista para suavizar el scroll
  });

  const virtualItems = rowVirtualizer.getVirtualItems();
  const totalSize = rowVirtualizer.getTotalSize();

  return (
    <>
      <div style={{ paddingBottom: 0 }}>
        <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
          <div
            ref={tableRef}
            className={`table-responsive ${sticky && data.length > 7 ? 'table-sticky' : ''}`}
            style={{ marginBottom: 20 }}>
            <table className="table" {...getTableProps()} style={{ borderBottom: '1px solid #e4e7ea' }}>
              <thead>
                {headerGroups.map((headerGroup, i) => (
                  <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, j) => (
                      <th
                        key={j}
                        className="width-150"
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        style={{
                          backgroundColor: getBackgroundColor(i, j),
                          color: getColor(i),
                          border: '0px solid red',
                        }}>
                        <div>{column.filter ? column.render('Filter') : null}</div>
                        <div
                          className="d-flex"
                          style={{
                            minWidth: column.minWidth ? column.minWidth : column.width ? 'auto' : '150px',
                            width: column.width ? column.width : 'auto',
                          }}>
                          <span>{column.render('Header')}</span>
                          <span className="ml-auto">
                            {column.sortable ? (
                              column.isSorted ? (
                                column.isSortedDesc ? (
                                  <i className="fa fa-sort-down fa-fw f-s-14 text-green"></i>
                                ) : (
                                  <i className="fa fa-sort-up fa-fw f-s-14 text-green"></i>
                                )
                              ) : (
                                <i className="fa fa-sort fa-fw f-s-14 opacity-3"></i>
                              )
                            ) : (
                              ''
                            )}
                          </span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {/* Espacio inicial */}
                {virtualItems.length > 0 && virtualItems[0].start > 0 && (
                  <tr>
                    <td style={{ height: `${virtualItems[0].start}px` }} colSpan={columns.length} />
                  </tr>
                )}
                {/* Filas virtualizadas */}
                {virtualItems.map(virtualRow => {
                  const row = rows[virtualRow.index];
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, j) => (
                        <td key={j} className="edit_hover_tr" {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  );
                })}
                {/* Espacio final */}
                {virtualItems.length > 0 && virtualItems[virtualItems.length - 1].end < totalSize && (
                  <tr>
                    <td
                      style={{
                        height: `${totalSize - virtualItems[virtualItems.length - 1].end}px`,
                      }}
                      colSpan={columns.length}
                    />
                  </tr>
                )}
              </tbody>
            </table>
            {rows.length === 0 && !blocking && <div className="w-100 text-center">Sin registros encontrados</div>}
          </div>
        </BlockUi>
      </div>
    </>
  );
};

export default NoPagination;
