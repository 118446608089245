import React, { useState, useEffect, useCallback } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Label, LabelList, Legend } from 'recharts';
// import LabelCategory from '../../label/label-category.jsx';
import Api from '../../../api/global.service.js';
// import mapChartData from '../../../utils/mapChartData.js';
// import renderCustomizedLabel from '../../../utils/renderCustomizedLabel.js';
import BlockUi from 'react-block-ui';

const CuentasResumenProductoresDetalle = ({ id_ciclo }) => {
  const [blocking, setBlocking] = useState(true);
  const [dataGrafica, setDataGrafica] = useState([]);
  const [totalPadron, setTotalPadron] = useState(0);
  const [total, setTotal] = useState(0);
  const URL_GENERO = 'dashboards/productores/generos';
  const URL_EDAD = 'dashboards/productores/edades';
  const URL_ALTAS = 'dashboards/productores/totales';
  const URL_PARCELAS = 'dashboards/productores/parcelas';
  const URL_RIESGOS = 'dashboards/productores/riesgos';
  const URL_HA = 'dashboards/productores/hectareas';
  const rStart = [10, 0, 0, 10];
  const rEnd = [0, 10, 10, 0];

  useEffect(() => {
    if (id_ciclo > 0) {
      getData();
    }
  }, [id_ciclo]);

  const getData = useCallback(async () => {
    setBlocking(true);
    const data = [];
    // api calls
    const apiFilter = [{ column: 'id_ciclo', operator: '=', value: id_ciclo }];
    const genero = await Api.search(URL_GENERO, { filter: apiFilter });
    const edad = await Api.search(URL_EDAD, { filter: apiFilter });
    const altas = await Api.search(URL_ALTAS, { filter: apiFilter });
    const parcelas = await Api.search(URL_PARCELAS, { filter: apiFilter });
    const riesgos = await Api.search(URL_RIESGOS, { filter: apiFilter });
    const ha = await Api.search(URL_HA, { filter: apiFilter });

    // data chart
    const gen = {
      name: 'Género',
      gen_a_label: 'Hombres',
      gen_b_label: 'Mujeres',
      gen_c_label: 'Sin Asignar',
    };
    gen.gen_a = genero.data.find(g => g.productor_genero == 'Hombre')?.total || 0;
    gen.gen_b = genero.data.find(g => g.productor_genero == 'Mujer')?.total || 0;
    gen.gen_c = genero.data.find(g => g.productor_genero == 'Sin Asignar')?.total || 0;
    data.push(gen);

    //setTotal(genero.data.reduce((acc, c) => parseInt(acc) + parseInt(c.total), 0));
    setTotalPadron(altas?.data?.[0]?.total_productores_padron || 0);
    setTotal(altas?.data?.[0]?.total_productores || 0);

    const age = {
      name: 'Edad',
      age_a_label: 'Jovenes',
      age_b_label: 'Adultos jóvenes',
      age_c_label: 'Adultos',
      age_d_label: 'Adultos Mayores',
      age_e_label: 'Sin Asignar',
    };
    age.age_a = edad.data.find(e => e.rango_edad == 'Menor de 20')?.total || 0;
    age.age_b = edad.data.find(e => e.rango_edad == '20 - 30')?.total || 0;
    age.age_c = edad.data.find(e => e.rango_edad == '31 - 50')?.total || 0;
    age.age_d = edad.data.find(e => e.rango_edad == 'Mayor a 51')?.total || 0;
    age.age_e = edad.data.find(e => e.rango_edad == 'Sin Asignar')?.total || 0;
    for (const key in age) {
      !age[key] && delete age[key];
    }
    data.push(age);

    const add = {
      name: 'Altas/Bajas',
      add_a_label: 'Productores ya existentes',
      add_b_label: 'Altas',
      add_c_label: 'Bajas',
    };
    add.add_a = altas?.data?.[0]?.total_productores_padron - altas?.data?.[0]?.total_altas || 0;
    add.add_b = altas?.data?.[0]?.total_altas || 0;
    add.add_c = altas?.data?.[0]?.total_bajas || 0;
    data.push(add);

    data.push({
      name: 'No. parcelas',
      p_a_label: 'Una parcela',
      p_b_label: 'Dos parcelas',
      p_c_label: '+ de 2 parcelas',
      ...parcelas.data[0],
    });

    data.push({
      name: 'Nivel de riesgo',
      r_a_label: 'Sin riesgo',
      r_b_label: 'Bajo',
      r_c_label: 'Medio',
      ...riesgos.data[0],
    });

    data.push({
      name: 'Superficie',
      ha_a_label: '< 1 ha',
      ha_b_label: 'Entre 1 y 2 ha',
      ha_c_label: 'Entre 2 y 5 ha',
      ha_d_label: '+ de 5 ha',
      ...ha.data[0],
    });

    setDataGrafica(data);
    setBlocking(false);
  }, [id_ciclo]);

  const customizedDataLabel = props => {
    const { content, ...rest } = props;
    return rest.width > 0 && <Label {...rest} fontSize="14" fill="#222222" fontWeight="Bold" />;
  };

  const customizedLabel = props => {
    const { content, ...rest } = props;
    return rest.width > 40 ? (
      <Label {...rest} fontSize="12" fill="#222222" fontWeight="Normal" />
    ) : (
      rest.width > 0 && <Label {...rest} fontSize="12" fill="#222222" fontWeight="Normal" position={'right'} offset={10} width={50} />
    );
  };

  // What is the correct radius for each bar chart
  const getRadius = (dataSet, dataKey) => {
    // 1. get values of dataSet (where "name" = "Superficie")
    const data = dataGrafica.find(d => d.name == dataSet);
    if (data && Object.entries(data).length > 0) {
      let dataEntries = Object.entries(data).reverse();
      // remove some properties
      let index = 0;
      for (const [delDK, delDV] of dataEntries) {
        delDK.includes('_label') && delete dataEntries[index];
        delDK.includes('id_cooperativa') && delete dataEntries[index];
        delDK.includes('id_ciclo') && delete dataEntries[index];
        delDK.includes('name') && delete dataEntries[index];
        ++index;
      }
      // 2. from the latest to the first item, who is equal to void/zero?
      let lastDataKey = null;

      const firstDataKeyArr = dataEntries.length > 0 ? dataEntries.filter(d => d) : [];
      const firstDataKeyArr1 = firstDataKeyArr.length > 0 ? firstDataKeyArr.slice(-1)[0][0] : [];
      const firstDataKey = firstDataKeyArr1.length > 0 ? firstDataKeyArr1[0] : null;
      for (const dataItem of dataEntries) {
        if (dataItem && dataItem.length === 2) {
          const [dK, dV] = dataItem;
          if (parseInt(dV) > 0) {
            lastDataKey = dK;
            break;
          }
        }
      }
      // 3. the item from above point is dataKey? if so, then radius is rEnd, if not then radius is normal
      if (dataKey == lastDataKey) {
        return rEnd;
      } else if (dataKey == firstDataKey) {
        return rStart;
      } else {
        return [0, 0, 0, 0];
      }
    }
    return [0, 0, 0, 0];
  };

  return (
    <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
      <div className="row card-table mt-2 mb-4">
        <div className="col-md-12">
          <h6 className="font-lato-normal-400 text-left">DATOS DE LOS PRODUCTORES DE LA COOPERATIVA</h6>
          <ResponsiveContainer height={520} width={'100%'}>
            <BarChart layout="vertical" data={dataGrafica} margin={{ left: 20, right: 20 }}>
              <CartesianGrid horizontal={false} vertical={false} strokeDasharray="3 3" />
              {/* <XAxis hide type="number" /> */}
              <XAxis type="number" tick={false} />
              <YAxis type="category" dataKey="name" fontSize="14" stroke="#222222" />
              <Tooltip />
              <Legend
                align="left"
                payload={[{ value: 'No. productores', type: 'line' }]}
                wrapperStyle={{ left: 60, bottom: 20, fontSize: 14 }}
              />
              {/* Genero */}
              <Bar
                dataKey="gen_a"
                stackId="a"
                fill="#FBCB83"
                name="Hombres"
                radius={getRadius('Género', 'gen_a')}
                isAnimationActive={false}>
                <LabelList dataKey="gen_a" position="center" content={customizedDataLabel} />
                <LabelList dataKey="gen_a_label" position="insideTopLeft" content={customizedLabel} />
              </Bar>
              <Bar
                dataKey="gen_b"
                stackId="a"
                fill="#E5975C"
                name="Mujeres"
                radius={getRadius('Género', 'gen_b')}
                isAnimationActive={false}>
                <LabelList dataKey="gen_b" position="center" content={customizedDataLabel} />
                <LabelList dataKey="gen_b_label" position="insideTopLeft" content={customizedLabel} />
              </Bar>
              <Bar
                dataKey="gen_c"
                stackId="a"
                fill="#9C969A"
                name="Sin Asignar"
                radius={getRadius('Género', 'gen_c')}
                isAnimationActive={false}>
                <LabelList dataKey="gen_c" position="center" content={customizedDataLabel} />
                <LabelList dataKey="gen_c_label" position="insideTopLeft" content={customizedLabel} />
              </Bar>
              {/* Edad */}
              <Bar dataKey="age_a" stackId="a" fill="#DCD186" name="Jóvenes" radius={getRadius('Edad', 'age_a')} isAnimationActive={false}>
                <LabelList dataKey="age_a" position="center" content={customizedDataLabel} />
                <LabelList dataKey="age_a_label" position="insideTopLeft" content={customizedLabel} />
              </Bar>
              <Bar
                dataKey="age_b"
                stackId="a"
                fill="#B8BE75"
                name="Adultos jóvenes"
                radius={getRadius('Edad', 'age_b')}
                isAnimationActive={false}>
                <LabelList dataKey="age_b" position="center" content={customizedDataLabel} />
                <LabelList dataKey="age_b_label" position="insideTopLeft" content={customizedLabel} />
              </Bar>
              <Bar dataKey="age_c" stackId="a" fill="#94AB66" name="Adultos" radius={getRadius('Edad', 'age_c')} isAnimationActive={false}>
                <LabelList dataKey="age_c" position="center" content={customizedDataLabel} />
                <LabelList dataKey="age_c_label" position="insideTopLeft" content={customizedLabel} />
              </Bar>
              <Bar
                dataKey="age_d"
                stackId="a"
                fill="#719859"
                name="Adultos mayores"
                radius={getRadius('Edad', 'age_d')}
                isAnimationActive={false}>
                <LabelList dataKey="age_d" position="center" content={customizedDataLabel} />
                <LabelList dataKey="age_d_label" position="insideTopLeft" content={customizedLabel} />
              </Bar>
              <Bar
                dataKey="age_e"
                stackId="a"
                fill="#9C969A"
                name="Sin Asignar"
                radius={getRadius('Edad', 'age_e')}
                isAnimationActive={false}>
                <LabelList dataKey="age_e" position="center" content={customizedDataLabel} />
                <LabelList dataKey="age_e_label" position="insideTopLeft" content={customizedLabel} />
              </Bar>
              {/* Altas y bajas */}
              <Bar
                dataKey="add_a"
                stackId="a"
                fill="#FBCB83"
                name="Productores ya existentes"
                radius={getRadius('Altas/Bajas', 'add_a')}
                isAnimationActive={false}>
                <LabelList dataKey="add_a" position="center" content={customizedDataLabel} />
                <LabelList dataKey="add_a_label" position="insideTopLeft" content={customizedLabel} />
              </Bar>
              <Bar
                dataKey="add_b"
                stackId="a"
                fill="#E5975C"
                name="Altas"
                radius={getRadius('Altas/Bajas', 'add_b')}
                isAnimationActive={false}>
                <LabelList dataKey="add_b" position="center" content={customizedDataLabel} />
                <LabelList dataKey="add_b_label" position="insideTopLeft" content={customizedLabel} />
              </Bar>
              <Bar
                dataKey="add_c"
                stackId="a"
                fill="#D9754B"
                name="Bajas"
                radius={getRadius('Altas/Bajas', 'add_c')}
                isAnimationActive={false}>
                <LabelList dataKey="add_c" position="center" content={customizedDataLabel} />
                <LabelList dataKey="add_c_label" position="insideTopLeft" content={customizedLabel} />
              </Bar>
              {/* Parcela */}
              <Bar
                dataKey="p_a"
                stackId="a"
                fill="#DCD186"
                name="Una parcela"
                radius={getRadius('No. parcelas', 'p_a')}
                isAnimationActive={false}>
                <LabelList dataKey="p_a" position="center" content={customizedDataLabel} />
                <LabelList dataKey="p_a_label" position="insideTopLeft" content={customizedLabel} />
              </Bar>
              <Bar
                dataKey="p_b"
                stackId="a"
                fill="#B8BE75"
                name="Dos parcelas"
                radius={getRadius('No. parcelas', 'p_b')}
                isAnimationActive={false}>
                <LabelList dataKey="p_b" position="center" content={customizedDataLabel} />
                <LabelList dataKey="p_b_label" position="insideTopLeft" content={customizedLabel} />
              </Bar>
              <Bar
                dataKey="p_c"
                stackId="a"
                fill="#719859"
                name="+ de 2 parcelas"
                radius={getRadius('No. parcelas', 'p_c')}
                isAnimationActive={false}>
                <LabelList dataKey="p_c" position="center" content={customizedDataLabel} />
                <LabelList dataKey="p_c_label" position="insideTopLeft" content={customizedLabel} />
              </Bar>
              {/* Riesgo */}
              <Bar
                dataKey="r_a"
                stackId="a"
                fill="#FBCB83"
                name="Sin riesgo"
                radius={getRadius('Nivel de riesgo', 'r_a')}
                isAnimationActive={false}>
                <LabelList dataKey="r_a" position="center" content={customizedDataLabel} />
                <LabelList dataKey="r_a_label" position="insideTopLeft" content={customizedLabel} />
              </Bar>
              <Bar
                dataKey="r_b"
                stackId="a"
                fill="#E5975C"
                name="Bajo"
                radius={getRadius('Nivel de riesgo', 'r_b')}
                isAnimationActive={false}>
                <LabelList dataKey="r_b" position="center" content={customizedDataLabel} />
                <LabelList dataKey="r_b_label" position="insideTopLeft" content={customizedLabel} />
              </Bar>
              <Bar
                dataKey="r_c"
                stackId="a"
                fill="#D9754B"
                name="Medio"
                radius={getRadius('Nivel de riesgo', 'r_c')}
                isAnimationActive={false}>
                <LabelList dataKey="r_c" position="center" content={customizedDataLabel} />
                <LabelList dataKey="r_c_label" position="insideTopLeft" content={customizedLabel} />
              </Bar>
              {/* Superficie */}
              <Bar
                dataKey="ha_a"
                stackId="a"
                fill="#DCD186"
                name="< 1 ha"
                radius={getRadius('Superficie', 'ha_a')}
                isAnimationActive={false}>
                <LabelList dataKey="ha_a" position="center" content={customizedDataLabel} />
                <LabelList dataKey="ha_a_label" position="insideTopLeft" content={customizedLabel} />
              </Bar>
              <Bar
                dataKey="ha_b"
                stackId="a"
                fill="#94AB66"
                name="Entre 1 y 2 ha"
                radius={getRadius('Superficie', 'ha_b')}
                isAnimationActive={false}>
                <LabelList dataKey="ha_b" position="center" content={customizedDataLabel} />
                <LabelList dataKey="ha_b_label" position="insideTopLeft" content={customizedLabel} />
              </Bar>
              <Bar
                dataKey="ha_c"
                stackId="a"
                fill="#B8BE75"
                name="Entre 2 y 5 ha"
                radius={getRadius('Superficie', 'ha_c')}
                isAnimationActive={false}>
                <LabelList dataKey="ha_c" position="center" content={customizedDataLabel} />
                <LabelList dataKey="ha_c_label" position="insideTopLeft" content={customizedLabel} />
              </Bar>
              <Bar
                dataKey="ha_d"
                stackId="a"
                fill="#719859"
                name="+ de 5 ha"
                radius={getRadius('Superficie', 'ha_d')}
                isAnimationActive={false}>
                <LabelList dataKey="ha_d" position="center" content={customizedDataLabel} />
                <LabelList dataKey="ha_d_label" position="insideTopLeft" content={customizedLabel} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
          <div style={{ marginTop: -20 }}>
            <h5 className="m-5 p-5 text-right">TOTAL {totalPadron} productores en padrón</h5>
            <h5 className="m-5 p-5 text-right">TOTAL {total} productores activos</h5>
          </div>
        </div>
      </div>
    </BlockUi>
  );
};

export default CuentasResumenProductoresDetalle;
