import { useEffect, useState } from 'react';
import { getIdCatCreditoPrecosecha, useCoopConfig } from '../components/content/coop-config';
import DebtCalculator from '../nuup/utils/debtCalculator';
import Api from '../api/global.service';
import getEstatus from '../nuup/utils/getStatusDebtColor';
import moment from 'moment/moment';
import xlsx from 'json-as-xlsx';
import transformWord from '../utils/transformWord';
import insertIf from '../utils/insertIf';

const useAnticipos = ({ ciclo, update, etiquetaAnticipos }) => {
  const [blocking, setBlocking] = useState(true);
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  const coopConfig = useCoopConfig();

  useEffect(() => {
    if (ciclo.id) {
      updateData(ciclo.id);
    }
  }, [ciclo, update]);

  const updateData = id_ciclo => {
    setBlocking(true);
    const interesCoop = coopConfig.catCreditoPrecosecha()?.interes / 100;
    Api.Acopio.search('anticipos', {
      filter: [{ column: 'id_ciclo', operator: '=', value: id_ciclo }],
    })
      .then(response => {
        const updatedData = response.data.map(anticipo => {
          const total_anticipos_recuperados = anticipo.anticipo_pagos.reduce((acc, pg) => acc + +pg.cantidad_pago / 100, 0);

          // RETURN DATA WITH DEBT CALCULATION
          if (getIdCatCreditoPrecosecha() > 2) {
            const interes = anticipo.tasa_interes && +anticipo.tasa_interes >= 0 ? +anticipo.tasa_interes : +interesCoop;
            const debtCalc = new DebtCalculator({
              credito: anticipo,
              interestRate: interes * 100,
              pagos: anticipo.anticipo_pagos,
              stringDate: null,
            });
            const calc = debtCalc.getDebt();
            const estatus = getEstatus(calc.debtToday.pendingCapital / 100);
            return {
              ...anticipo,
              anticipo_id: anticipo.id,
              total_anticipos_recuperados,
              total_saldo: calc.debtToday.pendingCapital / 100,
              total: +calc.debtToday.totalInterest / 100 + +anticipo.cantidad_anticipo / 100,
              total_interes: calc.debtToday.totalInterest / 100,
              estatus: estatus.label,
              estatus_color: estatus.color,
              tasa_interes: interes,
            };
          }
          // RETURN DATA WITH NO INTEREST RATE
          const estatus = getEstatus(+anticipo.cantidad_anticipo / 100 - +total_anticipos_recuperados);

          return {
            ...anticipo,
            anticipo_id: anticipo.id,
            total_anticipos_recuperados,
            total: +anticipo.cantidad_anticipo / 100,
            total_interes: 0,
            total_saldo: +anticipo.cantidad_anticipo / 100 - +total_anticipos_recuperados,
            estatus: estatus.label,
            estatus_color: estatus.color,
            comision_efectivo: 999,
          };
        });
        setData(updatedData);
        setOriginalData(updatedData);
        setBlocking(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('error: ', e);
        setBlocking(false);
      });
  };

  const exportarAnticipos = () => {
    let xlsData1 = [
      {
        sheet: transformWord(etiquetaAnticipos, ['singular', 'uppercase']),
        columns: [
          { label: 'CLAVE', value: 'productor_clave_tx' },
          ...insertIf(!!+coopConfig.config?.usa_codigo_tx, { label: 'CÓDIGO', value: 'productor_codigo_tx' }),
          { label: 'NOMBRE PRODUCTOR', value: 'productor_nombre' },
          { label: 'LOCALIDAD', value: 'localidad' },
          { label: 'REGIÓN', value: 'region' },
          { label: 'CENTRO DE ACOPIO', value: 'centro_acopio' },
          { label: 'CATEGORÍA', value: 'cuenta_categoria' },
          { label: 'FECHA DESEMBOLSO', value: 'fecha_ejecucion' },
          { label: 'ID ' + transformWord(etiquetaAnticipos, ['singular', 'uppercase']), value: 'folio' },
          ...insertIf(getIdCatCreditoPrecosecha() > 2, {
            label: 'IMPORTE TOTAL',
            value: 'total',
            format: `${coopConfig.currency()?.symbol}#,##0.00`,
          }),
          { label: 'CAPITAL DESEMBOLSADO', value: row => +row.cantidad_anticipo / 100, format: `${coopConfig.currency()?.symbol}#,##0.00` },
          ...insertIf(getIdCatCreditoPrecosecha() > 2, {
            label: 'INTERÉS ACUMULADO',
            value: 'total_interes',
            format: `${coopConfig.currency()?.symbol}0.00`,
          }),
          ...insertIf(getIdCatCreditoPrecosecha() > 2, { label: 'TASA INTERÉS', value: row => +row.tasa_interes / 10000, format: '0.00%' }),
          {
            label: transformWord(etiquetaAnticipos, ['singular', 'uppercase']) + ' RECUPERADOS',
            value: row => (isNaN(row.total_anticipos_recuperados) ? 0 : +row.total_anticipos_recuperados),
            format: `${coopConfig.currency()?.symbol}0.00`,
          },
          {
            label: 'COMISIÓN',
            value: row =>
              row.anticipo_pagos.reduce(
                (sum, item) =>
                  sum + (Number(item.comision_efectivo) > 0) ? Number(item.comision_efectivo) / 100 : Number(item.comision_efectivo),
                0
              ),
            format: `${coopConfig.currency()?.symbol}0.00`,
          },
          {
            label: 'SALDO',
            value: row => (row.total_saldo < 0.01 && row.total_saldo > -0.01 ? 0 : row.total_saldo),
            format: `${coopConfig.currency()?.symbol}0.00`,
          },
          { label: 'DESCRIPCIÓN', value: 'descripcion' },
          { label: 'ESTATUS', value: 'estatus' },
          { label: 'REGISTRO', value: row => `Registrado ${row.registro ? 'por ' + row.registro : ''} el ${row.fecha_ejecucion}` },
        ],
        content: data,
      },
    ];
    let settings = {
      fileName: `Anticipos_${ciclo.nombre}_${moment().format('YYYYMMDD_HHmmss')}`,
    };
    xlsx(xlsData1, settings);
  };

  return {
    blocking,
    data,
    originalData,
    setData,
    exportarAnticipos,
  };
};

export default useAnticipos;
