import React from 'react';
import env from '../../environment';

const LabelAlertas = ({ alertas, blocking }) => {
  if (blocking) return null;
  const filteredAlerts = alertas.filter(alert => alert.error != 1);
  if (filteredAlerts.length === 0) {
    return (
      <div>
        <i
          className="fa fa-check"
          style={{
            color: '#789D16',
            marginRight: 10,
          }}
        />
        <span>Polígono válido, no encontramos errores de cruces o superposición de polígonos</span>
      </div>
    );
  }
  return (
    <div>
      <i
        className="fa fa-exclamation-triangle"
        aria-hidden="true"
        style={{
          color: env.colors.secondary_300,
          marginRight: 10,
        }}
      />
      <span>Se identificaron alertas de calidad y/o validez del polígono: </span>
      {filteredAlerts.map((alert, index) => (
        <span key={alert.id} style={{ fontWeight: 'italic' }}>
          {alert.titulo}
          {index < filteredAlerts.length - 1 ? ', ' : ''}
        </span>
      ))}
    </div>
  );
};

export default LabelAlertas;
