/**
 * Get the zoom level depending on the area
 * @param {number} area
 * @returns {number} zoom
 */
const getZoomForArea = area => {
  if (isNaN(area)) return 19;
  if (area < 0.5) {
    return 19;
  } else if (area < 1.5) {
    return 18;
  } else if (area < 2) {
    return 17;
  } else if (area < 10) {
    return 16;
  } else if (area < 100) {
    return 15;
  } else if (area < 1000) {
    return 12;
  } else if (area < 10000) {
    return 10;
  } else {
    return 7;
  }
};

export default getZoomForArea;
