import React, { useState, forwardRef, useEffect, useRef } from 'react';
import Api from '../../../../api/global.service';
import 'react-datepicker/dist/react-datepicker.css';
import * as yup from 'yup';
import { publish } from '../../../../components/events.js';
import { validacionNumero2DecimalesNotRequired } from '../../../../nuup/utils/validaciones/validacionNumero2Decimales.js';
import TextField from '@mui/material/TextField';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { useCoopConfig } from '../../../../components/content/coop-config.jsx';

const ModalNuevoLote = (props, ref) => {
  const coopConfig = useCoopConfig();
  const [idCiclo, setIdCiclo] = useState(0);
  const [show, setShow] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [id, setId] = useState(0);
  const [uuid, setUuid] = useState(uuidv4());
  const [nombre, setNombre] = useState('');
  const [folio, setFolio] = useState('');
  const [volumen, setVolumen] = useState('');
  const [fechaEntregaVenta, setFechaEntregaVenta] = useState('');
  const [observaciones, setObservaciones] = useState('');
  const modalRef = useRef();
  const title = props.idLote === 0 ? 'Nuevo lote' : 'Actualizar lote';

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Escape' && show) {
        event.stopPropagation();
        handleClose();
      }
    };

    // Así como está, llama handleClose() apenas se abre en algunas ocasiones
    // const handleClickOutside = (event) => {
    // if (show && modalRef.current && !modalRef.current.contains(event.target)) {
    // event.stopPropagation();
    // handleClose();
    // }
    // event.stopPropagation();
    // };

    document.addEventListener('keydown', handleKeyDown);
    //    document.addEventListener('click', handleClickOutside);

    return () => {
      //    document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [show]);

  const [errors, setErrors] = useState(null);

  const schema = yup.object().shape({
    id: yup
      .number()
      .min(0)
      .nullable(),
    nombre: yup.string().required('Debe escribir un nombre'),
    folio: yup.string().nullable(),
    volumen: validacionNumero2DecimalesNotRequired(),
    fecha_entrega_venta: yup
      .date()
      .max(moment().add(24, 'M'), 'Seleccione otra fecha')
      .transform((curr, orig) => (orig === '' ? null : curr))
      .nullable()
      .notRequired(),
    observaciones: yup.string().nullable(),
  });

  const setData = () => {
    setId(props.infoLote.id);
    setUuid(props.infoLote.uuid);
    setNombre(props.infoLote.nombre);
    setFolio(props.infoLote.folio);
    setVolumen(props.infoLote.volumen);
    setFechaEntregaVenta(props.infoLote.fecha_entrega_venta);
    setErrors(null);
    setObservaciones(props.infoLote.observaciones);
    setIdCiclo(props.cicloActual);
    setShow(true);
  };

  useEffect(() => {
    setData();
  }, [props.idLote]);

  useEffect(() => {
    if (show && props.infoLote.length > 0) {
      setData();
    } else if (show) {
      setIdCiclo(props.cicloActual);
    }
  }, [show, props.infoLote, props.idLote]);
  // este useEffect extra no debería necesitarse, pero no logro que funcione sin dejarlo

  const validateHandler = () => {
    setBlocking(true);

    var data = {
      id: id,
      nombre: nombre,
      folio: folio,
      volumen: volumen,
      fecha_entrega_venta: fechaEntregaVenta,
      observaciones: observaciones,
    };

    var that = this; // hermoso

    schema
      .validate(data, { abortEarly: false })
      .then(function(valid) {
        //Datos validos
        const data = {
          id: id,
          uuid: uuid || uuidv4(),
          nombre: nombre,
          folio: folio,
          volumen: volumen,
          fecha_entrega_venta: fechaEntregaVenta,
          id_ciclo: idCiclo,
          observaciones: observaciones,
        };

        if (data.id > 0) {
          actualizaLote(data);
        } else {
          creaLote(data);
        }
      })
      .catch(function(err) {
        let errors = err.inner.reduce(function(map, obj) {
          map[obj.path] = obj.message;
          return map;
        }, {});
        setErrors(errors);
        setBlocking(false);
      });
  };

  const creaLote = data => {
    setBlocking(true);
    Api.Acopio.create('lotes', data)
      .then(response => {
        if (response.data.success) {
          setShow(false);
          setBlocking(false);
          props.onClose();
          publish('Lote::onSave', data);
        } else {
          let err = response.data.data;
          setErrors(err);
          setBlocking(false);
        }
      })
      .catch(e => {
        setBlocking(false);
      });
  };

  const actualizaLote = data => {
    Api.Acopio.update('lotes', uuid, data)
      .then(response => {
        if (response.data.success) {
          setBlocking(false);
          setShow(false);
          props.onClose();
          publish('Lote::onSave', data);
        } else {
          let err = response.data.data;
          setErrors(err);
          setBlocking(false);
        }
      })
      .catch(e => {
        setBlocking(false);
      });
  };

  const handleClose = () => {
    setShow(false);
    props.onClose();
  };

  return (
    <div className="modal" style={{ display: show ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.2)' }}>
      <div
        className="modal-dialog modal-dialog-scrollable"
        ref={modalRef}
        style={{ top: 0, right: 25, position: 'absolute', width: 400, height: 'calc(100% - 50px)' }}>
        <div className="modal-content" style={{ height: '100%' }}>
          <div className="modal-header" style={{ flex: 'inherit' }}>
            <h4 className="modal-title">{title}</h4>
            <button
              onClick={() => {
                handleClose();
              }}
              type="button"
              className="btn"
              style={{ borderRadius: '20px 20px 20px 20px' }}>
              <i className="fa fa-times"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <TextField
                  name="nombre"
                  error={!!errors?.nombre}
                  label="Nombre (ej: Lote 1)"
                  className="form-control"
                  value={nombre || ''}
                  onChange={event => {
                    setErrors(null);
                    setNombre(event.target.value);
                  }}
                />
              </div>
            </div>
            {errors?.nombre && <div className="invalid-feedback mt-4">{errors?.nombre}</div>}

            <div className="row">
              <div className="col-12 mt-5">
                <TextField
                  name="folio"
                  error={!!errors?.folio}
                  label="Folio o código"
                  className="form-control"
                  value={folio || ''}
                  onChange={event => {
                    setFolio(event.target.value);
                  }}
                />
              </div>
              <div className="col-12 mt-4">
                <span>Opcional</span>
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-3">
                <TextField
                  type="number"
                  name="volumen"
                  error={!!errors?.volumen}
                  label={`Volumen (${coopConfig.acopioUnits()?.abreviacion_plural}.)`}
                  className="form-control"
                  value={volumen || ''}
                  onChange={event => {
                    setVolumen(event.target.value);
                  }}
                />
              </div>
              {props.infoLote && Number(props.infoLote.lote_kgs_entregados) > Number(volumen) && (
                <div className="invalid-feedback mt-4">Estas reduciendo el volumen por debajo de lo que ya se le agregó</div>
              )}
              <div className="col-12 mt-4">
                {errors?.volumen ? <div className="invalid-feedback">{errors.volumen}</div> : <span>Opcional</span>}
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-5">
                <TextField
                  name="observaciones"
                  error={!!errors?.observaciones}
                  label="Observaciones"
                  className="form-control"
                  value={observaciones || ''}
                  onChange={event => {
                    setObservaciones(event.target.value);
                  }}
                />
              </div>
              <div className="col-12 mt-4">
                <span>Opcional</span>
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-3">
                <TextField
                  type="date"
                  name="fecha_entrega_venta"
                  error={!!errors?.fecha_entrega_venta}
                  label="Fecha entrega o venta"
                  className="form-control"
                  value={fechaEntregaVenta || ''}
                  onChange={event => {
                    setFechaEntregaVenta(event.target.value);
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </div>

              <div className="col-12 mt-4">
                {errors?.fecha_entrega_venta ? <div className="invalid-feedback">{errors.fecha_entrega_venta}</div> : <span>Opcional</span>}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {blocking && (
              <div className="spinner-border text-warning" role="status">
                <span className="sr-only">Guardando...</span>
              </div>
            )}
            {!blocking && (
              <>
                <button
                  onClick={() => {
                    handleClose();
                  }}
                  type="button"
                  className="btn button-hover"
                  style={{ borderRadius: '20px 20px 20px 20px' }}>
                  <i className="fa fa-times"></i> Cancelar
                </button>
                <button
                  disabled={blocking}
                  onClick={() => validateHandler()}
                  type="button"
                  className="btn btn-sirio"
                  style={{ borderRadius: '20px 20px 20px 20px' }}>
                  <i className="fa fa-save"></i> Guardar
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(ModalNuevoLote);
