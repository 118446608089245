import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  createRef,
} from "react";
import { Link } from "react-router-dom";
import CardContainer from "../../components/card/card-container.jsx";
import { useAuth } from "../../components/content/auth.jsx";
import SelectCiclo from "../../components/select/select-ciclo.jsx";
import { useHistory } from "react-router-dom";
import { useCoopConfig } from "../../components/content/coop-config.jsx";
import { useReactToPrint } from "react-to-print";
import Api from "../../api/global.service";
import env from "../../environment.js";
import CardProductor from "./components/card-productor.jsx";
import CalidadAnalisisSensorialInfoZarandasDefectos from "./calidad-analisis-fisico-info-zarandas-defectos.js";
import {getCalidadLS} from './components/helpers.js';
import LinkDownload from '../../components/link/link-download.jsx';

const pageStyle = `
  @page {
    size: 80mm 50mm;
  }

  @media print {
    .pagebreak {
      clear: both;
      page-break-after: always !important;
    }

    tr  {
      border: 2px solid #000 !important;
      padding: 5px 10px !important;
    }

    td, th  {
      font-size: 10px !important;
      padding: 5px 10px !important;
    }

  }
`;

const CalidadAnalisisSensorialInfo = () => {
  const MSG_REQUIRED = "Información obligatoria";
  const queryParameters = new URLSearchParams(window.location.search);
  const history = useHistory();
  const auth = useAuth();
  const coopConfig = useCoopConfig();
  const [data, setData] = useState([]);
  const [dataZarandas, setDataZarandas] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [ciclo, setCiclo] = useState({});
  const [blocking, setBlocking] = useState(false);
  const componentRef = useRef(null);
  const [nameToPrint, setNameToPrint] = useState("AnalisisFisico");
  const [idCuenta, setIdCuenta] = useState();
  const [tipoAnalisis, setTipoAnalisis] = useState(0);
  const [existeAnalisisSensorial, setExisteAnalisisSensorial] = useState(false);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: nameToPrint,
    //onAfterPrint: useCallback(() => history.goBack(), [history]),
    removeAfterPrint: true,
    copyStyles: true,
    pageStyle: pageStyle,
  });

  const agregarAnalisisSensorial = () => {
    history.push({
      pathname: "/admin/calidad/analisis/sensorial/registro"
    });
  };

  const getData = (id_calidad_analisis_fisico) => {
    Api.get("calidad/analisis/fisico", id_calidad_analisis_fisico)
      .then((response) => {
        if (typeof response.data !== "undefined") {
          setData(response.data);
        }
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  const getDataZarandas = (id_calidad_analisis_fisico) => {
    Api.getAll(
      "calidad/analisis/fisico/" + id_calidad_analisis_fisico + "/zarandas"
    )
      .then((response) => {
        if (typeof response.data !== "undefined") {
          console.log(response.data);
          setDataZarandas(response.data);
        }
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  useEffect(() => {
    let id_calidad_analisis_fisico = getCalidadLS("id_calidad_analisis_fisico");
    if (id_calidad_analisis_fisico.length > 0) {
      getData(id_calidad_analisis_fisico);
      getDataZarandas(id_calidad_analisis_fisico);
    }
    setIdCuenta(getCalidadLS("id_cuenta"));
    setTipoAnalisis(getCalidadLS("tipo_analisis"));
  }, []);

  useEffect(() => {
    let id_calidad_analisis_sensorial  = getCalidadLS("id_calidad_analisis_sensorial");
    if(id_calidad_analisis_sensorial.length === 36){
      setExisteAnalisisSensorial(true);
    }
  }, []);

  return (
    <div>
      <div className="row justify-content-between mb-2">
        <button
          className="btn btn-outline-dark mt-3"
          type="button"
          onClick={() => history.push({pathname: '/admin/calidad/analisis/fisico/registro',})}
           >
          <i className="fa fa-arrow-left"></i>
          {" Volver a registro"}
        </button>
        <div className="float-right">
          <LinkDownload url={`calidad/analisis/recibo/descargar/${getCalidadLS("id_calidad_analisis")}`} label={<button className="btn btn-outline-secondary ml-2" style={{ borderRadius: '20px 20px 20px 20px' }} type="button">Exportar PDF</button>}></LinkDownload>
        </div>
      </div>

      <CardContainer height={"100%"} style={{ marginTop: 10 }}>

        <div className="row" ref={componentRef}>
          <div className="col-md-12 col-xl-12">
            <h4>{"Registro de análisis físico"}</h4>
          </div>
          <div className="col-md-6 col-xl-6">
            <div className="row mt-1">
              <div className="col-xs-6 col-xl-6">
                <span>
                  {"Fecha de registro: "}
                  <span style={{ textDecoration: "underline" }}>
                    {data?.fecha}
                  </span>
                </span>
              </div>

              <div className="col-xs-6 col-xl-6">
                <span>
                  {"Tipo de proceso: "}
                  <span style={{ textDecoration: "underline" }}>
                    {data?.proceso?.nombre}
                  </span>
                </span>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-xs-12 col-xl-12">
                <h5>{"Análisis pergamino"}</h5>
              </div>
            </div>

            <div className="row mt-1">
              <div className="col-xs-4 col-xl-4">
                <span>
                  {"Rendimiento: "}
                  {data?.pergamino_rendimiento > 0 && (
                  <span style={{ textDecoration: "underline" }}>
                    {data?.pergamino_rendimiento + ' %'}
                  </span>
                  )}
                </span>
              </div>

              <div className="col-xs-4 col-xl-4">
                <span>
                  {"Humedad: "}
                  <span style={{ textDecoration: "underline" }}>
                    {data?.pergamino_humedad + '%'}
                  </span>
                </span>
              </div>

              <div className="col-xs-4 col-xl-4">
                <span>
                  {"Olor: "}
                  <span style={{ textDecoration: "underline" }}>
                    {data?.olor_muestra}
                  </span>
                </span>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-xs-4 col-xl-4">
                <span>
                  {"Cerezo: "}

                  {data?.pergamino_cerezo > 0 && (
                  <span style={{ textDecoration: "underline" }}>
                    {data?.pergamino_cerezo + ' %'}
                  </span>
                  )}
                </span>
              </div>

              <div className="col-xs-4 col-xl-4">
                <span>
                  {"Mancha: "}
                  {data?.pergamino_mancha > 0 && (
                  <span style={{ textDecoration: "underline" }}>
                    {data?.pergamino_mancha + ' %'}
                  </span>
                  )}
                </span>
              </div>

              <div className="col-xs-4 col-xl-4">
                <span>
                  {"Densidad: "}
                  <span style={{ textDecoration: "underline" }}>
                    {data?.pergamino_densidad + ' g./l.'}
                  </span>
                </span>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-xs-4 col-xl-4">
                <span>
                  {"Actividad en agua.: "}


                  <span style={{ textDecoration: "underline" }}>
                    {data?.pergamino_actividad_agua}
                  </span>

                </span>
              </div>

              </div>


            <div className="row mt-3">
              <div className="col-xs-12 col-xl-12">
                <h5>{"Análisis oro"}</h5>
              </div>
            </div>

            <div className="row mt-1">
              <div className="col-xs-6 col-xl-6">
                <span>
                  {"Color: "}
                  <span style={{ textDecoration: "underline" }}>
                    {data?.color?.nombre}
                  </span>
                </span>
              </div>

              <div className="col-xs-6 col-xl-6">
                <span>
                  {"Rendimiento: "}
                  {data?.oro_rendimiento_exportable > 0 && (
                  <span style={{ textDecoration: "underline" }}>
                    {data?.oro_rendimiento_exportable + ' %'}
                  </span>
                  )}

                </span>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-xs-6 col-xl-6">
                <span>
                  {"Mancha: "}
                  {data?.oro_mancha > 0 && (
                  <span style={{ textDecoration: "underline" }}>
                    {data?.oro_mancha + ' %'}
                  </span>
                  )}
                </span>
              </div>

              <div className="col-xs-6 col-xl-6">
                <span>
                  {"Kgs exportables: "}
                  {data?.oro_kgs_exportables > 0 && (
                  <span style={{ textDecoration: "underline" }}>
                    {data?.oro_kgs_exportables}
                  </span>
                  )}
                </span>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-xl-6">
            {tipoAnalisis === 1 && (
            <div className="row">
              <div className="col-xs-10 col-xl-10 offset-2">
                <CardProductor idCuenta={idCuenta} />
              </div>
            </div>
            )}
          </div>

          <div className="col-xs-12 col-xl-12 mt-3">
            <span>
              {"Observaciones: "}
              <span style={{ textDecoration: "underline" }}>
                {data?.observaciones}
              </span>
            </span>
          </div>
        </div>

        {dataZarandas.map((zaranda, k) => {
          return (
            <div className="row mt-5" key={"z-" + k}>
              <div className="col-xs-12 col-md-12 col-xl-12">
                <CalidadAnalisisSensorialInfoZarandasDefectos data={zaranda} />
              </div>
            </div>
          );
        })}

        <div className="row mt-5">

          <div className="col-xs-6 col-xl-3 col-xxl-2">
            <button
              onClick={() => agregarAnalisisSensorial()}
              style={{
                borderRadius: "20px 20px 20px 20px",
                color: "#333",
                fontWeight: "normal",
                fontSize: 16,
              }}
              className="btn btn-sirio"
            >
              {existeAnalisisSensorial ? 'Ver' : '+ Agregar'} análisis sensorial
            </button>
          </div>

          <div className="col-xs-3 col-xl-2">
            <Link
              style={{
                borderRadius: "20px 20px 20px 20px",
                color: "#333",
                fontWeight: "normal",
                fontSize: 16,
              }}
              className="btn btn-outline-dark"
              to="/admin/calidad"
            >
              Guardar análisis
            </Link>
          </div>

        </div>
      </CardContainer>
    </div>
  );
};

export default CalidadAnalisisSensorialInfo;
