import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Select from 'react-select';
import Swal from 'sweetalert2';
import BlockUi from 'react-block-ui';
import { useHistory } from 'react-router-dom';
import './EudrForm.css';
import Api from '../../../api/global.service';
import colourStyles from '../../../utils/colourStyles';
import generalSuccessModal from '../../../components/modal/modal-success';
import env from '../../../environment';
import LinkDownload from '../../../components/link/link-download.jsx';

const schema = yup.object().shape({
  justificacion: yup.string().required('La justificación es obligatoria'),
  nuevo_riesgo: yup
    .object()
    .nullable()
    .test('required', 'Debes seleccionar un nivel de riesgo', value => value !== null),
  imagen: yup.mixed(),
  documento: yup.mixed(),
});

const EudrForm = ({ uuid, eudrRisk, setFormActive }) => {
  const history = useHistory();
  const [riskOptions, setRiskOptions] = useState([]);
  const [loadingRisks, setLoadingRisks] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      justificacion: eudrRisk?.justificacion || '',
      imagenes_previas: eudrRisk?.imagenes ? eudrRisk?.imagenes?.split(';') : [],
      documentos_previos: eudrRisk?.documentos ? eudrRisk?.documentos?.split(';') : [],
      nuevo_riesgo: eudrRisk?.cat_riesgo_eudr
        ? {
            value: eudrRisk.cat_riesgo_eudr.id,
            label: eudrRisk.cat_riesgo_eudr.nombre,
            color: eudrRisk.cat_riesgo_eudr.color,
          }
        : null,
    },
  });

  const imagenesPrevias = watch('imagenes_previas');
  const documentosPrevios = watch('documentos_previos');

  useEffect(() => {
    const loadRiskOptions = async () => {
      setLoadingRisks(true);
      try {
        const response = await Api.getAll('cat_riesgo_eudr');
        const options = response.data.map(risk => ({
          value: risk.id,
          label: risk.nombre,
          color: risk.color,
        }));
        setRiskOptions(options);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error loading risk options:', error);
      } finally {
        setLoadingRisks(false);
      }
    };

    loadRiskOptions();
  }, []);

  const onSubmit = data => {
    setLoading(true);
    const formData = new FormData();

    // Add the basic form data
    formData.append('justificacion', data.justificacion);
    formData.append('imagenes_previas', data.imagenes_previas);
    formData.append('documentos_previos', data.documentos_previos);
    formData.append('uuid', uuid);
    formData.append('id_riesgo_eudr', data.nuevo_riesgo.value);

    // Add images
    selectedImages.forEach((image, index) => {
      formData.append(`imagenes[${index}]`, image);
    });

    // Add documents
    selectedDocuments.forEach((document, index) => {
      formData.append(`documentos[${index}]`, document);
    });

    Api.create(`parcela_eudr_risk`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(response => {
        const { success, message, data } = response.data;
        const finish = () => {
          history.go(0);
          setLoading(false);
        };
        generalSuccessModal(success, `Riesgo actualizado con éxito`, finish, message, data);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log('error', error);
        const err = error.response.data;
        const errorMessage = err.errors['new_value'] !== undefined ? err.errors['new_value'] : err.errors.message;
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: '',
          text: errorMessage || 'Hubo un error al actualizar el riesgo. Favor de contactar al equipo de Sirio.',
        });
      });
  };

  const handleImageChange = e => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter(file => {
      if (file.size > 2 * 1024 * 1024) {
        // 2MB in bytes
        Swal.fire({
          icon: 'warning',
          title: 'Archivo demasiado grande',
          text: `El archivo ${file.name} excede el límite de 2MB`,
        });
        return false;
      }
      return true;
    });
    setSelectedImages(prevFiles => [...prevFiles, ...validFiles]);
    register('imagen').onChange(e);
  };

  const handleDocumentChange = e => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter(file => {
      if (file.size > 2 * 1024 * 1024) {
        // 2MB in bytes
        Swal.fire({
          icon: 'warning',
          title: 'Archivo demasiado grande',
          text: `El archivo ${file.name} excede el límite de 2MB`,
        });
        return false;
      }
      return true;
    });
    setSelectedDocuments(prevFiles => [...prevFiles, ...validFiles]);
    register('documento').onChange(e);
  };

  const removeImage = index => {
    setSelectedImages(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const removeDocument = index => {
    setSelectedDocuments(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <BlockUi tag="div" blocking={loading} className="eudr-form-container no-print">
      <h4 className="mb-2">CAMBIO DE RIESGO:</h4>
      <form onSubmit={handleSubmit(onSubmit)} className="eudr-form">
        <div className="form-group mb-4">
          <label>Justifica el cambio de nivel de riesgo:</label>
          <textarea
            className={`form-control ${errors.justificacion ? 'is-invalid' : ''}`}
            {...register('justificacion', {
              required: 'La justificación es requerida',
              value: eudrRisk?.justificacion || '',
            })}
            placeholder="Escribe aquí tu justificación"
            rows={4}
          />
          {errors.justificacion && <div className="invalid-feedback">{errors.justificacion.message}</div>}
        </div>

        {imagenesPrevias.length > 0 && (
          <div className="form-group mb-4">
            <label>Imágenes previas:</label>
            <div
              className="scrollable-gallery"
              style={{ display: 'flex', flexDirection: 'column', gap: '10px', overflowX: 'auto', padding: '10px 0' }}>
              {imagenesPrevias.map((image, index) => (
                <div
                  key={index}
                  style={{
                    position: 'relative',
                    aspectRatio: '1',
                    minWidth: '200px',
                    flexShrink: 0,
                    height: 150,
                  }}>
                  <img
                    src={`${env.assetURL}/storage/${image}`}
                    alt={`Imagen ${index + 1}`}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    }}
                  />
                  <button
                    type="button"
                    className="remove-file"
                    onClick={() => {
                      const newImages = imagenesPrevias.filter((_, i) => i !== index);
                      setValue('imagenes_previas', newImages);
                    }}
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      background: 'rgba(255, 255, 255, 0.9)',
                      border: 'none',
                      borderRadius: '50%',
                      width: '24px',
                      height: '24px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                    }}>
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        {documentosPrevios.length > 0 && (
          <div className="form-group mb-4 no-print">
            <label>Documentos previos:</label>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px 0' }}>
              {documentosPrevios.map((document, index) => {
                const filename = document.split('/').pop();
                const extension = filename.split('.').pop();
                let icon = env.images.icon.pdf;
                if (extension === 'doc' || extension === 'docx') icon = env.images.icon.doc;
                if (extension === 'xls' || extension === 'xlsx') icon = env.images.icon.xls;

                return (
                  <div
                    key={index}
                    style={{
                      position: 'relative',
                      // backgroundColor: env.colors.neutral_200,
                      padding: '10px 20px 10px 20px',
                      borderRadius: 4,
                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                      // height: 150,
                    }}>
                    <LinkDownload url={`parcela_eudr_risk/descargar/${uuid}/documentos/${filename}`} label={filename} icon={icon} />
                    <button
                      type="button"
                      className="remove-file"
                      onClick={() => {
                        const newDocuments = documentosPrevios.filter((_, i) => i !== index);
                        setValue('documentos_previos', newDocuments);
                      }}
                      style={{
                        position: 'absolute',
                        top: '9px',
                        right: '5px',
                        background: 'rgba(255, 255, 255, 0.9)',
                        border: 'none',
                        borderRadius: '50%',
                        width: '24px',
                        height: '24px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                      }}>
                      <i className="fa fa-times"></i>
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <div className="form-group mb-4">
          <label>
            ¿Quieres añadir {imagenesPrevias.length > 0 || documentosPrevios.length > 0 ? 'otras' : ''} imágenes o documentos como
            constancia? (opcional):
          </label>
          <div className="upload-options">
            <div className="upload-option">
              <label className="upload-box">
                <input type="file" accept="image/*" onChange={handleImageChange} multiple style={{ display: 'none' }} />
                <div className="upload-content">
                  <i className="fa fa-camera"></i>
                  <span>Añadir imágenes</span>
                </div>
              </label>
              {selectedImages.length > 0 && (
                <div className="selected-files-list">
                  {selectedImages.map((file, index) => (
                    <div key={index} className="selected-file">
                      <i className="fa fa-check-circle text-success"></i>
                      <span>{file.name}</span>
                      <button type="button" className="remove-file" onClick={() => removeImage(index)}>
                        <i className="fa fa-times"></i>
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="upload-option">
              <label className="upload-box">
                <input
                  type="file"
                  accept=".pdf,.doc,.docx, .xls, .xlsx"
                  onChange={handleDocumentChange}
                  multiple
                  style={{ display: 'none' }}
                />
                <div className="upload-content">
                  <i className="fa fa-file"></i>
                  <span>Añadir documentos</span>
                </div>
              </label>
              {selectedDocuments.length > 0 && (
                <div className="selected-files-list">
                  {selectedDocuments.map((file, index) => (
                    <div key={index} className="selected-file">
                      <i className="fa fa-check-circle text-success"></i>
                      <span>{file.name}</span>
                      <button type="button" className="remove-file" onClick={() => removeDocument(index)}>
                        <i className="fa fa-times"></i>
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="form-group mb-4">
          <label>¿Cuál es el nuevo riesgo de esta parcela?</label>
          <div style={{ width: '50%' }}>
            <Controller
              name="nuevo_riesgo"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={riskOptions}
                  isLoading={loadingRisks}
                  placeholder="Seleccionar nivel de riesgo"
                  className={errors.nuevo_riesgo ? 'is-invalid' : ''}
                  classNamePrefix="select"
                  styles={colourStyles}
                  isClearable
                />
              )}
            />
          </div>
          {errors.nuevo_riesgo && <div className="invalid-feedback">{errors.nuevo_riesgo.message}</div>}
        </div>

        <div className="form-actions">
          <button
            type="submit"
            className="btn btn-outline btn-sirio"
            style={{ borderRadius: '20px 20px 20px 20px', paddingLeft: 24, paddingRight: 24 }}>
            Enviar
          </button>
          <button type="button" className="btn-cancel" onClick={() => setFormActive(false)}>
            Cancelar
          </button>
        </div>
      </form>
    </BlockUi>
  );
};

export default EudrForm;
