import React, { useState, useEffect, createRef } from "react";
import { Link } from "react-router-dom";
import CardContainer from "../../components/card/card-container.jsx";
import Api from "../../api/global.service";
import { useAuth } from "../../components/content/auth.jsx";
import env from "../../environment.js";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { useCoopConfig } from "../../components/content/coop-config.jsx";
import GraficaSensorial from "./components/grafica-sensorial.jsx";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {getCalidadLS} from './components/helpers.js';
import {ReactHookFormInput} from "../../nuup/componentes/Forms/ReactHookFormInput.jsx";

const CalidadAnalisisSensorial = () => {
  const MSG_REQUIRED = "Información obligatoria";
  const MSG_BET_6_10 = "Se espera un valor entre 6 y 10";
  const MSG_2_DEC = "Solo se permiten dos decimales";
  const MSG_INTERVAL = "Solo se permiten decimales .00|.25|.50|.75";
  const queryParameters = new URLSearchParams(window.location.search)
  const history = useHistory();
  const auth = useAuth();
  const coopConfig = useCoopConfig();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [ciclo, setCiclo] = useState({});
  const [blocking, setBlocking] = useState(false);
  const [btnLotes, setBtnLotes] = useState(true);
  const [idsSeleccionados, setIdsSeleccionados] = useState([]);

  const [formData] = useState({
    id_calidad_analisis: getCalidadLS('id_calidad_analisis'),
    id_calidad_analisis_fisico: getCalidadLS('id_calidad_analisis_fisico'),
    id_calidad_analisis_sensorial: getCalidadLS('id_calidad_analisis_sensorial'),
    perfil_taza: "",
    fragancia_aroma: "",
    fragancia_aroma_desc: "",
    sabor: "",
    sabor_desc: "",
    sabor_residual: "",
    sabor_residual_desc: "",
    acidez: "",
    acidez_desc: "",
    cuerpo: "",
    cuerpo_desc: "",
    balance: "",
    balance_desc: "",
    uniformidad: "",
    uniformidad_desc: "",
    taza_limpia: "",
    taza_limpia_desc: "",
    dulzor: "",
    dulzor_desc: "",
    catador: "",
    catador_desc: "",
    descripcion: "",
  });

  const [dataGrafica,setDataGrafica] = useState(formData);

  const validacionDecimales = () => {
    return yup
    .number()
    .min(6.00, MSG_BET_6_10)
    .max(10.00, MSG_BET_6_10)
    .transform((value) => (isNaN(value) ? undefined : value))
    .test(
      "maxTwoDecimals",
      MSG_2_DEC,
      (number) => /^\d+(\.\d{1,2})?$/.test(number)
    )
    .test(
      "interval",
      MSG_INTERVAL,
      (number) => /^\d*\.?((25)|(50)|(5)|(75)|(0)|(00))?$/.test(number)
    )
    .required(MSG_REQUIRED);
  };


  const schema = yup.object().shape({
    perfil_taza: yup.string().required(MSG_REQUIRED),
    fragancia_aroma: validacionDecimales(),
    fragancia_aroma_desc: yup.string().nullable(),
    sabor: validacionDecimales(),
    sabor_desc: yup.string().nullable(),
    sabor_residual: validacionDecimales(),
    sabor_residual_desc: yup.string().nullable(),
    acidez: validacionDecimales(),
    acidez_desc: yup.string().nullable(),
    cuerpo: validacionDecimales(),
    cuerpo_desc: yup.string().nullable(),
    balance: validacionDecimales(),
    balance_desc: yup.string().nullable(),
    uniformidad: validacionDecimales(),
    uniformidad_desc: yup.string().nullable(),
    taza_limpia: validacionDecimales(),
    taza_limpia_desc: yup.string().nullable(),
    dulzor: validacionDecimales(),
    dulzor_desc: yup.string().nullable(),
    catador: validacionDecimales(),
    catador_desc: yup.string().nullable(),
    descripcion: yup.string().nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    getValues,
    clearErrors,
    setError,
    reset
  } = useForm({
    resolver: async (data, context, options) => {
      return yupResolver(schema)(data, context, options);
    },
    mode: "all",
    defaultValues: formData,
  });

  const setFilter = (value) => {
    setCiclo(value.target);
  };


	const validateHandler = () => {
    let data = getValues();

		schema.validate(data, { abortEarly: false })
			.then(function (valid) {
        sendData(data);
        clearErrors();
			}).catch(function (err) {
				err.inner.reduce(function (map, obj) {
          setError(obj.path,{message : obj.message});
					map[obj.path] = obj.message;
					return map;
				}, {});
				setBlocking(false);
			});

	}

  const sendData = (data) => {
    let id_calidad_analisis_sensorial = getCalidadLS('id_calidad_analisis_sensorial');
    if(id_calidad_analisis_sensorial.length === 36){
      Api.update('calidad/analisis/sensorial', id_calidad_analisis_sensorial ,data).then((response) => {
        setBlocking(false);
        history.push({pathname: '/admin/calidad/analisis/sensorial/info',});
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log(e);
        setBlocking(false);
        Swal.fire({
          icon: 'error',
          title: '',
          text: e.response.data.message,
        });
      });
    }else{
      Api.create('calidad/analisis/sensorial', data).then((response) => {
        setBlocking(false);
        let configAnalisis = JSON.parse(localStorage.getItem(`${env.appName}-calidad`));
        configAnalisis.id_calidad_analisis_sensorial = response.data.data.id_calidad_analisis_sensorial;
        localStorage.setItem(`${env.appName}-calidad`, JSON.stringify(configAnalisis));
        history.push({pathname: '/admin/calidad/analisis/sensorial/info',});
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log(e);
        setBlocking(false);
        Swal.fire({
          icon: 'error',
          title: '',
          text: e.response.data.message,
        });
      });
    }
  };

  useEffect(() => {
    watch(values => {
      setDataGrafica(values);
    });
  }, [watch]);


  const getData = (id_calidad_analisis_sensorial) => {
    Api.get('calidad/analisis/sensorial',id_calidad_analisis_sensorial).then((response) => {
      setBlocking(false);
      if (typeof response.data !== "undefined") {
        reset(response.data[0]);
        setData(response.data[0]);
      }
      setBlocking(false);
    })
    .catch((e) => {
      console.log("e", e);
      setBlocking(false);
    });
  }

  useEffect(() => {
    let id_calidad_analisis_sensorial  = getValues().id_calidad_analisis_sensorial;
    if(id_calidad_analisis_sensorial.length === 36){
      getData(id_calidad_analisis_sensorial);
    }
  }, []);

  return (
    <div>
      <div>
        <button
          className="btn btn-outline-dark mt-3"
          type="button"
          onClick={() => history.push({pathname: '/admin/calidad/analisis/fisico/info',})}
           >
          <i className="fa fa-arrow-left"></i>
          {" Volver a análisis físico"}
        </button>
      </div>

      <CardContainer height={"100%"} style={{ marginTop: 10 }}>
        <h4>{"Registro de análisis sensorial"}</h4>

        <div className="row">
          <div className="col-md-6 col-xl-6">
            
          <div className="row mt-3">
            <div className="col-xs-12 col-xl-3">
            <h5>{"Perfil de la taza"}</h5>
              </div>
              <div className="col-md-12 col-xl-9">
                <ReactHookFormInput
                  type="text"
                  id="perfil_taza"
                  name="perfil_taza"
                  label="Descripción"
                  error={errors.perfil_taza}
                  control={control}
                  value={getValues().perfil_taza}
                />
              </div>

          </div>


          <div className="row mt-3">
              <div className="col-xs-12 col-md-12 col-xl-3 col-xxl-3">
              <h5>{"Fragancia / aroma"}</h5>
              </div>
              <div className="col-xs-4 col-xl-4 col-xxl-3">
                <ReactHookFormInput
                  type="number"
                  id="fragancia_aroma"
                  name="fragancia_aroma"
                  label="Puntuación"
                  error={errors.fragancia_aroma}
                  control={control}
                  step={0.25}
                  value={getValues().fragancia_aroma}
                />
              </div>
              <div className="col-xs-6 col-md-8 col-xl-5 col-xxl-6">
                <ReactHookFormInput
                  type="text"
                  id="fragancia_aroma_desc"
                  name="fragancia_aroma_desc"
                  label="Descripción"
                  error={errors.fragancia_aroma_desc}
                  control={control}
                  value={getValues().fragancia_aroma_desc}
                />
              </div>
            </div>


            <div className="row mt-3">
            <div className="col-xs-12 col-md-12 col-xl-3 col-xxl-3">
            <h5>{"Sabor"}</h5>
                </div>
                <div className="col-xs-4 col-xl-4 col-xxl-3">
                <ReactHookFormInput
                  type="number"
                  id="sabor"
                  name="sabor"
                  label="Puntuación"
                  error={errors.sabor}
                  control={control}
                  step="0.25"
                  value={getValues().sabor}
                />
              </div>
              <div className="col-xs-6 col-md-8 col-xl-5 col-xxl-6">
                <ReactHookFormInput
                  type="text"
                  id="sabor_desc"
                  name="sabor_desc"
                  label="Descripción"
                  error={errors.sabor_desc}
                  control={control}
                  value={getValues().sabor_desc}
                />
              </div>
            </div>

            <div className="row mt-3">
            <div className="col-xs-12 col-md-12 col-xl-3 col-xxl-3">
            <h5>{"Sabor residual"}</h5>
              </div>
              <div className="col-xs-4 col-xl-4 col-xxl-3">
                <ReactHookFormInput
                  type="number"
                  id="sabor_residual"
                  name="sabor_residual"
                  label="Puntuación"
                  error={errors.sabor_residual}
                  control={control}
                  step="0.25"
                  value={getValues().sabor_residual}
                />
              </div>
              <div className="col-xs-6 col-md-8 col-xl-5 col-xxl-6">
                <ReactHookFormInput
                  type="text"
                  id="sabor_residual_desc"
                  name="sabor_residual_desc"
                  label="Descripción"
                  error={errors.sabor_residual_desc}
                  control={control}
                  value={getValues().sabor_residual_desc}
                />
              </div>
            </div>


            <div className="row mt-3">
            <div className="col-xs-12 col-md-12 col-xl-3 col-xxl-3"><h5>{"Acidez"}</h5></div>
            <div className="col-xs-4 col-xl-4 col-xxl-3">
                <ReactHookFormInput
                  type="number"
                  id="acidez"
                  name="acidez"
                  label="Puntuación"
                  error={errors.acidez}
                  control={control}
                  step="0.25"
                  value={getValues().acidez}
                />
              </div>
              <div className="col-xs-6 col-md-8 col-xl-5 col-xxl-6">
                <ReactHookFormInput
                  type="text"
                  id="acidez_desc"
                  name="acidez_desc"
                  label="Descripción"
                  error={errors.acidez_desc}
                  control={control}
                  value={getValues().acidez_desc}
                />
              </div>
            </div>

            <div className="row mt-3">
            <div className="col-xs-12 col-md-12 col-xl-3 col-xxl-3"><h5>{"Cuerpo"}</h5></div>
            <div className="col-xs-4 col-xl-4 col-xxl-3">
                <ReactHookFormInput
                  type="number"
                  id="cuerpo"
                  name="cuerpo"
                  label="Puntuación"
                  error={errors.cuerpo}
                  control={control}
                  step="0.25"
                  value={getValues().cuerpo}
                />
              </div>
              <div className="col-xs-6 col-md-8 col-xl-5 col-xxl-6">
                <ReactHookFormInput
                  type="text"
                  id="cuerpo_desc"
                  name="cuerpo_desc"
                  label="Descripción"
                  error={errors.cuerpo_desc}
                  control={control}
                  value={getValues().cuerpo_desc}
                />
              </div>
            </div>


            <div className="row mt-3">
            <div className="col-xs-12 col-md-12 col-xl-3 col-xxl-3"><h5>{"Balance"}</h5></div>
            <div className="col-xs-4 col-xl-4 col-xxl-3">
                <ReactHookFormInput
                  type="number"
                  id="balance"
                  name="balance"
                  label="Puntuación"
                  error={errors.balance}
                  control={control}
                  step="0.25"
                  value={getValues().balance}
                />
              </div>
              <div className="col-xs-6 col-md-8 col-xl-5 col-xxl-6">
                <ReactHookFormInput
                  type="text"
                  id="balance_desc"
                  name="balance_desc"
                  label="Descripción"
                  error={errors.balance_desc}
                  control={control}
                  value={getValues().balance_desc}
                />
              </div>
            </div>
            <div className="row mt-3">
            <div className="col-xs-12 col-md-12 col-xl-3 col-xxl-3"><h5>{"Uniformidad"}</h5></div>
            <div className="col-xs-4 col-xl-4 col-xxl-3">
                <ReactHookFormInput
                  type="number"
                  id="uniformidad"
                  name="uniformidad"
                  label="Puntuación"
                  error={errors.uniformidad}
                  control={control}
                  step="0.25"
                  value={getValues().uniformidad}
                />
              </div>
              <div className="col-xs-6 col-md-8 col-xl-5 col-xxl-6">
                <ReactHookFormInput
                  type="text"
                  id="uniformidad_desc"
                  name="uniformidad_desc"
                  label="Descripción"
                  error={errors.uniformidad_desc}
                  control={control}
                  value={getValues().uniformidad_desc}
                />
              </div>
            </div>

            <div className="row mt-3">
            <div className="col-xs-12 col-md-12 col-xl-3 col-xxl-3"><h5>{"Taza limpia"}</h5></div>
            <div className="col-xs-4 col-xl-4 col-xxl-3">
                <ReactHookFormInput
                  type="number"
                  id="taza_limpia"
                  name="taza_limpia"
                  label="Puntuación"
                  error={errors.taza_limpia}
                  control={control}
                  step="0.25"
                  value={getValues().taza_limpia}
                />
              </div>
              <div className="col-xs-6 col-md-8 col-xl-5 col-xxl-6">
                <ReactHookFormInput
                  type="text"
                  id="taza_limpia_desc"
                  name="taza_limpia_desc"
                  label="Descripción"
                  error={errors.taza_limpia_desc}
                  control={control}
                  value={getValues().taza_limpia_desc}
                />
              </div>
            </div>

            <div className="row mt-3">
            <div className="col-xs-12 col-md-12 col-xl-3 col-xxl-3"><h5>{"Dulzor"}</h5></div>
            <div className="col-xs-4 col-xl-4 col-xxl-3">
                <ReactHookFormInput
                  type="number"
                  id="dulzor"
                  name="dulzor"
                  label="Puntuación"
                  error={errors.dulzor}
                  control={control}
                  step="0.25"
                  value={getValues().dulzor}
                />
              </div>
              <div className="col-xs-6 col-md-8 col-xl-5 col-xxl-6">
                <ReactHookFormInput
                  type="text"
                  id="dulzor_desc"
                  name="dulzor_desc"
                  label="Descripción"
                  error={errors.dulzor_desc}
                  control={control}
                  value={getValues().dulzor_desc}
                />
              </div>
            </div>

            <div className="row mt-3">
            <div className="col-xs-12 col-md-12 col-xl-3 col-xxl-3"><h5>{"Catador"}</h5></div>
            <div className="col-xs-4 col-xl-4 col-xxl-3">
                <ReactHookFormInput
                  type="number"
                  id="catador"
                  name="catador"
                  label="Puntuación"
                  error={errors.catador}
                  control={control}
                  step="0.25"
                  value={getValues().catador}
                />
              </div>
              <div className="col-xs-6 col-md-8 col-xl-5 col-xxl-6">
                <ReactHookFormInput
                  type="text"
                  id="catador_desc"
                  name="catador_desc"
                  label="Descripción"
                  error={errors.catador_desc}
                  control={control}
                  value={getValues().catador_desc}
                />
              </div>
            </div>

            <div className="row mt-2">
            <div className="col-xs-12 col-xl-12">
            <ReactHookFormInput
              type="text"
              id="descripcion"
              name="descripcion"
              label="Descripción / notas adicionales"
              error={errors.descripcion}
              control={control}
              value={getValues().descripcion}
              multiline
            />
          </div>
        </div>

          </div>
          <div className="col-md-6 col-xl-6">
              <GraficaSensorial data={dataGrafica} />
          </div>
        </div>




        <div className="row mt-5">
          <div className="col-xs-3 col-xl-1 col-xxl-1">
            <button
              onClick={() => validateHandler()}
              style={{
                borderRadius: "20px 20px 20px 20px",
                color: "#333",
                fontWeight: "normal",
                fontSize: 16,
              }}
              className="btn btn-sirio"
            >
              Continuar
            </button>

          </div>

        </div>
      </CardContainer>
    </div>
  );
};

export default CalidadAnalisisSensorial;
