import { differenceInDays, isValid } from 'date-fns';

/**
 * IMPORTANT BEFORE UPDATING CODE!!!!
 * ANY CHANGE IN THIS FILE SHOULD BE DONE IN sirio_app AND sirio_admin equally to avoid errors.
 * VERSION 1.4
 */

const AVG_DAYS_PER_MONTH = 30;
const CLEAR_ZEROES = 10000;

// Forces new dates at midnight to have accurate day differences
const dateParser = (string = null) => {
  let parsedDate;
  if (string) {
    parsedDate = new Date(string);
    // add 24 hours assuming we are in negative time offset
    parsedDate.setHours(24, 0, 0, 0);
  } else {
    parsedDate = new Date();
    parsedDate.setHours(0, 0, 0, 0);
  }
  return parsedDate;
};

/**
 * Debt Calculator
 */
class DebtCalculator {
  /**
   *
   * @param {{
   *  credito: {
   *    fecha_ejecucion: string,
   *    cantidad_anticipo: number,
   *  },
   *  interestRate: number,
   *  pagos: [{
   *    cantidad_pago: number,
   *    fecha_ejecucion: string,
   *    updated_at: string
   *  }],
   *  stringDate: string
   * }} params
   */
  constructor({ credito, interestRate, pagos, stringDate }) {
    this.startDate = dateParser(credito.fecha_ejecucion);
    this.currentDate = this.startDate;
    this.pendingCapital = +credito.cantidad_anticipo;
    this.interestRate = +interestRate;
    this.totalInterest = 0;
    this.totalPayment = 0;
    this.pagos = this._clearZeroPayments(pagos);
    this.deuda = [];
    this.debtTodayDate = stringDate ? dateParser(stringDate) : dateParser();
    this.debtTodayDateString = stringDate;
  }

  _clearZeroPayments(pagos) {
    return pagos.filter(p => +p.cantidad_pago > 0);
  }

  _rounderTwoDecimals(value) {
    return Number((Math.round(value * 100) / 100).toFixed(2));
  }

  _getDaysPassed(currentDate, startDate) {
    const days = differenceInDays(currentDate, startDate);
    return days > 0 ? days : 0;
  }

  _accumulatedInterest(days) {
    if (this.pendingCapital <= 0.01) {
      return 0;
    }
    return this._rounderTwoDecimals((days * this.interestRate * this.pendingCapital) / (AVG_DAYS_PER_MONTH * CLEAR_ZEROES));
  }

  _getInterest({ currentDate, dateString, payment = 0 }) {
    let days = 0;
    let interest = 0;
    if (isValid(currentDate)) {
      days = this._getDaysPassed(currentDate, this.currentDate);
      this.currentDate = currentDate;
      interest = this._accumulatedInterest(days);
      this.totalInterest += interest;
      this.totalPayment += payment;
      this.pendingCapital = this._rounderTwoDecimals(this.pendingCapital + interest - payment);
    }
    return {
      payment: payment,
      date: currentDate.toString(),
      dateString: dateString,
      daysPassed: days,
      totalDaysPassed: this._getDaysPassed(currentDate, this.startDate),
      interest: this._rounderTwoDecimals(interest),
      pendingCapital: this._rounderTwoDecimals(this.pendingCapital),
      totalInterest: this._rounderTwoDecimals(this.totalInterest),
      totalPayment: this._rounderTwoDecimals(this.totalPayment),
    };
  }

  _cleanAndSortPagos() {
    this.pagos = this.pagos
      .map(p => {
        return {
          ...p,
          dateString: p.fecha_ejecucion,
          fecha_ejecucion: p.fecha_ejecucion ? p.fecha_ejecucion : p.updated_at,
        };
      })
      .sort((a, b) => dateParser(a.fecha_ejecucion) - dateParser(b.fecha_ejecucion));
  }

  getDebt() {
    let debtDetail = [];
    if (isValid(this.currentDate)) {
      this._cleanAndSortPagos();
      debtDetail = this.pagos.map(p => {
        return {
          ...this._getInterest({
            payment: this._rounderTwoDecimals(+p.cantidad_pago),
            currentDate: dateParser(p.fecha_ejecucion),
            dateString: p.dateString,
          }),
          pago_id: p.id,
        };
      });
    }

    return {
      debtDetail: debtDetail,
      debtToday: this._getInterest({
        currentDate: this.debtTodayDate,
        dateString: this.debtTodayDateString,
      }),
      interestRate: this.interestRate,
      startDate: this.startDate.toString(),
    };
  }
}

export default DebtCalculator;
