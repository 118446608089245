import React, { useState, useEffect, createRef, useCallback, useMemo } from 'react';

import Swal from 'sweetalert2';

import Api from '../../../api/global.service.js';
import LabelNumber from '../../../components/label/label-number.jsx';
import ModalEfectivo from '../../../components/modal/modal-efectivo.jsx';
import { useAuth } from '../../../components/content/auth.jsx';
import NoPagination from '../../../components/pagination/noPagination.jsx';
import CheckboxList from '../../../components/forms/checkbox-list.jsx';
import { subscribe, unsubscribe } from '../../../components/events.js';
import { useCoopConfig } from '../../../components/content/coop-config.jsx';
import useEfectivo from '../../../hooks/useEfectivo.jsx'
import SearchColumn from '../../../components/search/search-column.jsx';

const EntregaEfectivoPorMovimientoTable = ({ ciclo, update, data, originalData, setData, setOriginalData }) => {
  const auth = useAuth();
  const coopConfig = useCoopConfig();
  const [blocking, setBlocking] = useState(true);
  const [blockingMovements, setBlockingMovements] = useState(true);
  const [edit, setEdit] = useState(null);
  const [totalPagado, setTotalPagado] = useState(0);
  const [totalDesembolsado, setTotalDesembolsado] = useState(100);
  const [percentagePayedSent, setPercentagePayedSent] = useState(0);
  const [idSeleccionado, setIdSeleccionado] = useState(null);
  const [dataSeleccionado, setDataSeleccionado] = useState(null);

  const {
    getBackgroundColor,
    getColor,
    handleMovimientosCheck,
    generateDynamicColumns
  } = useEfectivo({ ciclo, auth });

  const dialog = createRef();
  
  const onClickEdit = (event, data) => {
    event.preventDefault();
    setEdit(data);
  };

  const onShowModal = () => dialog.current.add();

  useEffect(() => {
    subscribe('Efectivo::onSave', e => handleSave(e));
    return () => {
      unsubscribe('Efectivo::onSave');
    };
  }, []);

  useEffect(() => {
    if (edit !== null) {      
      dialog.current.edit(edit);
    }
    return () => {
      setEdit(null);
      setIdSeleccionado(null);
      setDataSeleccionado(null);
    };
  }, [edit]);
  
  const baseColumns = React.useMemo(
    () => [
      {
        Header: 'INFORMACIÓN GENERAL',
        columns: [
          {
            Header: 'ID',
            accessor: 'id',
            sortable: true,
            minWidth: 50,
            width: 80,
          },
          {
            Header: 'CENTRO DE ACOPIO',
            accessor: 'nombre',
            sortable: true,
            minWidth: 150,
          },
          {
            Header: 'DESEMBOLSO EFECTIVO',
            accessor: 'desembolsos_sum_cantidad',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'TOTAL PAGADO',
            accessor: 'acopio_pagos_sum_total',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'SALDO',
            accessor: 'saldo',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
        ],
      },
    ],
    [data, update]
  );
  
  const [dynamicColumnsData, setDynamicColumnsData] = useState([]);
  
  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'nombre', label: 'CENTRO DE ACOPIO' },
      { value: 2, target: 'desembolsos_sum_cantidad', label: 'DESEMBOLSO EFECTIVO' },
      { value: 3, target: 'acopio_pagos_sum_total', label: 'TOTAL PAGADO A PRODUCTORES' },
      { value: 4, target: 'saldo', label: 'SALDO' },
    ],
    []
  );
  
  const updateData = useCallback(async (id_ciclo) => {
    setBlocking(true);
    setBlockingMovements(true);
    
    try {
      const response = await Api.Acopio.search(`efectivo_por_centro_acopio/${id_ciclo}`, {
        filter: [{ column: 'id_cooperativa', operator: '=', value: auth.user.id_cooperativa }],
      });

      const totalSent = response.data.reduce((current, sum) => current + +sum.desembolsos_sum_cantidad, 0);
      const totalPayed = response.data.reduce((current, sum) => current + +sum.acopio_pagos_sum_total, 0);
      const avance_anticipos = parseInt(+totalSent !== 0 ? (totalPayed * 100) / totalSent : 0);
      
      setTotalDesembolsado(totalSent);
      setTotalPagado(totalPayed);
      setPercentagePayedSent(avance_anticipos);
      
      setData(response.data);
      setOriginalData(response.data);
      setDynamicColumnsData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setBlocking(false);
      setBlockingMovements(false);
    }
  }, [auth.user.id_cooperativa]);

  const dynamicColumns = useMemo(() => {
    if (!dynamicColumnsData.length) return baseColumns;
    return generateDynamicColumns(
      dynamicColumnsData,
      baseColumns,
      auth,
      coopConfig,
      onClickEdit
    );
  }, [dynamicColumnsData, baseColumns]);

  useEffect(() => {
    if (ciclo?.id) {
      updateData(ciclo.id);
    }
  }, [ciclo]);

  useEffect(() => {
    setDynamicColumnsData(prevColumns => {
      // para actualizar el checkbox
      const updatedColumns = [...prevColumns];
      if (updatedColumns[0]?.columns?.[0]?.id === 'check') {
        updatedColumns[0].columns[0] = {
          ...updatedColumns[0].columns[0],
          Cell: ({ row }) => (
            <div className="form-check">
              <CheckboxList
                name="movimiento"
                value={row.original.id}
                handleCheck={event => handleMovimientosCheck(event, row.original)}
                checked={idSeleccionado === Number(row.original.id)}
              />
            </div>
          ),
        };
      }
      return updatedColumns;
    });
  }, [idSeleccionado]);

  const setDataFilter = data => {
    setData(data);
  };

  const handleSave = useCallback(event => {
    Swal.fire({
      icon: 'success',
      title: '',
      text: `Se guardó con éxito el desembolso de efectivo ${event.detail.id}.`,
    });

      updateData(event.detail.id_ciclos);
  }, []);

  return (
    <div>
      <ModalEfectivo ref={dialog} title="desembolso de efectivo"></ModalEfectivo>
        <div className="row mb-2">
          <div className="col-10">
            <SearchColumn
              data={originalData}
              columns={searchColumns}
              setDataFilter={evt => {
                setDataFilter(evt);
              }}
            />
          </div>
          <div className="col-2">
            {auth.hasPermission('ACOPIOS_ENTREGAS_EFECTIVO_NUEVO') && (
              <button onClick={onShowModal} type="button" className="btn btn-sirio pr-4 pl-4" style={{ borderRadius: '20px 20px 20px 20px' }}>
                <i className="fa fa-plus mr-2"></i> Nuevo
              </button>
            )}
        </div>
        </div>

    <NoPagination
      blocking={blocking}
      selected="id"
      columns={dynamicColumns}
      data={data}
      fnBackgroundColor={getBackgroundColor}
      fnColor={getColor}
    />
    </div>
  );
};

export default EntregaEfectivoPorMovimientoTable;
