import React, { useState } from 'react';
// import { useTable, useSortBy, usePagination, useFilters } from 'react-table';
import { Link } from 'react-router-dom';
// import LinkDownload from '../../components/link/link-download';
import CardContainer from '../../components/card/card-container.jsx';
import LabelCategory from '../../components/label/label-category.jsx';
import LabelNumber from '../../components/label/label-number.jsx';
import Api from '../../api/global.service';
import { useAuth } from '../../components/content/auth.jsx';
import { useHistory } from 'react-router-dom';
import env from '../../environment.js';

import SelectColumnFilter from '../../components/selectColumnFilter/SelectColumnFilter.js';
import SelectCiclo from '../../components/select/select-ciclo.jsx';
import SearchColumn from '../../components/search/search-column.jsx';
import CuentasListExport from './cuentas-list-export.js';
import { useCoopConfig } from '../../components/content/coop-config.jsx';
import transformWord from '../../utils/transformWord.js';
import NoPagination from '../../components/pagination/noPagination.jsx';
import useEtiquetas from '../../hooks/useEtiquetas.jsx';
import insertIf from '../../utils/insertIf.js';

const CuentasList = () => {
  const auth = useAuth();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [ciclo, setCiclo] = useState({});
  const [blocking, setBlocking] = useState(true);
  const coopConfig = useCoopConfig();
  const { etiquetas } = useEtiquetas();

  // Header: transformWord(coopConfig.config.parcelas_etiqueta, ['plural', 'uppercase']),

  const columns = React.useMemo(
    () => [
      {
        Header: 'CLAVE',
        accessor: 'clave_tx',
        sortable: true,
        minWidth: 60,
      },
      {
        Header: 'CÓDIGO',
        accessor: 'codigo_tx',
        sortable: true,
        minWidth: 80,
        show: !!+coopConfig.config?.usa_codigo_tx,
      },
      {
        Header: 'NOMBRE PRODUCTOR',
        accessor: 'nombre',
        sortable: true,
        Cell: ({ value, row }) => (
          <Link to={{ pathname: `/admin/cuentas/perfil/${row.original.uuid}` }} className="m-r-2" style={{ color: '#789D16' }}>
            <span>{value}</span>
          </Link>
        ),
      },
      {
        Header: 'REGIÓN',
        accessor: 'cuenta_region',
        sortable: true,
      },
      {
        Header: 'MUNICIPIO',
        accessor: 'municipio',
        sortable: true,
      },
      {
        Header: 'COMUNIDAD',
        accessor: 'localidad',
        sortable: true,
      },
      {
        Header: 'CATEGORÍA',
        accessor: 'cuenta_categoria',
        sortable: true,
        Cell: ({ value, row }) => <LabelCategory label={value} color={row.original.cuenta_categoria_color}></LabelCategory>,
        minWidth: 90,
      },
      {
        Header: 'N° F. SIC',
        accessor: 'total_tx_sic',
        sortable: true,
        minWidth: 30,
        show: !!+coopConfig.config?.usa_sic,
        Cell: ({ value }) => {
          return <LabelNumber value={value}></LabelNumber>;
        },
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: 'N° F. PO',
        accessor: 'total_tx_po',
        sortable: true,
        minWidth: 30,
        show: !!+coopConfig.config?.usa_sic,
        Cell: ({ value }) => {
          return <LabelNumber value={value}></LabelNumber>;
        },
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: 'N° ' + transformWord(etiquetas?.parcela, ['plural', 'uppercase']),
        accessor: 'total_parcelas',
        sortable: true,
        minWidth: 60,
        Cell: ({ value }) => {
          return <LabelNumber value={value}></LabelNumber>;
        },
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: 'N° ' + transformWord(etiquetas?.planta, ['plural', 'uppercase']),
        accessor: 'total_plantas',
        sortable: true,
        minWidth: 60,
        show: +coopConfig.config?.cooperativa?.producto?.id === env.productos.MIEL,
        Cell: ({ value }) => {
          return <LabelNumber value={value}></LabelNumber>;
        },
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: 'CICLO',
        accessor: 'ciclo',
        sortable: true,
        minWidth: 60,
        Cell: ({ value }) => {
          return (
            <p className="text-right" style={{ margin: 0 }}>
              {value}
            </p>
          );
        },
      },
    ],
    [coopConfig, etiquetas]
  );

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'clave_tx', label: 'CLAVE' },
      ...insertIf(+coopConfig.config?.usa_codigo_tx, { value: 2, target: 'codigo_tx', label: 'CÓDIGO' }),
      { value: 3, target: 'nombre', label: 'PRODUCTOR' },
      { value: 4, target: 'cuenta_region', label: 'REGIÓN' },
      { value: 5, target: 'municipio', label: 'MUNICIPIO' },
      { value: 6, target: 'localidad', label: 'COMUNIDAD' },
      { value: 7, target: 'cuenta_categoria', label: 'CATEGORÍA' },
      ...insertIf(+coopConfig.config?.usa_sic, { value: 8, target: 'total_tx_sic', label: 'N° F. SIC' }),
      ...insertIf(+coopConfig.config?.usa_sic, { value: 9, target: 'total_tx_po', label: 'N° F. PO' }),
      { value: 10, target: 'total_parcelas', label: 'N° PARCELAS' },
      { value: 11, target: 'ciclo', label: 'CICLO' },
    ],
    []
  );

  const setFilter = value => {
    setCiclo(value.target);

    setBlocking(true);
    Api.search('cuentas', {
      filter: [
        { column: 'id_ciclo', operator: '=', value: value.target.id },
        { column: 'confirmado', operator: '=', value: 1 },
      ],
      sort: [{ column: 'clave_tx', sort: 'asc' }],
    })
      .then(response => {
        response.data = response.data.map(function(item, i) {
          return {
            ...item,
            _no: i + 1,
          };
        });
        setData(response.data);
        setOriginalData(response.data);
        setBlocking(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('e', e);
        setBlocking(false);
      });
  };

  const setDataFilter = data => {
    setData(data);
  };

  return (
    <div>
      <div className="row justify-content-between">
        <SelectCiclo
          setFilter={evt => {
            setFilter(evt);
          }}></SelectCiclo>

        <div className="col-8 d-flex justify-content-end">
          {auth.hasPermission('ACOPIOS_ENTREGAS_X_PRODUCTOR_NUEVO') && (
            <button
              onClick={() => history.push(`/admin/cuentas/agregar`, { redirect: '/admin/cuentas/list' })}
              type="button"
              className="btn btn-outline-secondary d-flex flex-row"
              style={{ marginBottom: 15, color: '#374E00' }}>
              <i className="fa fa-plus" style={{ color: '#374E00', alignSelf: 'center' }}></i>
              <p style={{ color: '#374E00', marginLeft: 5, alignSelf: 'center', marginBottom: 0 }}>Nuevo productor</p>
            </button>
          )}
          {ciclo?.uuid && auth.hasPermission('CUENTAS_EXPORTAR') && (
            <div style={{ alignSelf: 'center', marginBottom: 12, marginLeft: 12 }}>
              {data.length > 0 && (
                <Link
                  to="#"
                  onClick={() => CuentasListExport(data, coopConfig?.config?.cooperativa?.nombre)}
                  className="m-r-2"
                  style={{ color: '#789D16' }}>
                  <img src={env.images.icon.xls} className="pr-1" /> <span>Descargar Excel</span>
                </Link>
              )}
            </div>
          )}
        </div>
      </div>

      <CardContainer height={'100%'}>
        <div className="row">
          <div className="col-8">
            <SearchColumn
              data={originalData}
              columns={searchColumns}
              setDataFilter={evt => {
                setDataFilter(evt);
              }}></SearchColumn>
          </div>
        </div>

        <NoPagination blocking={blocking} columns={columns} data={data}></NoPagination>
      </CardContainer>
    </div>
  );
};

export default CuentasList;
