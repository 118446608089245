import React, { useState } from 'react';
import CardBasic from '../../components/card/card-basic.jsx';
import Api from '../../api/global.service';
import { useAuth } from '../../components/content/auth.jsx';
import BlockUi from 'react-block-ui';
import SelectCertificacion from '../../components/select/select-certificacion.jsx';

const CsvList = () => {
  const auth = useAuth();

  const [certificacion, setCertificacion] = useState('empty');
  const [data, setData] = useState([]);
  const [blocking, setBlocking] = useState(false);
  const [cardHeight] = useState(170);

  const setFilter = value => {
    setCertificacion(value);
    setBlocking(true);
    Api.search('csv_estadisticas', {
      filter: [{ column: 'certificacion', operator: '=', value: value }],
    })
      .then(response => {
        setData(response.data[0]);
        setBlocking(false);
      })
      .catch(e => {
        setBlocking(false);
      });
  };

  return (
    <div>
      <div className="mb-3">
        <SelectCertificacion
          setFilter={evt => {
            setFilter(evt);
          }}></SelectCertificacion>
      </div>

      <div className="row pb-4">
        <div className="col-xl-3">
          <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
            <CardBasic
              height={cardHeight}
              label="Cooperativas"
              value={(data.total_cooperativas || 0).toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
              link={auth.hasPermission('CSV_EXPORTAR') ? `kobo/datos/cooperativas/${certificacion}` : null}></CardBasic>
          </BlockUi>
        </div>
        <div className="col-xl-3">
          <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
            <CardBasic
              height={cardHeight}
              label="Inspectores"
              value={(data.total_inspectores || 0).toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
              link={auth.hasPermission('CSV_EXPORTAR') ? `kobo/datos/inspectores/${certificacion}` : null}></CardBasic>
          </BlockUi>
        </div>
        <div className="col-xl-3">
          <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
            <CardBasic
              height={cardHeight}
              label="Cuentas"
              value={(data.total_cuentas || 0).toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
              link={auth.hasPermission('CSV_EXPORTAR') ? `kobo/datos/cuentas/${certificacion}` : null}></CardBasic>
          </BlockUi>
        </div>
        <div className="col-xl-3">
          <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
            <CardBasic
              height={cardHeight}
              label="Parcelas"
              value={(data.total_parcelas || 0).toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
              link={auth.hasPermission('CSV_EXPORTAR') ? `kobo/datos/parcelas/${certificacion}` : null}></CardBasic>
          </BlockUi>
        </div>
      </div>

      <div className="row pb-4">
        <div className="col-xl-3">
          <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
            <CardBasic
              height={cardHeight}
              label="Arboles"
              value={(data.total_arboles || 0).toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
              link={auth.hasPermission('CSV_EXPORTAR') ? `kobo/datos/arboles` : null}></CardBasic>
          </BlockUi>
        </div>
      </div>
    </div>
  );
};

export default CsvList;
