import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react';
import Api from '../../api/global.service';
import { useAuth } from '../../components/content/auth.jsx';
import 'react-datepicker/dist/react-datepicker.css';
import * as yup from 'yup';
import { publish } from '../events';
import ModalGeneral from './modal-general';
import { usaCentrosAcopios, useCoopConfig, getIdCatCreditoPrecosecha } from '../../components/content/coop-config.jsx';
import FormSelectMaterial from '../../nuup/componentes/Forms/FormSelectMaterial.jsx';
import FormSelectMaterialApi from '../../nuup/componentes/Forms/FormSelectMaterialApi.jsx';
import TextField from '@mui/material/TextField';
import moment from 'moment';

const ModalEfectivo = forwardRef((props, ref) => {
  const auth = useAuth();

  const coopConfig = useCoopConfig();
  const [show, setShow] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [fecha, setFecha] = useState('');
  const [id, setId] = useState(0);
  const [idCentroAcopio, setIdCentroAcopio] = useState(0);
  const [monto, setMonto] = useState('');
  const [tipoMotivo, setTipoMotivo] = useState(0);
  const [idAcopiador, setIdAcopiador] = useState(0);
  const [usaCentroAcopio] = useState(usaCentrosAcopios());
  const [motivos, setMotivos] = useState([]);
  const modalRef = useRef();

  const [formData, setFormData] = useState({
    id: 0,
    cantidad: '',
    fecha_envio: '',
    motivo: '',
    observaciones: '',
  });

    useEffect(() => {
      const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
          closeHandler();
        }  
      };
  
      // const handleClickOutside = (event) => {
      //   if (modalRef.current && !modalRef.current.contains(event.target)) {
      //     closeHandler();
      //   }
      // };
  
      if (show) {
        document.addEventListener('keydown', handleKeyDown);    
//        document.addEventListener('click', handleClickOutside);
      }
  
      return () => {
  //      document.removeEventListener('click', handleClickOutside);
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [show, props]);

  useEffect(() => {
    const allMotivos = [];
    if (getIdCatCreditoPrecosecha() > 1) {
      allMotivos.push({ value: 1, label: 'Crédito pre-cosecha' });
    }
    if (coopConfig.config?.cooperativa?.producto?.nombre) {
      allMotivos.push({ value: 2, label: `Pagos de ${coopConfig.config?.cooperativa?.producto?.nombre}` });
    }
    setMotivos(prev => [...prev, ...allMotivos]);
  }, [coopConfig.config]);

  const [filterCA] = useState([
    {
      column: 'id_cooperativa',
      operator: '=',
      value: auth.user.id_cooperativa,
    },
  ]);

  const [filterAco] = useState([
    {
      column: 'id_cooperativa',
      operator: '=',
      value: auth.user.id_cooperativa,
    },
    { column: 'id_rol', operator: '=', value: 6 },
  ]);

  const [errors, setErrors] = useState(null);

  const schema = yup.object().shape({
    fecha: yup
      .date()
      .max(moment().add(12, 'M'), 'Seleccione otra fecha')
      .transform(value => (isNaN(value) ? undefined : value))
      .required('Debe seleccionar una fecha'),
    id_centro_acopio: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .when([], {
        is: () => usaCentroAcopio === 0,
        then: yup.number().nullable(),
        otherwise: yup
          .number()
          .min(1, 'Debe seleccionar un centro de acopio')
          .transform(value => (isNaN(value) ? undefined : value))
          .required('Debe seleccionar un centro de acopio'),
      }),
    cantidad: yup
      .number()
      .required('Debe escribir un cantidad')
      .positive()
      .transform(value => (isNaN(value) ? undefined : value)),
    tipo_motivo: yup
      .number()
      .min(1, 'Debe seleccionar un motivo')
      .transform(value => (isNaN(value) ? undefined : value))
      .required('Debe seleccionar un acopiador'),
    id_acopiador: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .nullable('Debe seleccionar un acopiador'),
  });

  //To call outside
  useImperativeHandle(ref, () => ({
    add: addHandler,
    edit: editHandler,
  }));

  const addHandler = () => {
    setShow(true);
    setIsNew(true);

    //Resteamos Valores
    setFecha('');
    setIdCentroAcopio(0);
    setMonto('');
    setTipoMotivo('');
    setIdAcopiador(0);
    setErrors(null);
  };

  const editHandler = data => {
    setShow(true);
    setIsNew(false);

    const editData = {
      ...formData,
      ...data,
      cantidad: data?.monto || '',
      fecha_envio: data?.fecha_envio || '',
      motivo: data?.motivo || '',
      observaciones: data?.observaciones || ''
    };
    
    setFormData(editData);
    setId(data.id);
    setFecha(data.fecha_envio);
    setIdCentroAcopio(data.id_centros_acopios);
    setMonto(data.monto);
    setTipoMotivo(data.tipo_motivo);
    setIdAcopiador(data.acopiador);
    setErrors(null);
  };

  const closeHandler = () => {
    setShow(false);
  };

  const validateHandler = () => {
    var data = {
      fecha: fecha,
      id_centro_acopio: idCentroAcopio,
      cantidad: Number(monto),
      tipo_motivo: tipoMotivo,
      id_acopiador: idAcopiador,
    };
    schema
      .validate(data, { abortEarly: false })
      .then(function() {
        //Datos validos
        const data = {
          fecha_envio: fecha,
          id_centros_acopios: idCentroAcopio === 0 ? null : idCentroAcopio,
          cantidad: parseFloat(monto * 100),
          tipo_motivo: tipoMotivo,
          acopiador: idAcopiador === 0 ? null : idAcopiador,
        };
        
        setBlocking(true);
        
        if (isNew) {
          // REGISTRAR
          Api.Acopio.create('registro_efectivo', data)
          .then(response => {
            if (response.data.success) {
              setBlocking(false);
              setShow(false);
                publish('Efectivo::onSave', response.data.data);
              } else {
                //Ocurrio un error
              }
            })
            .catch(e => {
              setBlocking(false);
            });
          } else {
            // EDITAR
            Api.Acopio.update('registro_efectivo', id, data)
            .then(response => {
              if (response.data.success) {
                setBlocking(false);
                publish('Efectivo::onSave', response.data.data);
                setShow(false);
              } else {
                //Ocurrio un error
              }
            })
            .catch(e => {
              // eslint-disable-next-line no-console
              console.log('error: ', e);
              setBlocking(false);
            });
        }
      })
      .catch(function(err) {
        let errors = err.inner.reduce(function(map, obj) {
          map[obj.path] = obj.message;
          return map;
        }, {});
        setErrors(errors);
      });
  };

  const regex = /[0-9]|\./;

  function validate(evt) {
    // Handle paste
    if (evt.type === 'paste') {
        key = evt.clipboardData.getData('text/plain');
    } else {
    // Handle key press
        var key = evt.keyCode || evt.which;
        key = String.fromCharCode(key);
    }
    if( !regex.test(key) ) {
      evt.returnValue = false;
      if(evt.preventDefault) evt.preventDefault();
    }
  }
  
  

  const handleMonto = (event) => {
    event.preventDefault();
    const value = event.target.value;
      setMonto(value);
  }

  return (
    <ModalGeneral
      show={show}
      title={isNew ? `Registrar ${props.title}` : `Editar ${props.title}`}
      onSave={validateHandler}
      onClose={() => closeHandler()}
      loading={blocking}>
      <div className="modal-body" ref={modalRef} style={{ overflowY: 'auto' }}>
        <div className="row my-4">
          <div className="col-12">
            <TextField
              type="date"
              name="fecha"
              error={!!errors?.fecha}
              label="Fecha"
              className="form-control"
              value={fecha || ""}
              onChange={event => {
                setFecha(event.target.value);
              }}
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">{errors?.fecha && <div className="invalid-feedback">{errors?.fecha}</div>}</div>
        </div>

        {usaCentroAcopio === 1 && (
          <div className="row" style={{ marginTop: 10 }}>
            <div className="input-group" style={{ borderStyle: 'none', marginLeft: -5 }}>
              <div style={{ paddingTop: 8 }} className="col-xl-12 col-lg-12">
                <FormSelectMaterialApi
                  placeholder="Seleccione un centro de acopio"
                  label="Centro de acopio"
                  id={'id_centro_acopio'}
                  error={!!errors?.id_centro_acopio}
                  url={'centros_acopios'}
                  keyArray="id"
                  labelArray="nombre"
                  onChange={event => {
                    setIdCentroAcopio(event.target.value);
                  }}
                  filter={filterCA}
                  sort={[{ column: 'nombre', sort: 'asc' }]}
                  value={idCentroAcopio}
                />
                {errors?.id_centro_acopio && <div className="invalid-feedback">{errors?.id_centro_acopio.message}</div>}
              </div>
            </div>
          </div>
        )}

        <div className="row my-4">
          <div className="col-12">
            {/* este input no mostraba el numero previo al editar con FormInputMaterial */}
            <div style={{position: "relative"}}>
              <input
              type="number"
              inputMode="numeric" 
              name="monto"
              id="monto"
              className="form-control"
              required
              min="0"
              pattern="[0-9]*"
              onKeyPress={event=>validate(event)}
              value={monto}
              style={{
                WebkitAppearance: "textfield",
                MozAppearance: "textfield",
                margin: "0",
                height: "40px",
              }}
              error={{ message: errors?.monto }}
              onChange={event => {
                handleMonto(event);
              }}
              ></input>
                <label htmlFor="monto" id="monto-label" style={{
                    position: "absolute",
                    top: monto ? "-12px" : "17%",
                    left: "18px",
                    fontSize: monto ? "1em" : "1.3em",
                    color: "#505050",
                    transition: '0.3s ease-in-out, top 0.3s, transform 0.3s',
                    background: monto ? "white" : "none",
                    padding: monto ? "0 5px" : "0",
                    zIndex: 1
                  }}>Cantidad</label>
            </div>
            {errors?.monto && <div className="invalid-feedback ">{errors?.monto}</div>}

            {/* <FormInputMaterial
              type="number"
              name="monto"
              className="form-control"
              label="Monto"
              placeholder="Escriba un monto"
              value={monto}
              error={{ message: errors?.monto }}
              onChangeCapture={event => {
                setMonto(event.target.value);
              }}
            /> */}
          </div>
        </div>

        <div className="row my-4">
          <div className="col-12">
            <FormSelectMaterial
              placeholder="Motivo desembolso"
              label="Motivo desembolso"
              options={motivos}
              id={'tipo_motivo'}
              error={!!errors?.tipo_motivo}
              onChange={value => {
                setTipoMotivo(value);
              }}
              value={tipoMotivo}
            />
            {errors?.tipo_motivo && <div className="invalid-feedback">{errors?.tipo_motivo}</div>}
          </div>
        </div>

        {usaCentroAcopio === 1 && (
          <div className="row my-4">
            <div className="col-12">
              <FormSelectMaterialApi
                placeholder="Seleccione un acopiador"
                label="Acopiador"
                id={'id_acopiador'}
                error={!!errors?.id_acopiador}
                url="users"
                keyArray="id"
                labelArray="nombre"
                onChange={event => {
                  setIdAcopiador(event.target.value);
                }}
                filter={filterAco}
                sort={[{ column: 'nombre', sort: 'asc' }]}
                value={idAcopiador}
              />
            </div>
          </div>
        )}
      </div>
    </ModalGeneral>
  );
});

export default ModalEfectivo;
